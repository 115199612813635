import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Student } from 'src/app/models/Student';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent implements OnInit {

  student: Student;
  constructor(
    public router: Router,
    public auth: AuthService,
    public api: ApiService,
    public route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    
    if (this.auth.user) {
      this.api.getStudent(this.auth.user._id).subscribe(
        student => {
          
          this.student= student;
        }
      );
    }
  }

}
