import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import errorMessages from '../../../utils/errorMessages';
import {MatDialog} from '@angular/material/dialog';
import {STATE} from 'src/app/models/Project';
import {ApiService} from 'src/app/services/api.service';
import {Proposal} from 'src/app/models/Proposal';
import {AuthService} from 'src/app/services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Editable} from 'src/app/utils/editable';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Teacher} from 'src/app/models/Teacher';

@Component({
    selector: 'app-project-editor',
    templateUrl: './project-editor.component.html',
    styleUrls: ['./project-editor.component.scss']
})
export class ProjectEditorComponent implements OnInit, Editable {

    project: Proposal;
    titleControl = new FormControl('', [Validators.required, Validators.minLength(7), Validators.maxLength(300)]);
    abstractControl = new FormControl('', [Validators.required, Validators.minLength(20), Validators.maxLength(2000)]);
    objectivesControl = new FormControl('', [Validators.maxLength(1000)]);
    backgroundControl = new FormControl('', [Validators.maxLength(1000)]);

    projectTypeErrorShow = false;
    investigationProject?: boolean;
    developmentProject?: boolean;
    otherTypeProjectEnable = false;
    otherTypeProjectControl = new FormControl('', [Validators.maxLength(300)]);
    expectedDurationMonthsControl = new FormControl(12, []);

    deliverablesControl = new FormControl('', [Validators.maxLength(300)]);
    impactControl = new FormControl('', [Validators.maxLength(500)]);
    extraCommentsControl = new FormControl('', [Validators.maxLength(1000)]);

    maxStudentsControl = new FormControl(3, [Validators.min(1)]);
    studentControls = new FormArray([]);
    requisitesControl = new FormControl('', [Validators.maxLength(300)]);
    externalParticipantsControl = new FormControl('', [Validators.maxLength(1000)]);

    startDateControl: FormArray = this.FB.array([
        this.FB.control(2020, [Validators.required]),
        this.FB.control(2, [Validators.required])
    ]);
    stateControl = new FormControl(0, []);
    isPublic: boolean;
    accepted: boolean;

    fieldOfStudy: string;
    public studyFields = ['Desarrollo Web', 'Desarrollo Mobile', 'Desarrollo Desktop', 'Procesamiento de Imágenes', 'HCI', 'Bases de Datos', 'Aprendizaje Automático', 'Inteligencia Artificial',
        'Robótica', 'Redes', 'Compiladores', 'Arquitectura de Computadoras', 'Sistemas Operativos', 'Sistemas Distribuidos', 'Sistemas Embebidos', 'Blockchain', 'Ingeniería de Software', 'Seguridad y Criptografía',
        'Videojuegos', 'Algoritmos y Complejidad', 'Fintech', 'Bioingeniería', 'Cloud Computing', 'Hardware', 'Ciencia de Datos', 'Metodos Numéricos', 'Otro'];

    form = this.FB.group({
        title: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(300)]],
        abstract: ['', [Validators.required, Validators.minLength(20), Validators.maxLength(2000)]],
        fieldOfStudy: [null, [Validators.required]],
        tutors: this.FB.array([]),
        extraComments: ['', [Validators.maxLength(1000)]],
        requisites: ['', [Validators.maxLength(300)]],
        deliverables: ['', [Validators.maxLength(300)]],
        objectives: ['', [Validators.maxLength(1000)]],
        maxStudents: [0, [Validators.min(1)]],
        state: [0, []],
        startDate: this.FB.array([
            this.FB.control(2020, [Validators.required]),
            this.FB.control(2, [Validators.required])
        ])
    });

    controlNames = {
        title: this.titleControl,
        abstract: this.abstractControl,
        objectives: this.objectivesControl,
        background: this.backgroundControl,
        requisites: this.requisitesControl,
        otherTypeProject: this.otherTypeProjectControl,
        externalParticipants: this.externalParticipantsControl,
        impact: this.impactControl,
        extraComments: this.extraCommentsControl,
        deliverables: this.deliverablesControl,
        maxStudents: this.maxStudentsControl,
        state: this.stateControl
    };
    formGroup = new FormGroup(this.controlNames);

    public separatorKeysCodes = [ENTER, COMMA];

    public tags: string[] = [];
    public tagInput = this.FB.control('', [this.AlreadyUsedTagValidator.bind(this)]);
    allTags: string[] = [];
    autocompleteTags: Observable<string[]>;

    allTeachers: Teacher[] = [];

    public tutors: Teacher[] = [];
    public tutorInput = this.FB.control('');
    autocompleteTutors: Observable<Teacher[]>;


    public posibleTutors: string[] = [];
    public posibleTutorInput = this.FB.control('');
    autocompletePosibleTutors: Observable<Teacher[]>;
    years: number[] = [];
    currentYear: number;
    constructor(
        public api: ApiService,
        public auth: AuthService,
        public router: Router,
        public route: ActivatedRoute,
        public dialog: MatDialog,
        public FB: FormBuilder,
        private _location: Location
    ) {
        this.autocompleteTags = this.tagInput.valueChanges.pipe(
            startWith(null),
            map((tag: string | null) => tag ? this._filterTags(tag) : this.allTags.slice()));
        this.autocompletePosibleTutors = this.posibleTutorInput.valueChanges.pipe(
            startWith(null),
            map((tutorName: string | null) => tutorName ?
                this._filterPosibleTutors(tutorName) :
                this.allTeachers.filter(teacher => !this.posibleTutors.includes(teacher.fullName) && !this.posibleTutors.includes(teacher.email))));
        this.autocompleteTutors = this.tutorInput.valueChanges.pipe(
            startWith(null),
            map((inputStr: string | null) => inputStr ?
                this._filterTutorsByInputString(inputStr) :
                this.allTeachers.filter(teacher => !this.tutors.map(tu => tu.email).includes(teacher.email))));
    }

    ngOnInit() {
        this.startDateControl.controls[0].setValue(this.api.currentSemester.value[0]);
        this.startDateControl.controls[1].setValue(this.api.currentSemester.value[1]);
        if (this.auth.isStudent) {
            this.studentControls.push(this.FB.control(this.auth.user.email));
            this.studentControls.push(this.FB.control(''));
            this.studentControls.push(this.FB.control(''));
            this.studentControls.at(0).disable();
        }
        if (this.auth.isTeacher) {
            this.tutors.unshift(this.auth.user as Teacher);
            this.stateControl.disable();
        }
        this.route.paramMap.subscribe((params) => {
            if (params.get('id')) {
                this.api.getProject(params.get('id')).subscribe(project => {
                    this.project = project;
                    if (this.auth.isStudent && !this.project.students.map(t => t._id).includes(this.auth.user._id)) {
                        this.router.navigate(['/']);
                    }
                    if (this.auth.isTeacher && !this.project.tutors.map(t => t._id).includes(this.auth.user._id)) {
                        this.router.navigate(['/']);
                    }
                    this.fillFormControlsFromProject(project);
                });
            }
        });

        this.api.teachers.subscribe((teachers) => {
            this.allTeachers = teachers;
        });
        this.allTags = this.api.tags.value;
        this.api.tags.subscribe((tags) => {
            this.allTags = tags;
        });
        this.populateYears();
    }

    uploadProject() {
        this.formGroup.markAllAsTouched();
        this.projectTypeErrorShow = true;
        if (this.formGroup.invalid || this.projectTypeError) {
            document.body.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
            return;
        }
        if (!this.project) {
            this.api.createProposal(this.getProjectFromControls()).subscribe(
                () => {
                    this.auth.getMe();
                    this._location.back();
                });
        } else {
            if (this.auth.isTeacher && this.project.state === STATE.CANCELLED) {
                this.stateControl.setValue(STATE.UNSTARTED);
            }
            this.api.editProject(this.project._id, this.getProjectFromControls()).subscribe(
                () => {
                    this.auth.getMe();
                    this._location.back();
                });
        }
    }

  capitalizeName(name: string) {
    if(name!=null){
      let capitalized = '';
      for (let i = 0; i < name.length; i++) {
        if (i === 0 || (name[i - 1] === ' ' && name[i] !== ' ')) {
          capitalized += name[i].toUpperCase();
        } else {
          capitalized += name[i].toLowerCase();
        }
      }
      return capitalized;
    }
  }

    get canEdit(): boolean {
        if (this.auth.user) {
            if (this.auth.isAdmin || (this.auth.isTeacher && this.project.tutors.map(t => t._id).includes(this.auth.user._id))) {
                return true;
            }
        }
        return false;
    }

    getProjectFromControls(): Proposal {
        const p = new Proposal();
        p.title = this.titleControl.value;
        p.abstract = this.abstractControl.value;
        p.objectives = this.objectivesControl.value;
        p.background = this.backgroundControl.value;
        p.investigationProject = this.investigationProject;
        p.developmentProject = this.developmentProject;
        p.fieldOfStudy = this.fieldOfStudy;
        p.expectedDurationMonths = this.expectedDurationMonthsControl.value;
        p.deliverables = this.deliverablesControl.value;
        p.impact = this.impactControl.value;
        p.extraComments = this.extraCommentsControl.value;
        p.startDate = this.startDateControl.value;
        p.tutors = this.tutors.map(t => t.email) as any[];
        p.posibleTutors = this.posibleTutors;
        p.tags = this.tags;
        p.maxStudents = this.maxStudentsControl.value;
        p.students = this.studentControls.value;
        p.requisites = this.requisitesControl.value;
        p.externalParticipants = this.externalParticipantsControl.value;
        p.state = this.stateControl.value;
        p.isPublic = this.isPublic;
        p.accepted = this.accepted;
        return p;
    }

    fillFormControlsFromProject(project: Proposal): void {
        this.titleControl.setValue(project.title || '');
        this.abstractControl.setValue(project.abstract || '');
        this.objectivesControl.setValue(project.objectives || '');
        this.backgroundControl.setValue(project.background || '');
        this.fieldOfStudy = project.fieldOfStudy;
        this.investigationProject = project.investigationProject;
        this.developmentProject = project.developmentProject;
        this.otherTypeProjectEnable = !!project.otherTypeProject;
        this.otherTypeProjectControl.setValue(project.otherTypeProject || '');
        this.expectedDurationMonthsControl.setValue(project.expectedDurationMonths || 12);
        this.deliverablesControl.setValue(project.deliverables || '');
        this.impactControl.setValue(project.impact || '');
        this.extraCommentsControl.setValue(project.extraComments || '');
        this.startDateControl.at(0).setValue(project.startDate[0]);
        this.startDateControl.at(1).setValue(project.startDate[1]);
        if (project.tutors.length) {
            this.tutors = project.tutors;
        }
        this.maxStudentsControl.setValue(project.maxStudents);
        this.studentControls.clear();
        for (let i = 0; i < project.maxStudents; i++) {
            this.studentControls.push(this.FB.control(project.students[i] ? project.students[i].email : ''));
        }
        if (this.auth.isTeacher) {
            this.studentControls.disable();
        }
        this.requisitesControl.setValue(project.requisites);
        this.externalParticipantsControl.setValue(project.externalParticipants);
        this.stateControl.setValue(project.state);
        this.isPublic = project.isPublic || false;
        this.accepted = project.accepted || false;
        this.tags = project.tags || [];
        this.posibleTutors = project.posibleTutors || [];
    }

    getErrorMessage(input: string) {
        const errorsAux = this.controlNames[input]?.errors;
        if (errorsAux) {
            const keys = Object.keys(this.controlNames[input].errors);
            if (!keys || keys.length === 0) return '';
            return errorMessages[keys[0]](errorsAux[keys[0]]);
        } else {
            return '';
        }
    }

    showTooltipOnClick(tooltip) {
        setTimeout(() => tooltip.show(), 1);
    }

    private _filterTags(value: string): string[] {
        return this.allTags.filter(tag => tag.toLowerCase().includes(value.toLowerCase()));
    }

    addTag(event: MatChipInputEvent): void {
        if (this.tagInput.value && this.tagInput.valid) {
            this.tags.push(this.tagInput.value);
            this.tagInput.reset();
        }
    }

    removeTag(tag: any, index: number): void {
        if (index >= 0) {
            this.tags.splice(index, 1);
        }
    }

    selectedAutocompleteTag(event: MatAutocompleteSelectedEvent): void {
        if (!this.tags.includes(event.option.viewValue)) {
            this.tags.push(event.option.viewValue);
        }
        this.tagInput.reset();
    }

    AlreadyUsedTagValidator(control: FormControl) {
        if (this.tags.map(t => t.toLowerCase()).includes(control.value?.toLowerCase())) {
            return {alreadyUsed: true};
        }
        return null;
    }

    private _filterTutorsByInputString(inputValue: string): Teacher[] {
        return this.allTeachers.filter(teacher => !this.tutors.map(t => t.email).includes(teacher.email)
            && (teacher.email.includes(inputValue) || teacher.fullName.toLowerCase().includes(inputValue.toString().toLowerCase())));
    }

    private _filterPosibleTutors(value: string): Teacher[] {
        return this.allTeachers.filter(teacher => (!this.posibleTutors.includes(teacher.fullName) && !this.posibleTutors.includes(teacher.email))
            && (teacher.fullName.toLowerCase().includes(value.toString().toLowerCase()) || teacher.email.toLowerCase().includes(value.toString().toLowerCase())));
    }

    addPosibleTutor(event: MatChipInputEvent): void {
        if (this.posibleTutorInput.value && this.posibleTutorInput.valid) {
            this.posibleTutors.push(this.posibleTutorInput.value);
            this.posibleTutorInput.reset();
        }
    }

    removePosibleTutor(tutor: any, index: number): void {
        if (index >= 0) {
            this.posibleTutors.splice(index, 1);
        }
    }

    private populateYears() {
        this.currentYear = new Date().getFullYear();
        for (let i = 2020; i <= this.currentYear; i++) {
            this.years.push(i);
        }
    }

    selectedAutocompleteTutor(event: MatAutocompleteSelectedEvent): void {
        this.tutors.push(event.option.value);
        this.tutorInput.reset();
        this.autocompleteTutors = this.tutorInput.valueChanges.pipe(
            startWith(null),
            map((inputStr: string | null) => inputStr ?
                this._filterTutorsByInputString(inputStr) :
                this.allTeachers.filter(teacher => !this.tutors.map(tu => tu.email).includes(teacher.email))));
    }

    selectedAutocompletePosibleTutor(event: MatAutocompleteSelectedEvent): void {

        const tutor: Teacher = event.option.value;
        const fullNameOrMail = (tutor.firstName) ? tutor.fullName : tutor.email;
        this.posibleTutors.push(fullNameOrMail);
        this.posibleTutorInput.reset();
        this.autocompletePosibleTutors = this.posibleTutorInput.valueChanges.pipe(
            startWith(null),
            map((tutorName: string | null) => tutorName ?
                this._filterPosibleTutors(tutorName) :
                this.allTeachers.filter(teacher => !this.posibleTutors.includes(teacher.fullName))));
    }

    removeTutor(tutor: any, index: number): void {
        if (index >= 0) {
            this.tutors.splice(index, 1);
        }
    }


    back() {
        this._location.back();
    }

    get projectTypeError() {
        return this.projectTypeErrorShow && !this.investigationProject && !this.developmentProject
            && (!this.otherTypeProjectEnable || (this.otherTypeProjectEnable && !this.otherTypeProjectControl.value));
    }
}
