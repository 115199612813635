<div class="container" [formGroup]="teacherEditFG">
  <img src="assets/icons/close.svg" alt="" (click)="this.dialogRef.close(false)" class="close">
  <h1 mat-dialog-title>Editar perfil</h1>
  <div class="h-flex">
    <mat-form-field>
      <mat-label>Título</mat-label>
      <mat-select formControlName="title" required>
        <mat-option [value]="'Prof.'">Prof.</mat-option>
        <mat-option [value]="'Lic.'">Lic.</mat-option>
        <mat-option [value]="'Ing.'">Ing.</mat-option>
        <mat-option [value]="'Mag.'">Mag.</mat-option>
        <mat-option [value]="'Dr.'">Dr.</mat-option>
      </mat-select>
    </mat-form-field>

  </div>

  <div class="name-flex">
    <mat-form-field>
      <mat-label>Nombre</mat-label>
      <input matInput placeholder="Nombre" formControlName="firstName" required>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Apellido</mat-label>
      <input matInput placeholder="Apellido" formControlName="lastName" required>
    </mat-form-field>
  </div>

  <mat-form-field class="input-group">
    <mat-label for="tag">Intereses</mat-label>
    <div class="cat-input">
      <input id="categories"
             matInput
             name="tag"
             placeholder="Ej: Machine Learning, Criptografía"
             [matChipInputFor]="chipList"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
             (matChipInputTokenEnd)="addTag($event)"
             [formControl]="tagInput">
      <button class="cat-input-btn" (click)="addTag($event)">
        <img src="assets/icons/forward.svg">
      </button>
    </div>
  </mat-form-field>
  <mat-chip-list #chipList style="width: 400px;overflow-y: auto;height: 60px">
    <mat-basic-chip *ngFor="let tag of tags; let i = index"
                    [selectable]="true"
                    [removable]="true"
                    (remove)="removeTag(tag, i)">
      {{tag}}
      <mat-icon matChipRemove (click)="removeTag(tag, i)">cancel</mat-icon>
    </mat-basic-chip>
  </mat-chip-list>

  <mat-form-field>
    <mat-label>Descripción personal</mat-label>
    <textarea matInput formControlName="bio" required></textarea>
  </mat-form-field>

  <button mat-stroked-button class="btn-blue" (click)="save()">Aceptar cambios</button>
</div>
