import {Pipe, PipeTransform} from '@angular/core';
import {Project, STATE} from 'src/app/models/Project';

@Pipe({
  name: 'projectAcceptedFilter'
})
export class ProjectAcceptedFilterPipe implements PipeTransform {

  transform(projects: Project[], ...args: any[]): Project[] {
    if (args) {
      if (args[0]===1){
        return projects?.filter(p => (p.state === STATE.UNSTARTED && p.accepted === true) )|| [];
      }else{
        return projects?.filter(p => (p.state === STATE.UNSTARTED && p.accepted === false) )|| [];
      }
    }
    return projects || [];
  }

}
