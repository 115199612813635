import { Injectable } from '@angular/core';
import { AuthService as GoogleAuth, GoogleLoginProvider, SocialUser } from 'angularx-social-login';
import { User, USER_TYPE } from 'src/app/models/User';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Teacher } from 'src/app/models/Teacher';
import { Router } from '@angular/router';
import { share } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import {Student} from "../models/Student";


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _user: User = null;
  private _isTokenAccess: boolean = false;
  userLoaded: Promise<User>;

  headers = new HttpHeaders()
    .append('Content-Type', 'application/json');

  constructor(
    private googleAuth: GoogleAuth,
    private http: HttpClient,
    private router: Router,
    private dialog: MatDialog,
    private _matSnackbar: MatSnackBar,
    private activatedRoute: ActivatedRoute) {
    this.loadUserFromLocalStorage();
    this.initialize();

  }
  private initialize() {
    this.activatedRoute.queryParams.subscribe(params => {
      const token = params['token'];
      if (token) {

        if (this._user == null || this._user.type !== USER_TYPE.admin) {
          this._isTokenAccess = true;
          this._user = {
            type: USER_TYPE.teacher,
            accessToken: token,
          } as Teacher;
        }
        this.headers = this.headers.append('x-access-token', this._user.accessToken);
      }
    });
  }
  public get accessByToken(): boolean {
    return this._isTokenAccess;
  }

  signInWithGoogle(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.googleAuth.signIn(GoogleLoginProvider.PROVIDER_ID).then((googleUser: SocialUser) => {
        this.loginToServer(googleUser.idToken).subscribe(
          (data: User) => {
            this._user = data;
            this.headers = this.headers.append('x-access-token', this._user.accessToken);
            this.saveUserToLocalStorage();
            this.redirectLoggedUser();
            this._matSnackbar.open(`Bienvenido ${data.firstName} ${data.lastName}`, null, {
              duration: 3000,
              panelClass: 'center'
            });
            resolve(true);
          }, (e) => {
            reject(e);
          });
      }).catch((e) => {
        reject(e);
      });
    });
  }

  loginToServer(idToken: string) {
    return this.http.post<any>(`${environment.apiURL}/auth`, { idToken }, { headers: this.headers });
  }

  getMe() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.user.accessToken);
    const obs = this.http.get<any>(`${environment.apiURL}/me`, { headers });
    obs.subscribe(data => {
      if (!data.accessToken) {
        data.accessToken = this.user.accessToken;
      }
      if (this.user.type !== data.type) {
        this._user = data;
        this.redirectLoggedUser();
      } else {
        this._user = data;
      }
      this.saveUserToLocalStorage();
    });
    return obs;
  }

  editMe(user: User) {
    const obs = this.http.post<User>(`${environment.apiURL}/me`, user, { headers: this.headers }).pipe(share());
    obs.subscribe((u) => {
      for (const key in user)
        this.user[key] = user[key];
      this.saveUserToLocalStorage();
      this._matSnackbar.open(`Se ha editado el usuario correctamente.`, null, { duration: 3000 });
    });
    return obs;
  }

  signOut(): void {
    this._user = null;
    localStorage.removeItem('loggedUserITBAThesis');
    this.googleAuth.signOut();
    this.router.navigate(['']);
    this._matSnackbar.open(`Se ha cerrado la sesión correctamente`, null, { duration: 3000 });
  }

  saveUserToLocalStorage() {
    localStorage.setItem('loggedUserITBAThesis', JSON.stringify(this._user));
  }

  loadUserFromLocalStorage() {
    if (localStorage.getItem('loggedUserITBAThesis')) {
      this._user = JSON.parse(localStorage.getItem('loggedUserITBAThesis'));
      this.headers = this.headers.append('x-access-token', this._user.accessToken);
      this.getMe();
    }
  }

  redirectLoggedUser() {
    if (this.user) {
      switch (this.user.type) {
        case USER_TYPE.admin:
          this.router.navigate(['admin']);
          break;
        case USER_TYPE.teacher:
          this.router.navigate(['teacher']);
          break;
        case USER_TYPE.student:
          this.router.navigate(['alu']);
          break;
      }
    }
  }

  public get userT(): Teacher {
    return this._user as Teacher;
  }
  
  public get userS(): Student {
    return this._user as Student;
  }
  public get user(): User {
    return this._user;
  }

  public get isAdmin(): boolean {
    return this._user?.type === 2;
  }

  public get isTeacher(): boolean {
    return this._user?.type === 1;
  }

  public get isStudent(): boolean {
    return this._user?.type === 0;
  }

  public get type() {
    return this._user?.type;
  }

}
