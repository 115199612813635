import { Component, Input, OnInit } from '@angular/core';
import { Proposal } from 'src/app/models/Proposal';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ProjectApplication } from 'src/app/models/ProjectApplication';
import errors from 'src/app/utils/errorCodes';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { Student } from 'src/app/models/Student';
import { AdminService } from 'src/app/administrator/services/admin.service';
import { ProjectApplicationEditorComponent } from 'src/app/shared/components/dialogs/project-application-editor/project-application-editor.component';
import { EmailDialogService } from 'src/app/shared/services/email-dialog.service';
import { merge, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LaunchProjectDialogComponent } from 'src/app/shared/components/dialogs/launch-project-dialog/launch-project-dialog.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {

  @Input()
  id: string;

  public proposal: Proposal;
  public groupOptionsDisplayed: boolean = true;
  public groupPartners: Array<FormControl>;
  public alreadyApplied: ProjectApplication = null;
  public priorityControl = new FormControl(-1, [Validators.min(1)]);
  public canEdit = false;
  public individual = false;

  public isPublicApplication = false;

  filteredStudents: Observable<string[]>;
  formCreated: boolean;

  public applicationForm: FormGroup;

  controls = [this.priorityControl];
  controlNames = {
    'priority': this.priorityControl,
  };
  allStudents: Student[];

  constructor(
    public api: ApiService,
    private admin: AdminService,
    public auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private emailDialogS: EmailDialogService,
    private fb: FormBuilder,
    private _location: Location) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const id = this.id || params.get('id');
      this.api.getProject(id).subscribe((p => {
        this.proposal = p;
        if (this.auth.isStudent) {
          this.proposal.applications?.forEach(application => {
            application.students.forEach(s => {
              if (s.email === this.auth.user.email) {
                this.alreadyApplied = application;
              }
            });
          });
          this.proposal.applications?.filter(app => app.isPublic || app._id === this.alreadyApplied?._id);
        }
        this.canEdit = this.auth.isAdmin
          || this.proposal.creator.toString() === this.auth.user?._id
          || this.proposal.tutors.map(s => s._id).includes(this.auth.user?._id);

        this.applicationForm = this.fb.group({
          partners: this.fb.array([])
        });

        this.formCreated = true;

        this.groupPartners = [];
        for (let i = 0; i < this.proposal.maxStudents; i++) {
          if (i === 0) {
            this.addPartner(true);
          } else {
            this.addPartner(false);
          }
        }

        this.filteredStudents = merge(...this.partners.controls
          .map((control: AbstractControl, index: number) => control.valueChanges
            .pipe(map(value => ({ rowIndex: index, value })))))
          .pipe(
            map(value => this._filter(value))
          );
      }));
    });
    this.api.getStudents().subscribe(
      data => {
        this.allStudents = data.filter(s => (!this.auth.user || s._id !== this.auth.user._id) && !s.project);
      }
    );
  }

  private _filter(value: any): string[] {
    const filterValue = value.value.email?.toString().toLowerCase();
    return this.allStudents?.filter(s => s.email.toLowerCase().includes(filterValue)
      || s.firstName?.toLowerCase().includes(filterValue)
      || s.lastName?.toLowerCase().includes(filterValue))
      .map(s => s.email);
  }

  get partners(): FormArray {
    return this.applicationForm.get('partners') as FormArray;
  }

  addPartner(isFirst: boolean) {
    this.partners.push(this.newPartner(isFirst));
  }

  private newPartner(isFirst: boolean): FormGroup {
    if (isFirst) {
      if (this.auth.isStudent) {
        return this.fb.group({
          email: new FormControl({value: this.auth.user.email, disabled: true}, [Validators.required, Validators.email]),
        });
      }
      return this.fb.group({
        email: new FormControl(null, [Validators.required, Validators.email]),
      });
    }
    return this.fb.group({
      email: new FormControl(null, Validators.email),
    });
  }

  public get canApply(): boolean {
    return this.auth.user && !this.proposal?.state
      && (this.auth.isAdmin || (!this.proposal?.students.map(s => s.email).includes(this.auth.user.email)));
  }

  public applyGroup() {
    if (!this.groupOptionsDisplayed) {
      this.groupOptionsDisplayed = true;
    } else {
      if (!this.priorityControl.invalid && !this.applicationForm.invalid) {
        const partnerEmails = [];
        this.partners.value.forEach(partner => {
          if (partner.email) {
            partnerEmails.push(partner.email);
          }
        });
        let title: string;
        let body: string;
        if (this.auth.isStudent) {
          partnerEmails.push(this.auth.user.email);
          title = 'Aplicando como grupo';
          body = 'Estás aplicando como grupo con los siguientes integrantes:';
          partnerEmails.forEach(email => body += '\n ' + email);
        } else {
          title = 'Crear aplicación';
          body = 'Estás creando un grupo con los siguientes integrantes:';
          partnerEmails.forEach(email => body += '\n ' + email);
        }
        const ref = this.dialog.open(ConfirmDialogComponent, {
          data: {
            title,
            body
          }
        });

        ref.afterClosed().subscribe(data => {
          if (data) {
            this.api.applyForProject(this.proposal._id, partnerEmails, this.isPublicApplication, this.priorityControl.value)
              .subscribe((data: ProjectApplication) => {
                if (this.auth.isStudent) {
                  this.alreadyApplied = data;
                }
                if (!this.proposal.applications) {
                  this.proposal.applications = [];
                }
                const app = new ProjectApplication();
                data.students = partnerEmails.map(email => ({ email } as Student));
                this.proposal.applications.push(data);
                this.priorityControl.reset();
                this.applicationForm.reset();
                this.groupOptionsDisplayed = false;
              });
          }
        });
      } else {
        this.priorityControl.markAsDirty();
        this.priorityControl.markAsTouched();
        this.partners.markAsDirty();
        this.partners.markAllAsTouched();
      }
    }
  }

  public createGroupAlone(isPublic: boolean) {
    let body = '';
    if (isPublic) {
      body = 'Estás creando un grupo abierto, esto significa que te interesa este proyecto y buscas gente interesada en realizarlo también.';
    } else {
      body = 'Estás aplicando solo. Esto significa que harías este proyecto de manera individual.';
      this.individual=true;
    }
    const ref = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: isPublic ? 'Creando grupo abierto' : 'Aplicando solo',
        body
      }
    });
    ref.afterClosed().subscribe(data => {
      if (data) {
        this.api.applyForProject(this.proposal._id, [], isPublic, this.priorityControl.value).subscribe((data: ProjectApplication) => {
          this.alreadyApplied = data;
          this.groupOptionsDisplayed = false;
        });
      }
    });
  }

  capitalizeName(name: string): string {
    let capitalized = '';
    if (name !== null){
      for (let i = 0; i < name.length; i++) {
        if (i === 0 || (name[i - 1] === ' ' && name[i] !== ' ')) {
          capitalized += name[i].toUpperCase();
        } else {
          capitalized += name[i].toLowerCase();
        }
      }
      return capitalized;
    }
  }

  getErrorMessage(input: string) {
    const errorsAux = this.controlNames[input].errors;
    if (errorsAux) {
      const keys = Object.keys(this.controlNames[input].errors);
      if (!keys || keys.length === 0) return '';
      return errors[Object.keys(this.controlNames[input].errors)[0]];
    } else {
      return '';
    }
  }

  getPriorityText(priority) {
    switch (priority) {
      case 1:
        return '1 - Ideal';
      case 2:
        return '2 - Alta';
      case 3:
        return '3 - Media';
      case 4:
        return '4 - Baja';
      case 5:
        return '5 - Meh';
      default:
        return '(Sin prioridad)';
    }
  }

  isThereAnyOpenGroups() {
    return this.proposal?.applications?.some(a => a.isPublic);
  }

  getPublicGroups() {
    return this.proposal.applications.filter(a => a.isPublic);
  }

  deleteProject() {
    const ref = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Eliminar proyecto',
        body: '¿Está seguro que quiere eliminar el proyecto?'
      }
    });
    ref.afterClosed().subscribe(data => {
      if (data) {
        this.admin.deleteProject(this.proposal._id).subscribe((data: Proposal) => {
          this.router.navigate(['/']);
        });
      }
    });
  }

  unsubTeam(groupId: string) {
    this.api.unJoinApplication(groupId).subscribe(
      () => {
        this.proposal.applications = this.proposal.applications.filter(a => groupId !== a._id);
      }
    );
  }

  acceptTeam(application: ProjectApplication) {
    this.admin.acceptProjectApplication(application._id).subscribe(
      () => {
        this.proposal.students.push(...application.students);
        this.proposal.applications = this.proposal.applications.filter(a => a._id !== application._id);
      }
    );
  }

  joinTeam(groupId: string) {
    this.api.joinProjectApplication(groupId).subscribe(
      data => {
        this.proposal.applications = this.proposal.applications.filter(a => groupId !== a._id);
        this.proposal.applications.push(data);
        this.alreadyApplied = data;
      }
    );
  }

  launch() {
    if (!this.proposal?.students?.length || !this.proposal?.tutors?.length)
      return;
    const ref = this.dialog.open(LaunchProjectDialogComponent, {
      data: {
        title: '¿Está seguro que quiere lanzar el proyecto?',
        body: 'Se enviará el siguiente email a los alumnos y tutores:',
      }
    });

    ref.afterClosed().subscribe(data => {
      if (data) {

        const emailBodyValue = ref.componentInstance?.getEmailBodyValue();
        const checkboxValue = ref.componentInstance?.getCheckboxValue();
        const updated_template = ref.componentInstance?.getUpdateTemplateValue();
        if (updated_template) {
          this.admin.updateLaunchProjectEmailTemplate(emailBodyValue);
        }
        this.admin.launchProject(this.proposal._id, emailBodyValue, checkboxValue).subscribe(
          res => {
            this.proposal.state = 1;

            this.auth.getMe();
            this._location.back();

          }

        );
      }
    });
  }

  openProjectApplicationEditor(pa: ProjectApplication) {
    this.dialog.open(ProjectApplicationEditorComponent, {
      data: {
        proposal: this.proposal,
        pa
      }
    });
  }

  back() {
    this._location.back();
  }

}
