import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { Milestone } from 'src/app/models/Milestone';
import { Router } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-teacher-dashboard',
  templateUrl: './teacher-dashboard.component.html',
  styleUrls: ['./teacher-dashboard.component.scss']
})
export class TeacherDashboardComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    public auth: AuthService,
    private router: Router,
    private _location: Location
  ) {
  }

  ngOnInit(): void {
  }

  getPstatus(milestones: Milestone[]) {
    return milestones.reduce(
      (prev, curr) =>
        prev || (curr.doneDate === undefined && new Date(curr.dueDate).getTime() > new Date().getTime()), false) ? 'Atrasado' : 'EN TIEMPO';
  }

  getLastUpdate(milestones: Milestone[]): string {
    return milestones.reduce(
      (prev, curr) =>
        (curr.doneDate !== undefined) ? (curr.doneDate as any) : prev, 'N/A');
  }

  back() {
    this._location.back();
  }

}
