import {Pipe, PipeTransform} from '@angular/core';
import {Project, STATE} from "../../models/Project";

@Pipe({
    name: 'finishedProjects'
})
export class FinishedProjectsPipe implements PipeTransform {
    transform(projects: Project[]): number {
        return projects.filter(p => p.state === STATE.FINISHED).length;
    }

}
