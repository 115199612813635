export class Meeting {

  _id: string;
  weekday: number; // 0-6
  hour: number; // 13hs
  minute: number; // 13hs
  location: string; // meet, oficina, etc
  weekly: number; // 1 semanal, 2 bisemanal

}
