<div class="container">
  <img src="assets/icons/close.svg" alt="" (click)="this.dialogRef.close(false)" cdkFocusInitial>
  <h1 mat-dialog-title>Cargar Nota</h1>
  <form [formGroup]="gradeForm" (submit)="gradeProject()">
    <p>
      <mat-form-field class="area-input">
        <mat-label>Alumno</mat-label>
        <mat-select formControlName="student" (selectionChange)="onStudentSelected($event.value)">
          <mat-option *ngFor="let student of data.project.students" [value]="student._id">
            <span *ngIf="student.firstName">{{student.firstName}} {{student.lastName}}</span>
            <span *ngIf="!student.firstName">{{student.email}}</span>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="gradeForm.controls.student.touched && gradeForm.controls.student.invalid">
          <span *ngIf="gradeForm.controls.student.errors.required">Campo obligatorio</span>
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field class="num-input">
        <mat-label>Nota</mat-label>
        <input
          matInput
          type="number"
          formControlName="grade"
          autocomplete="off"/>
        <mat-error *ngIf="gradeForm.controls.grade.touched && gradeForm.controls.grade.invalid">
          <span *ngIf="gradeForm.controls.grade.errors.required">Campo obligatorio</span>
          <span *ngIf="gradeForm.controls.grade.errors.max">La nota debe pertenecer a [0,10]</span>
          <span *ngIf="gradeForm.controls.grade.errors.min">La nota debe pertenecer a [0,10]</span>
        </mat-error>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field class="num-input">
        <mat-label>Nro de Acta Final</mat-label>
        <input
          matInput
          type="number"
          formControlName="acta"
          autocomplete="off"
        />
      </mat-form-field>
    </p>
    <p class="btn-wrapper">
      <mat-slide-toggle [color]="'primary'" formControlName="sendMail">Enviar mail</mat-slide-toggle>
      <button mat-stroked-button class="btn-blue" type="submit">Calificar</button>
    </p>
  </form>
</div>
