import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Project, STATE } from 'src/app/models/Project';
import { AuthService } from 'src/app/services/auth.service';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Meeting } from 'src/app/models/Meeting';
import { Editable } from 'src/app/utils/editable';
import { ProjectResourceEditorComponent } from 'src/app/shared/components/dialogs/project-resource-editor/project-resource-editor.component';
import { EmailDialogService } from 'src/app/shared/services/email-dialog.service';
import errorMessages from 'src/app/utils/errorMessages';
import { ConfirmDialogComponent } from '../../../shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminService } from "../../../administrator/services/admin.service";
import { ProjectFinishDialogComponent } from "../../../administrator/components/projects/project-finish-dialog/project-finish-dialog.component";
import { Location } from '@angular/common';
@Component({
  selector: 'app-project-feed',
  templateUrl: './project-feed.component.html',
  styleUrls: ['./project-feed.component.scss']
})
export class ProjectFeedComponent implements OnInit, Editable {

  public project: Project;
  public addMeetFG: FormGroup;

  private rejected = false;

  constructor(
    public auth: AuthService,
    private admin: AdminService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private emailDialogS: EmailDialogService,
    private matSnackbar: MatSnackBar,
    private _location: Location) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      params => {
        this.apiService.getProject(params.get('id')).subscribe(
          data => {
            this.project = data;
          }
        );
      }
    );
    this.addMeetFG = this.fb.group({
      type: [1, [Validators.required]],
      weekday: [null, [Validators.required]],
      location: ['', [Validators.required]],
      time: [null, [Validators.required]]
    });
  }

  get canEdit(): boolean {
    if (this.auth.user) {
      if (this.auth.isAdmin || (this.auth.isTeacher && this.project?.tutors.map(t => t._id).includes(this.auth.user._id))) {
        return true;
      }
    }
    return false;
  }

  get studentOrTutor(): boolean {
    return this.project && this.project.state === STATE.UNDERWAY &&
      ((this.project.tutors && this.project.tutors.map(tutor => tutor._id).includes(this.auth.user?._id)) ||
        (this.project.students && this.project.students.map(student => student._id).includes(this.auth.user?._id)));
  }

  addEvent() {
    this.addMeetFG.markAllAsTouched();
    if (this.addMeetFG.invalid) {
      return;
    }
    const aux = new Meeting();
    aux.weekly = this.addMeetFG.value.type;
    aux.weekday = this.addMeetFG.value.weekday;
    [aux.hour, aux.minute] = this.parseTime(this.addMeetFG.value.time);
    aux.location = this.addMeetFG.value.location;
    this.apiService.addMeet(this.project._id, aux).subscribe(
      data => {
        this.project = data;
      }
    );
  }

  deleteEvent(meetingId) {
    this.apiService.deleteMeet(this.project._id, meetingId).subscribe(
      data => {
        this.project = data;
      }
    );
  }

  parseTime(time12h: string) {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':') as any;
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
    return [parseInt(hours), parseInt(minutes)];
  }

  openEditGdocs() {
    this.dialog.open(ProjectResourceEditorComponent, {
      data: {
        title: 'Cambiar link del informe',
        body: '',
        project: this.project,
        resourceName: 'draftPaperLink',
        formControl: new FormControl(this.project.draftPaperLink, [this.InvalidGDOCValidator]),
        formControlName: 'Link al informe'
      }
    });
  }

  back() {
    this._location.back();
  }


  openEditRepo() {
    this.dialog.open(ProjectResourceEditorComponent, {
      data: {
        title: 'Cambiar link al repositorio BitBucket',
        body: '',
        project: this.project,
        resourceName: 'repository',
        formControl: new FormControl(this.project.repository, [this.InvalidBitbucketValidator]),
        formControlName: 'Link al Repositorio'
      }
    });
  }

  openEmailSender() {
    const recipients = {};
    this.project.students.forEach(s => {
      recipients[s.email] = s;
    });
    this.project.tutors.forEach(s => {
      recipients[s.email] = s;
    });
    this.emailDialogS.openBasicEmail(Object.values(recipients));
  }

  InvalidGDOCValidator(control: FormControl) {
    // if (!/^(http:\/\/|https:\/\/)?docs\.google\.com\/document\/d\//i.test(control.value)) {
    //   return { invalidURL: true };
    // }
    return null;
  }

  InvalidBitbucketValidator(control: FormControl) {
    if (!/^(http:\/\/|https:\/\/)?(bitbucket.org\/itba\/|github.com\/)/i.test(control.value)) {
      return { invalidURL: true };
    }
    return null;
  }

  getErrorMessage(input: string) {
    const errorsAux = this.addMeetFG.controls[input]?.errors;
    if (errorsAux) {
      const keys = Object.keys(this.addMeetFG.controls[input].errors);
      if (!keys || keys.length === 0) return '';
      return errorMessages[keys[0]](errorsAux[keys[0]]);
    } else {
      return '';
    }
  }

  public get projectState(): typeof STATE {
    return STATE;
  }

  finishProject() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Finalizar proyecto',
        body: '¿Estas seguro que quieres dar el proyecto por terminado?'
      }
    }).afterClosed().subscribe(accepted => {
      if (accepted) {

        this.apiService.proposeFinishProject(this.project).subscribe(
          data => {
            this.project.state = data.state;
          }
        );
      }
    });
  }

  rejectFinishProject() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Cancelar Finalización',
        body: '¿Estas seguro que quieres rechazar la finalización del proyecto?'
      }
    }).afterClosed().subscribe(accepted => {
      if (accepted) {
        this.apiService.rejectFinishProject(this.project._id, this.auth.user).subscribe(
          () => {
            this.project.state = STATE.UNDERWAY;
            this.rejected = true;
          },
          error => {
            this.matSnackbar.open(error.error, null, { duration: 5000 });
          }
        );
      }
    });
  }

  adminFinishProject() {
    const ref = this.dialog.open(ProjectFinishDialogComponent, {
      data: {
        project: this.project
      }
    });
    ref.afterClosed().subscribe(accepted => {
      if (accepted) {
        const emailBodyValue = ref.componentInstance?.getEmailBodyValue();
        const updated_template = ref.componentInstance?.getUpdateTemplateValue();
        if (updated_template) {
          this.admin.updateFinishProjectEmailTemplate(emailBodyValue);
        }

      }
    });
  }

  capitalizeName(name: string): string {
    let capitalized = '';
    for (let i = 0; i < name.length; i++) {
      if (i === 0 || (name[i - 1] === ' ' && name[i] !== ' ')) {
        capitalized += name[i].toUpperCase();
      } else {
        capitalized += name[i].toLowerCase();
      }
    }
    return capitalized;
  }
}
