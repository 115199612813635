import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AuthService} from 'src/app/services/auth.service';


@Component({
  selector: 'app-teacher',
  templateUrl: './teacher.component.html',
  styleUrls: ['./teacher.component.scss']
})
export class TeacherComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    public authService: AuthService
  ) {
  }

  ngOnInit() {

  }

}
