import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogComponent} from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import {FormControl} from '@angular/forms';
import {Project} from 'src/app/models/Project';
import {ApiService} from 'src/app/services/api.service';

@Component({
  selector: 'app-project-resource-editor',
  templateUrl: './project-resource-editor.component.html',
  styleUrls: ['./project-resource-editor.component.scss']
})
export class ProjectResourceEditorComponent implements OnInit {

  resource: FormControl;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    public api: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string, body: string, project: Project,
      resourceName: string, formControl: FormControl, formControlName: string
    }
  ) {
    this.resource = data.formControl;
  }

  ngOnInit(): void {
  }

  editResource(): void {
    this.resource.markAsTouched();
    if (this.resource.invalid) {
      return;
    }
    let p = {};
    p[this.data.resourceName] = this.resource.value;
    this.api.editProject(this.data.project._id, p).subscribe((p) => {
      this.data.project[this.data.resourceName] = this.resource.value;
      this.dialogRef.close(true);
    });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

}
