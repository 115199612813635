import {Component, Inject, OnInit} from '@angular/core';
import {ApiService} from "../../../../services/api.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Project} from "../../../../models/Project";
import {Teacher} from "../../../../models/Teacher";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {MatOptionSelectionChange} from "@angular/material/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-jury-editor',
  templateUrl: './jury-editor.component.html',
  styleUrls: ['./jury-editor.component.scss']
})
export class JuryEditorComponent implements OnInit {

  juryForm: FormGroup;

  filteredTeachers: Observable<Teacher[]>;

  errorMessage: string;

  constructor(public dialogRef: MatDialogRef<JuryEditorComponent>,
              private formBuilder: FormBuilder,
              private api: ApiService,
              @Inject(MAT_DIALOG_DATA) public data: {
                project: Project,
                teachers: Teacher[]
              }) {
  }

  ngOnInit(): void {
    this.juryForm = this.formBuilder.group({
      jury: ['', [Validators.required, Validators.email]],
      name: ['', [Validators.minLength(2), Validators.maxLength(25)]]
    });
    this.filteredTeachers = this.jury.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  private _filter(value: string): Teacher[] {
    const filterValue = value.toLowerCase();
    return this.data.teachers.filter(teacher => teacher.email.toLowerCase().includes(filterValue)
      || teacher.firstName?.toLowerCase().includes(filterValue)
      || teacher.lastName?.toLowerCase().includes(filterValue));
  }


  get jury() {
    return this.juryForm.get('jury');
  }

  get name() {
    return this.juryForm.get('name');
  }

  addJuror() {
    try {
      if (this.juryForm.invalid) {
        return;
      }
      const jury: any[] = this.data.project.jury ? [...this.data.project.jury] : [];
      if (!jury.find(existing => existing.jury.toLowerCase() == this.juryForm.value.jury.toLowerCase())) {
        jury.push({
          jury: this.juryForm.value.jury,
          name: this.juryForm.value.name
        });
        this.api.editJury(this.data.project._id, jury).subscribe(
          () => {
            this.data.project.jury = jury;
            this.dialogRef.close();
          }
        );
      } else {
        this.errorMessage = null;
        throw new Error('Jurado duplicado.');
      }
    }catch(error){
      this.errorMessage = "Jurado duplicado.";
    }
  }

  onTeacherSelected($event: MatOptionSelectionChange) {
    const teacherEmail: string = $event.source.value;
    if (teacherEmail) {
      const teacher: Teacher = this.data.teachers.find(t => t.email.toLowerCase() === teacherEmail.toLowerCase());
      if (teacher && teacher?.firstName && teacher?.lastName) {
        this.name.setValue(teacher.firstName + ' ' + teacher.lastName);
      }
    }
  }
}
