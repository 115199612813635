<div class="container-custom">
  <div *ngIf="!projects || projects?.length === 0" style="margin: 0 auto; text-align: center">
    <mat-card>
      <h3>No hay próximas defensas por el momento</h3>
    </mat-card>
  </div>
  <mat-card *ngFor="let project of upcomingPresentations; index as i" class="proposal" style="margin-top: 30px; margin-bottom: 30px;">
    <p style="font-size: 1.1em; margin-bottom: 5px; margin-top: 5px; color: #757575; font-weight: 200">{{project?.startDate[0]}}, C{{project?.startDate[1]}} - <span style="font-weight: 200; font-size: 1.1em; color: #64b900">Próxima</span></p>
    <h3 style="font-size: 1.6em; margin-bottom: 12px; margin-top: 6px;font-weight: 400;overflow: auto; display: -webkit-box; -webkit-line-clamp: 1;-webkit-box-orient: vertical">{{project.title | uppercase}}</h3>
    <p style="font-size: 1.05em; text-align: justify; overflow: auto; display: -webkit-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical; margin: 0; line-height: 1.2em;">{{project.abstract}}</p>
    <div>
      <p class="text" *ngIf="project?.tutors?.length" style="overflow: auto; display: -webkit-box; -webkit-line-clamp: 1;-webkit-box-orient: vertical">
        <b>{{project?.tutors.length > 1? 'Tutores:' : 'Tutor:'}} </b>
        <span *ngFor="let tutor of project?.tutors; index as i" [routerLink]="['/teachers', tutor.email]"
              class="click" style="cursor: pointer;">{{capitalizeName(tutor.firstName)}} {{capitalizeName(tutor.lastName)}} - {{tutor.email}}<span
          *ngIf="(i + 1)  < project?.tutors?.length">,</span>   </span>
      </p>
      <p *ngIf="project?.students?.length" class="text" style="overflow: auto; display: -webkit-box; -webkit-line-clamp: 1;-webkit-box-orient: vertical">
        <b>{{project?.students.length > 1? 'Alumnos:' : 'Alumno:'}} </b>
        <span *ngFor="let student of project?.students; index as i">{{student.email}} <span
          *ngIf="student.firstName">({{capitalizeName(student.lastName)}}, {{capitalizeName(student.firstName)}})</span>
                    <span *ngIf="(i + 1) < project?.students?.length"> - </span>
                </span>
      </p>
    </div>
    <p class="text" *ngIf="project.fieldOfStudy"><b>Área de Estudio:</b> {{project?.fieldOfStudy}}</p>
    <div>
      <p class="text"><b>Fecha de presentación: </b>{{project?.presentationDate | date:'dd/MM/yyyy'}}
        <span *ngIf="project.presentationTime">, {{project?.presentationTime}}</span></p>
      <p class="text" *ngIf="project.publicPresentation && project.meetingLink"><b>Link de la reunión: </b>
        <a *ngIf="project?.meetingLink" href="{{ project?.meetingLink }}">{{ project?.meetingLink}}</a>
      </p>
    </div>
  </mat-card>

  <mat-card *ngFor="let project of pastPresentations; index as i" class="proposal" style="margin-top: 30px; margin-bottom: 30px">
    <p style="font-size: 1.1em; margin-bottom: 5px; margin-top: 5px; color: #757575; font-weight: 200" *ngIf="project.presentationDate< today">{{project?.startDate[0]}}, C{{project?.startDate[1]}} - <span style="font-weight: 200; font-size: 1.1em; color: #f44336">Finalizado</span></p>
    <h3 style="font-size: 1.6em; margin-bottom: 12px;margin-top: 6px;font-weight: 400;overflow: auto; display: -webkit-box; -webkit-line-clamp: 1;-webkit-box-orient: vertical">{{project.title | uppercase}}</h3>
    <div class="module line-clamp">
      <p style="font-size: 1.05em; text-align: justify; overflow: auto; display: -webkit-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical; margin: 0; line-height: 1.2em;">{{project.abstract}}</p>
    </div>
    <div>
      <p class="text" *ngIf="project?.tutors?.length" style="overflow: auto; display: -webkit-box; -webkit-line-clamp: 1;-webkit-box-orient: vertical">
        <b>{{project?.tutors.length > 1? 'Tutores:' : 'Tutor:'}} </b>
        <span *ngFor="let tutor of project?.tutors; index as i" [routerLink]="['/teachers', tutor.email]"
              class="click" style="cursor: pointer;">{{capitalizeName(tutor.firstName)}} {{capitalizeName(tutor.lastName)}} - {{tutor.email}}<span
          *ngIf="(i + 1)  < project?.tutors?.length">,</span>   </span>
      </p>
      <p *ngIf="project?.students?.length" class="text" style="overflow: auto; display: -webkit-box; -webkit-line-clamp: 1;-webkit-box-orient: vertical">
        <b>{{project?.students.length > 1? 'Alumnos:' : 'Alumno:'}} </b>
        <span *ngFor="let student of project?.students; index as i">{{student.email}} <span
          *ngIf="student.firstName">({{capitalizeName(student.lastName)}}, {{capitalizeName(student.firstName)}})</span>
                    <span *ngIf="(i + 1) < project?.students?.length"> - </span>
                </span>
      </p>
    </div>
    <div>
      <p class="text"><b>Fecha de presentación: </b>{{project?.presentationDate | date:'dd/MM/yyyy'}}<span
        *ngIf="project.presentationTime">, {{project?.presentationTime}}</span></p>
      <p class="text" *ngIf="project.publicPresentation && project?.meetingLink"><b>Link de la reunión: </b>
        <a *ngIf="project?.meetingLink" href="{{ project?.meetingLink }}">{{ project?.meetingLink}}</a>
      </p>
    </div>
  </mat-card>
</div>

