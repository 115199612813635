import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "../../../../services/api.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Project } from "../../../../models/Project";
import { AdminService } from "../../../services/admin.service";


const defaultMessage = 'Hemos recibido la notificación de la finalización del proyecto. El próximo paso es la defensa, para lo cual la cátedra convocará a dos jurados. En cuanto definamos jurado y fecha posible, les llegará un mail indicando los detalles.\nPara la defensa, informamos que la presentación debe durar entre 20 y 30 minutos incluyendo demo, la cual debe ser en vivo. Luego de la presentación, el jurado formulará preguntas a los alumnos. Todo el proceso involucra aproximadamente 1 hora.\nPor favor, informen a la cátedra si prefieren que la defensa sea presencial o virtual, y en caso de ser presencial, la cantidad de invitados que esperan\nSe adjunta en este mail un archivo que deberá ser firmado y enviado a vsoliani@itba.edu.ar a la brevedad, para autorizar la publicación del trabajo en la biblioteca digital del ITBA.\nSaludos';



@Component({
    selector: 'app-project-finish-dialog',
    templateUrl: './project-finish-dialog.component.html',
    styleUrls: ['./project-finish-dialog.component.scss']
})
export class ProjectFinishDialogComponent implements OnInit {

    public finishForm: FormGroup | null = null;


    constructor(private formBuilder: FormBuilder,
        private api: ApiService,
        private adminService: AdminService,
        public dialogRef: MatDialogRef<ProjectFinishDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { project: Project }) {
            
    }

    ngOnInit(): void {

        this.api.getCurrentFinishEmailTemplate().subscribe(
            (data: string) => {
                this.getTemplate(data);
            },
            (error) => {
                console.error('Error fetching template:', error);
            }
        );

    }

    getTemplate(emailTemplate: string) {

        const today = new Date();

        this.finishForm = this.formBuilder.group({
            emailBody: [emailTemplate, [Validators.required]],
            date: [today, [Validators.required]],
            sendAttachment: [true],
            sendMail: [true],
            updateTemplate: [false]
        })
    }

    get emailBody() {
        return this.finishForm.get('emailBody');
    }


    getEmailBodyValue() {
        return this.finishForm.get('emailBody').value;
    }

    getUpdateTemplateValue() {
        return this.finishForm.get('updateTemplate').value;
    }

    get sendMail() {
        return this.finishForm.get('sendMail');
    }
    get finishDate() {
        return this.finishForm.get('date');
    }

    get updateTemplate() {
        return this.finishForm.get('updateTemplate');
    }

    finishProject() {
        this.adminService.finishProject(this.data.project,
            {
                emailBody: this.emailBody.value,
                sendMail: this.sendMail.value,
                endDate: this.finishDate.value,
                updateTemplate: this.updateTemplate.value
            }
        ).subscribe(
            data => {
                this.data.project.state = data.state;
                this.dialogRef.close(true);

            },
            () => {
                this.dialogRef.close(false);
            }
        );
    }
}
