<div class="container">
  <h1 mat-dialog-title *ngIf="data.title" [innerHTML]="data.title"></h1>
  <b [innerHTML]="data.body"></b>
  <p style="display: flow; text-align: justify;">{{data.info}}</p>
  <p style="text-align: justify;">Si es parte de la institución ITBA ingrese con su cuenta al siguiente <a href={{data.internalLink}}>link</a></p>
  <p style="text-align: justify;">Si no es parte de la institución ingrese mediante el siguiente <a href="{{data.externalLink}}">link</a></p>
  <div mat-dialog-actions style="margin-top: 0">
    <div *ngIf="emailBody" class="col-12" [formGroup]="emailBody">
      <div class="row">
        <mat-form-field class="templateField">
          <mat-label>Comentario</mat-label>
          <mat-hint>Opcional</mat-hint>
          <textarea style="text-align: justify;" matInput matNativeControl cdkTextareaAutosize
            formControlName="email_default_body"></textarea>
        </mat-form-field>
      </div>
      
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-5 col-xs-12">
          <mat-slide-toggle color="primary" matNativeControl formControlName="checkbox_control">Enviar
            email</mat-slide-toggle>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-5 col-xs-12">
          <mat-slide-toggle color="primary" matNativeControl formControlName="update_template_control">Actualizar
            comentario</mat-slide-toggle>
        </div>
      </div>


    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button color="primary" [mat-dialog-close]="true">Confirmar</button>
  </div>
  <img src="assets/icons/close.svg" alt="" (click)="dialogRef.close(false)" cdkFocusInitial>
</div>
