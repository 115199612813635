import {Pipe, PipeTransform} from '@angular/core';
import {Project, STATE} from "../../models/Project";

@Pipe({
  name: 'ongoingProjects'
})
export class OngoingProjectsPipe implements PipeTransform {

  transform(projects: Project[]): number {
    return projects.filter(p => p.state === STATE.UNDERWAY || p.state === STATE.PENDING_FINISH_CONFIRMATION).length;
  }

}
