<div class="container" *ngIf="project" xmlns="http://www.w3.org/1999/html">
  <div class="back" (click)="back()" *ngIf="!(auth.accessByToken || (auth.isStudent && auth.userS.hasOwnProperty('project')))">
    <mat-icon>arrow_back</mat-icon>
  </div>
  <app-project-card *ngIf="project?.state === projectState.FINISHED; else notFinished"
    [project]="project"></app-project-card>
  <ng-template #notFinished>
    <div class="col-12 project-info">
      <mat-card class="card"
        [ngClass]="{underway: project?.state === projectState.UNDERWAY, pendingFinish: project?.state === projectState.PENDING_FINISH_CONFIRMATION}"
        style="width: 85%">
        <div class="row">
          <div class="col-10 col-lg-9 col-md-7 col-sm-6 col-xs-6" style="padding-right: 0">
            <h2 class="edit-title">{{project?.title | uppercase}}</h2>
          </div>
          <div class="col-2 col-lg-3 col-md-5 col-sm-6 col-xs-6">
            <div class="action-btn-group" *ngIf="auth.isAdmin" style="justify-content: right;float: right;">
              <mat-icon class="edit" style="color:black; margin-right: 10px;" *ngIf="canEdit"
                [routerLink]="['edit']">edit</mat-icon>
              <button mat-stroked-button class="btn-blue" (click)="openEmailSender()">Enviar mail al equipo</button>
            </div>
          </div>
        </div>
        <div class="col-12" style="display: flex;">
          <div class="col-9 middle">
            <b>{{project?.students.length > 1? 'Alumnos:' : 'Alumno:'}} </b>
            <div *ngFor="let student of project?.students">
              <p *ngIf="student.firstName; else noName" class="capitalize">
                {{student?.firstName | lowercase }} {{student?.lastName | lowercase}}
              </p>
              <ng-template #noName>
                <p>
                  {{student.email}}
                </p>
              </ng-template>
            </div>
            <b>{{project?.tutors.length > 1? 'Tutores:' : 'Tutor:'}} </b>
            <div *ngFor="let tutor of project?.tutors">
              <p *ngIf="tutor.firstName; else noName" class="capitalize" [routerLink]="['/teachers', tutor.email]"
                style="cursor: pointer">
                {{capitalizeName(tutor.firstName) + ' ' + capitalizeName(tutor.lastName)}}
              </p>
              <ng-template #noName>
                <p [routerLink]="['/teachers', tutor.email]" style="cursor: pointer">
                  {{tutor.email}}
                </p>
              </ng-template>
            </div>
          </div>
        </div>
        <h2 style="margin-bottom: 0;font-size: 1.5rem">Documentación</h2>
        <div *ngIf="!project?.draftPaperLink" class="col-12">
          <b>Informe: </b>
          <button *ngIf="!project?.draftPaperLink && (this.auth.isStudent || this.auth.isAdmin || this.auth.isTeacher)"
            mat-stroked-button class="download-btn" (click)="openEditGdocs()"><mat-icon
              style="margin-bottom: 3px;">add</mat-icon>Google Doc</button>
        </div>
        <div class="col-12" *ngIf="!project?.draftPaperLink" style="display: flex;">
          <div class="col-9 no">
            <p>Se recomienda ir trabajando en un borrador del informe una vez comenzado el proyecto.</p>
          </div>
        </div>
        <div *ngIf="project?.draftPaperLink" class="col-12 yes" style="display: flex;margin-bottom: 4px">
          <b>Informe: </b>
          <a class="edit"
            [href]="project.draftPaperLink.includes('http')?project.draftPaperLink:'https://'+project.draftPaperLink">{{project.draftPaperLink.includes('http')?project.draftPaperLink:'https://'+project.draftPaperLink}}</a>
          <mat-icon (click)="openEditGdocs()" class="edit-icon edit">edit</mat-icon>
        </div>
        <div class="col-12" *ngIf="!project?.repository">
          <b>Bitbucket: </b>
          <button *ngIf="!project?.repository && (this.auth.isStudent || this.auth.isAdmin || this.auth.isTeacher)"
            mat-stroked-button class="download-btn" (click)="openEditRepo()"><mat-icon
              style="margin-bottom: 3px;">add</mat-icon> Bitbucket</button>
        </div>
        <div class="col-12" *ngIf="!project?.repository" style="display: flex;margin-bottom: 4px">
          <div class="col-9 middle">
            <p>Se debe agregar un repositorio Bitbucket una vez comenzado el proyecto.</p>
          </div>
        </div>

        <div *ngIf="project?.repository" style="display: flex;">
          <b>Bitbucket:</b>
          <a class="edit"
            [href]="project.repository.includes('http')?project.repository:'https://'+project.repository">{{project.repository.includes('http')?project.repository:'https://'+project.repository}}</a>
          <mat-icon (click)="openEditRepo()" class="edit-icon edit">edit</mat-icon>
        </div>
        <mat-chip-list #chipList class="chips">
          <mat-basic-chip *ngFor="let tag of project?.tags; let i = index">
            {{tag}}
          </mat-basic-chip>
        </mat-chip-list>
        <div class="col-12 bt">
          <button mat-stroked-button *ngIf="studentOrTutor" class="btn-red" style="margin-top: 10px;"
            (click)="finishProject()">Finalizar proyecto</button>
          <button *ngIf="auth.isAdmin && project?.state === projectState.UNDERWAY" mat-stroked-button class="btn-red"
            style="margin-top: 10px;" (click)="adminFinishProject()">Finalizar proyecto</button>
        </div>   
      </mat-card>
    </div>
    <div class="col-12" style="text-align: center; display: block; justify-content: space-around;">
      <div *ngIf="project?.state === projectState.PENDING_FINISH_CONFIRMATION" class="confirm-wrapper">
        <div class="col-12" style="margin-bottom: 10px; padding: 5px;">
          <h2>Proyecto finalizado: esperando confirmación</h2>
        </div>
        <div *ngIf="auth.isAdmin" class="col-12 finish-action-buttons">
          <div class="col-6" style="text-align-last: end">
            <button mat-stroked-button class="btn-blue" style="text-align-last: center; margin-right: 5px; width: 30%;"
              (click)="adminFinishProject()">Confirmar
              finalización
            </button>
          </div>
          <div class="col-6" style="text-align-last: start">
            <button mat-stroked-button class="btn-red" style="text-align-last: center; margin-left: 5px; width: 30%;"
              (click)="rejectFinishProject()">Rechazar</button>
          </div>
        </div>
      </div>
      <app-milestones-viewer
        *ngIf="(project?.state === projectState.UNDERWAY) && (this.auth.isStudent || this.auth.isAdmin || this.auth.isTeacher)"
        [project]="project" style="align-content: center"></app-milestones-viewer>
    </div>
  </ng-template>
</div>
