<div class="container">
  <div class="back" (click)="back()">
    <mat-icon>arrow_back</mat-icon>
  </div>
  <div class="col-12 project-info" >
    <mat-card class="card">
      <section class="project-details">
        <div class="col-12 header">
          <div class="col-11">
            <h2 style="margin-bottom: 2px;">{{proposal?.title | uppercase}}</h2>
          </div>
          <div class="col-1">
				<span class="edit" *ngIf="canEdit">
					<mat-icon [routerLink]="['edit']" class="cursor">edit</mat-icon>
				</span>
            <span class="edit" *ngIf="auth.isAdmin">
					<mat-icon (click)="deleteProject()" class="cursor">delete_outline</mat-icon>
				</span>
          </div>
        </div>
        <div class="body">
          <p style="text-align: justify">{{proposal?.abstract}}</p>
          <span><b>Autor: </b>
          <div *ngIf="proposal?.creator?.firstName.length==0; else noCreatorName;">
            <p>
            {{proposal?.creator?.email}}
            </p>
          </div>
          <ng-template #noCreatorName>
            <p>{{capitalizeName(proposal?.creator?.firstName)}} {{capitalizeName(proposal?.creator?.lastName)}}
              - {{proposal?.creator?.email}}
            </p>
          </ng-template>
        </span>
          <div>
            <div *ngIf="proposal?.tutors?.length">
              <b>{{proposal?.tutors.length > 1? 'Tutores:' : 'Tutor:'}} </b>
              <div *ngFor="let tutor of proposal?.tutors; index as i" [routerLink]="['/teachers', tutor.email]">
                <p *ngIf="tutor.firstName; else noName" class="capitalize" style="cursor: pointer;">
                  {{capitalizeName(tutor.firstName) + ' ' + capitalizeName(tutor.lastName)}}
                </p>
                <ng-template #noName>
                  <p>
                    {{tutor.email}}
                  </p>
                </ng-template>
              </div>
            </div>
            <div *ngIf="proposal?.posibleTutors?.length!=0">
              <b>{{proposal?.posibleTutors?.length>1 ? "Tutores tentativos: ": "Tutor tentativo: "}}</b>
              <p *ngFor="let tutor of proposal?.posibleTutors; index as i" class="capitalize"> {{capitalizeName(tutor)}}</p>
            </div>
            <div *ngIf="proposal?.students?.length">
              <b>{{proposal?.students?.length>1 ? "Alumnos: ": "Alumno: "}}</b>
              <p *ngFor="let student of proposal?.students; index as i">{{student.email | lowercase}} </p>
            </div>
          </div>
          <div class="extras">
            <span *ngIf="proposal?.deliverables"><b>Materiales a entregar:</b><p>{{proposal?.deliverables}}</p></span>
            <p *ngIf="proposal?.subjects.length"><b>Materias necesarias:</b> {{proposal?.subjects}}</p>
            <p *ngIf="auth.isAdmin && proposal?.expectedDurationMonths"><b>Duración
              estimada:</b> {{proposal?.expectedDurationMonths}} meses.</p>
            <p *ngIf="auth.isAdmin"><b>Tipo de proyecto:</b> {{proposal?.investigationProject ? 'Investigación' :
              ''}}{{proposal?.investigationProject && proposal?.developmentProject ? ',' : ''}}
              {{proposal?.developmentProject ? 'Desarrollo' : ''}}</p>
            <p *ngIf="auth.isAdmin"><b>Objetivos:</b> {{proposal?.objectives | showOrNA}}</p>
            <p *ngIf="proposal?.type && proposal?.maxStudents"><b>Cantidad máxima de alumnos:</b> {{proposal?.maxStudents}}</p>
            <p *ngIf="proposal?.requisites"><b>Requisitos:</b> {{proposal?.requisites}}</p>
            <p *ngIf="proposal?.extraComments"><b>Comentarios extra:</b> {{proposal?.extraComments | showOrNA}}</p>
            <mat-chip-list #chipList class="chips">
              <mat-basic-chip *ngFor="let tag of proposal?.tags; let i = index">
                {{tag}}
              </mat-basic-chip>
            </mat-chip-list>
          </div>
        </div>
      </section>
      <section class="applications" *ngIf="canApply">
        <div class="actions teacher" *ngIf="proposal?.applications?.length && (auth.isAdmin || (auth.isTeacher && canEdit))">
          <h2 style="font-size: 1.5rem!important;margin-bottom: 6px;">Grupos anotados</h2>
          <div class="group" *ngFor="let application of proposal?.applications">
            <hr>
            <h5 style="font-size: 1.2rem!important;">Grupo {{application.isPublic ? 'público' : 'privado'}}</h5>
            <div class="row">
              <div class="col-3">
                <b>{{application.students.length>1 ? 'Integrantes:' : 'Integrante:'}}</b>
                <p *ngFor="let student of application.students">{{student.email}}</p>
                <p><b>Prioridad:</b> {{getPriorityText(application.priority)}}</p>
              </div>
              <div class="col-2" style="text-align: center;">
                <button mat-stroked-button class="btn-red" style="width: 150px" (click)="unsubTeam(application._id)">Desanotar equipo</button>
              </div>
              <div class="col-2" style="text-align: center;">
                <button mat-stroked-button class="btn-blue" style="width: 150px" (click)="openProjectApplicationEditor(application)">Editar equipo</button>
              </div>
              <div class="col-2" style="text-align: center;">
                <button mat-stroked-button class="btn-blue" style="width: 150px" (click)="acceptTeam(application)">Aceptar equipo</button>
              </div>
            </div>
            <hr>
          </div>
        </div>
        <div class="actions" *ngIf="!alreadyApplied">
          <div class="student-action">
            <div *ngIf="auth.isStudent">
              <h3>Aplicar como grupo</h3>
              <p>
                Si ya tenés un grupo formado, anotá a tus colegas. Podés crear un grupo abierto para que otros se
                unan.
              </p>
            </div>
            <div>
              <form [formGroup]="applicationForm" *ngIf="formCreated && !proposal?.students?.length">
                <h2 style="margin-bottom: 16px;font-size: 1.5rem!important;" *ngIf="!auth.isStudent">Añadir grupo</h2>
                <div class="col-12 options" [ngClass]="{appear: groupOptionsDisplayed}">
                  <div class="row">
                    <div class="col-3">
                      <mat-form-field style="margin-left: 0;">
                        <mat-label>Prioridad</mat-label>
                        <mat-select [formControl]="priorityControl" required>
                          <mat-option [value]="-1">--</mat-option>
                          <mat-option [value]="1">1 - Ideal</mat-option>
                          <mat-option [value]="2">2 - Alta</mat-option>
                          <mat-option [value]="3">3 - Media</mat-option>
                        </mat-select>
                        <mat-error *ngIf="priorityControl.invalid">{{getErrorMessage('priority')}}</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-9" style="padding-bottom: 20px;align-self: flex-end;text-align: left;">
                      <mat-checkbox color="primary" style="padding-left: 0px;" [(ngModel)]="isPublicApplication"
                                    [ngModelOptions]="{standalone: true}">Grupo abierto
                      </mat-checkbox>
                    </div>
                  </div>
                  <div class="col-12" formArrayName="partners">
                    <div class="row justify-content-center">
                      <div *ngFor="let partner of partners.controls; let i=index" class="col-lg-3 col-md-6 col-sm-6 col-xs-6" style="text-align: center">
                        <div [formGroupName]="i">
                          <mat-form-field style="margin-left: 0;">
                            <input matInput placeholder="Email alumno {{i + 1}}" formControlName="email"
                                   type="text" aria-label="Number" [matAutocomplete]="auto">
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6" style="align-self: center;text-align: start;">
                        <button type="submit" mat-stroked-button class="btn-blue" *ngIf="!auth.isStudent" (click)="applyGroup()">
                          Agregar
                        </button>
                      </div>
                    </div>
                  </div>
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let student of filteredStudents | async" [value]="student">
                      {{student}}
                    </mat-option>
                  </mat-autocomplete>
                </div>
                <div class="col-2" style="padding-left: 0px; text-align-last: center;">
                  <button type="submit" mat-stroked-button class="btn-blue" *ngIf="auth.isStudent" style="width: 100%;" (click)="applyGroup()">
                    Aplicar como grupo
                  </button>
                </div>
              </form>

            </div>
          </div>
          <div class="student-action" *ngIf="auth.isStudent">
            <h3>Aplicar solo</h3>
            <div class="col-12" style="display: flex; flex-direction: column;">
              <div class="col-4">
                <mat-form-field style="width: 100%;">
                  <mat-label>Prioridad</mat-label>
                  <mat-select [formControl]="priorityControl" required>
                    <mat-option [value]="-1">--</mat-option>
                    <mat-option [value]="1">1 - Ideal</mat-option>
                    <mat-option [value]="2">2 - Alta</mat-option>
                    <mat-option [value]="3">3 - Media</mat-option>
                    <mat-option [value]="4">4 - Baja</mat-option>
                  </mat-select>
                  <mat-error *ngIf="priorityControl.invalid">{{getErrorMessage('priority')}}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-2">
                <button mat-stroked-button class="btn-blue" style="width: 100%;" (click)="createGroupAlone(false)">Aplicar solo</button>
              </div>
            </div>
          </div>
          <div class="student-action" *ngIf="auth.isStudent">
            <h3>No tengo grupo</h3>
            <p>
              Si no tenés un grupo organizado podés unirte a uno existe o crear un grupo abierto para que otros se
              unan.
            </p>
            <div class="groups" *ngIf="isThereAnyOpenGroups()">
              <div class="group" *ngFor="let application of getPublicGroups()">
                <h3>Grupo abierto: </h3>
                <div class="col-12 hor" style="display: flex; flex-direction: column;">
                  <div class="col-4 group-members">
                    <p *ngFor="let student of application.students" class="capitalize">{{student.firstName | lowercase}}
                      {{student.lastName | lowercase}}<span style="text-transform: lowercase;"> - {{student.email}}</span></p>
                  </div>
                  <div class="col-2 group-actions">
                    <button mat-stroked-button class="btn-blue" style="width: 100%;" (click)="joinTeam(application._id)">Unirse</button>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!isThereAnyOpenGroups()">
              <b>No hay grupos abiertos disponibles</b>
              <div class="col-12" style="display: flex; flex-direction: column;">
                <div class="col-4" style="padding-top: 10px;">
                  <mat-form-field style="width: 100%;">
                    <mat-label>Prioridad</mat-label>
                    <mat-select [formControl]="priorityControl" required>
                      <mat-option [value]="-1">--</mat-option>
                      <mat-option [value]="1">1 - Ideal</mat-option>
                      <mat-option [value]="2">2 - Alta</mat-option>
                      <mat-option [value]="3">3 - Media</mat-option>
                      <mat-option [value]="4">4 - Baja</mat-option>
                      <mat-option [value]="5">5 - Muy baja</mat-option>
                    </mat-select>
                    <mat-error *ngIf="priorityControl.invalid">{{getErrorMessage('priority')}}</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-2">
                  <button mat-stroked-button class="btn-blue" style="width: 100%;" (click)="createGroupAlone(true)">Crear grupo
                    abierto
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="actions" *ngIf="auth.isStudent && alreadyApplied && individual">
          <h5>Ya aplicaste a este proyecto con el siguiente grupo:</h5>
          <p *ngFor="let student of alreadyApplied.students">- {{student.email}}</p>
          <button *ngIf="alreadyApplied.students[0]._id === auth.user._id" mat-stroked-button class="btn-blue"
                  (click)="openProjectApplicationEditor(alreadyApplied)">Editar
          </button>
        </div>
      </section>
      <div class="launch" *ngIf="auth.isAdmin && !proposal?.state">
        <button mat-stroked-button class="btn-blue" style="width: 250px"
                [disabled]="!proposal?.students?.length || !proposal?.tutors?.length" (click)="launch()">
          Lanzar proyecto
        </button>
        <div class="errors">
          <p *ngIf="!proposal?.students?.length && proposal?.tutors?.length">Aún faltan alumnos para poder lanzarlo
          </p>
          <p *ngIf="!proposal?.tutors?.length && proposal?.students?.length">Aún faltan tutores para poder lanzarlo
          </p>
          <p *ngIf="!proposal?.students?.length && !proposal?.tutors?.length">Aún faltan tutores y alumnos para poder lanzarlo</p>
        </div>
      </div>
    </mat-card>
  </div>
</div>
