<div class="container">
    <div class="projects" *ngIf="student?.proposals?.length">
        <h3>Mis propuestas</h3>
        <mat-table [dataSource]="student?.proposals">
            <ng-container matColumnDef="project">
            <mat-header-cell *matHeaderCellDef style="min-width: 80%">Proyecto</mat-header-cell>
            <mat-cell *matCellDef="let row" style="min-width: 80%">{{row?.startDate[0]}}, C{{row?.startDate[1]}} - {{row?.title}}</mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="['project']"></mat-header-row>
            <mat-row class="project underway" *matRowDef="let row; columns: ['project']" (click)="router.navigate(['/proposal', row._id])" ></mat-row>
        </mat-table>
    </div>
    <div class="projects" *ngIf="student?.applications?.length">
      <h3 style="margin-top: 20px;">Proyectos aplicados</h3>
      <mat-table [dataSource]="student?.applications">
        <ng-container matColumnDef="project">
          <mat-header-cell *matHeaderCellDef style="min-width: 80%">Proyecto</mat-header-cell>
          <mat-cell *matCellDef="let row" style="min-width: 80%">{{row?.project.startDate[0]}}, C{{row?.project.startDate[1]}} - {{row.project.title}}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="['project']"></mat-header-row>
        <mat-row class="project underway" *matRowDef="let row; columns: ['project']" (click)="router.navigate(['/proposal', row.project._id])" ></mat-row>
      </mat-table>
    </div>
    <div style="align-self: center; padding-top: 20px;" *ngIf="(!(student?.proposals?.length) && student?.applications?.length)">
        <h3>Aún no has propuesto ningún proyecto.</h3>
    </div>
    <div style="align-self: center; padding-top: 20px;" *ngIf="(!(student?.applications?.length) && (student?.proposals?.length))">
      <h3>Aún no has aplicado a ningún proyecto.</h3>
    </div>
    <div style="align-self: center; padding-top: 20px;" *ngIf="(!(student?.applications?.length) && !(student?.proposals?.length))">
        <h3>Aún no has propuesto ni aplicado a proyectos.</h3>
    </div>
</div>
  
  
  