import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Proposal } from 'src/app/models/Proposal';
import { ProjectApplication } from 'src/app/models/ProjectApplication';
import { Milestone } from 'src/app/models/Milestone';
import { Project } from 'src/app/models/Project';
import { Meeting } from 'src/app/models/Meeting';
import { Teacher } from 'src/app/models/Teacher';
import { BehaviorSubject, Observable } from 'rxjs';
import { EmailType } from 'src/app/models/EmailType';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, share } from 'rxjs/operators';
import { User, USER_TYPE } from '../models/User';
import { Student } from "../models/Student";
import { Semester } from "../administrator/components/projects/projects.component";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private _headers = new HttpHeaders().append('Content-Type', 'application/json');
  public currentSemester: BehaviorSubject<number[]> = new BehaviorSubject([2020, 2]);
  public inscriptionInterval: BehaviorSubject<{ start: Date, end: Date }> = new BehaviorSubject({
    start: new Date(1596250800000),
    end: new Date(1597028400000)
  });
  public teachers: BehaviorSubject<Teacher[]> = new BehaviorSubject([]);
  public tags: BehaviorSubject<string[]> = new BehaviorSubject([]);

  public selectedSemesters: BehaviorSubject<Semester[]> = new BehaviorSubject([]);
  public selectedPriorities: BehaviorSubject<number[]> = new BehaviorSubject([]);
  public selectedStates: BehaviorSubject<number[]> = new BehaviorSubject([]);

  public currentLaunchProjectEmailTemplate: BehaviorSubject<string> = new BehaviorSubject(" ");

  public currentFinishProjectEmailTemplate: BehaviorSubject<string> = new BehaviorSubject(" ");

  public currentConfirmPresEmailTemplate: BehaviorSubject<string> = new BehaviorSubject(" ");

  public currentJuryAccessEmailTemplate: BehaviorSubject<string> = new BehaviorSubject(" ");

  constructor(private auth: AuthService, private http: HttpClient, private _matSnackbar: MatSnackBar) {
    this.init();
  }

  private get headers() {
    if (this.auth.user) {
      return this._headers.append('x-access-token', this.auth.user.accessToken);
    }else {
      return this._headers;
    }
  }

  

  private init() {
    this.loadCurrentSemesterFromLS();
    this.getCurrentSemester();
    this.getInscriptionInterval();
    this.getTeachers();
    this.getTags();
    this.getCurrentLaunchEmailTemplate();
    this.getCurrentFinishEmailTemplate();
    this.getCurrentConfirmPresEmailTemplate();
  }

  public saveStateFilters(selectedStates: number[]) {
    this.selectedStates.next(selectedStates);
  }

  public savePriorityFilters(selectedPriorities: number[]) {
    this.selectedPriorities.next(selectedPriorities);
  }

  public saveSemesterFilters(selectedSemesters: Semester[]) {
    this.selectedSemesters.next(selectedSemesters);
  }

  public getLastSelectedSemesters(): Semester[] {
    return this.selectedSemesters.getValue();
  }

  public getLastSelectedStates(): number[] {
    return this.selectedStates.getValue();
  }

  public getLastSelectedPriorities(): number[] {
    return this.selectedPriorities.getValue();
  }

  public getTeachers() {
    this.http.get<Teacher[]>(`${environment.apiURL}/teachers`, { headers: this.headers }).pipe(share()).subscribe((teachers) => {
      teachers.forEach(t => t.fullName = `${t.firstName} ${t.lastName}`);
      this.teachers.next(teachers);
    });
    return this.teachers;
  }

  public getAllSemesters(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiURL}/semesters`, { headers: this.headers });
  }

  public getTags() {
    this.http.get<string[]>(`${environment.apiURL}/tags`, { headers: this.headers }).pipe(share()).subscribe((tags) => {
      this.tags.next(tags);
    });
    return this.tags;
  }

  public getCurrentLaunchEmailTemplate(): Observable<string> {
    return this.http.get<string>(`${environment.apiURL}/current_launch_project_email_template`, { headers: this.headers });
  }

  public getCurrentFinishEmailTemplate(): Observable<string> {
    return this.http.get<string>(`${environment.apiURL}/current_finish_project_email_template`, { headers: this.headers });
  }

  public getCurrentConfirmPresEmailTemplate(): Observable<string> {
    return this.http.get<string>(`${environment.apiURL}/current_confirm_pres_email_template`, { headers: this.headers });
  }

  public getCurrentJuryAccessEmailTemplate(): Observable<string> {
    return this.http.get<string>(`${environment.apiURL}/current_jury_access_email_template`, { headers: this.headers });
  }

  public getFinishedProjects() {
    return this.http.get<Project[]>(`${environment.apiURL}/project/finished`, { headers: this.headers });
  }

  public getUnstartedProjects() {
    return this.http.get<Proposal[]>(`${environment.apiURL}/project/unstarted`, { headers: this.headers }).pipe(share());
  }

  public getOngoingProjects() {
    return this.http.get<Proposal[]>(`${environment.apiURL}/project/ongoing`, { headers: this.headers }).pipe(share());
  }

  public queryProjects(project: Partial<Project> | { semester: number, year: number }) {
    let params = new HttpParams();
    for (const param in project) {
      if (project.hasOwnProperty(param) && project[param] !== undefined) {
        params = params.append(param, project[param]);
      }
    }
    return this.http.get<Proposal[]>(`${environment.apiURL}/project/query`, { headers: this.headers, params });
  }

  public createProposal(proposal: Proposal) {
    const obs = this.http.post<Proposal>(`${environment.apiURL}/proposal`, proposal, { headers: this.headers }).pipe(share());
    obs.subscribe(() => {
      this._matSnackbar.open(`Se ha enviado la propuesta "${proposal.title}"`, null, { duration: 3000 });
    });
    return obs;
  }

  public getProject(id: string) {
    return this.http.get<Proposal>(`${environment.apiURL}/project/${id}`, { headers: this.headers }).pipe(share());
  }

  public getTeacher(id: string) {
    return this.http.get<Teacher>(`${environment.apiURL}/user/${id}`, { headers: this.headers }).pipe(share());
  }
  
  public getStudent(id: string){
    return this.http.get<Student>(`${environment.apiURL}/student/${id}`, { headers: this.headers }).pipe(share());
  }

  public editProject(id: string, proposal: Partial<Proposal>) {
    const obs = this.http.post<Proposal>(`${environment.apiURL}/project/${id}`, proposal, { headers: this.headers }).pipe(share());
    obs.subscribe(() => {
      this._matSnackbar.open(`Se ha editado el proyecto correctamente`, null, {
        duration: 3000,
        panelClass: 'center'
      });
    });
    return obs;
  }

  public addMilestone(projectID: string, milestone: Milestone) {
    const obs = this.http.post<Project>(`${environment.apiURL}/project/${projectID}/milestone`, milestone, { headers: this.headers }).pipe(share());
    obs.subscribe(() => {
      this._matSnackbar.open(`Se ha agregado la meta "${milestone.name}" al proyecto`, null, { duration: 3000 });
    });
    return obs;
  }

  public addMilestoneToProjects(projectsIDs: string[], milestone: Milestone) {
    const obs = this.http.post<Project>(`${environment.apiURL}/milestones`, {
      projectsIDs,
      milestone
    }, { headers: this.headers }).pipe(share());
    obs.subscribe(() => {
      this._matSnackbar.open(`Se ha agregado la meta "${milestone.name}" a${projectsIDs.length == 1 ? 'l proyecto' : ' los proyectos'}`, null, { duration: 3000 });
    });
    return obs;
  }

  public addMeet(projectID: string, meeting: Meeting) {
    const obs = this.http.post<Project>(`${environment.apiURL}/project/${projectID}/meeting`, meeting, { headers: this.headers }).pipe(share());
    obs.subscribe(() => {
      this._matSnackbar.open(`Se ha creado la reunión correctamente`, 'OK', { duration: 2000 });
    });
    return obs;
  }

  public deleteMeet(projectId, meetingId: any) {
    const obs = this.http.delete<Project>(`${environment.apiURL}/project/${projectId}/meeting/${meetingId}`,
      { headers: this.headers }).pipe(share());
    obs.subscribe(() => {
      this._matSnackbar.open(`Se ha eliminado la reunión correctamente`, 'OK', { duration: 2000 });
    });
    return obs;
  }

  public applyForProject(id: string, allStudentEmails: string[] = [], isPublic: boolean = false, priority: number) {
    const obs = this.http.post<ProjectApplication>(`${environment.apiURL}/project/${id}/application`, {
      allStudentEmails,
      isPublic,
      priority,
    }, { headers: this.headers }).pipe(share());
    obs.subscribe(() => {
      this._matSnackbar.open(`Se ha aplicado al proyecto correctamente`, null, { duration: 3000 });
    });
    return obs;
  }

  public editProjectApplication(id: string, allStudentEmails: string[], isPublic: boolean, priority: number) {
    const obs = this.http.post<ProjectApplication>(`${environment.apiURL}/project_application/${id}/edit`, {
      allStudentEmails,
      isPublic,
      priority,
    }, { headers: this.headers }).pipe(share());
    obs.subscribe(() => {
      this._matSnackbar.open(`Se ha editado el grupo correctamente`, null, { duration: 3000 });
    });
    return obs;
  }

  public joinProjectApplication(id: string) {
    const obs = this.http.post<ProjectApplication>(`${environment.apiURL}/project_application/${id}`, null, { headers: this.headers }).pipe(share());
    obs.subscribe(() => {
      this._matSnackbar.open(`Se ha unido al grupo correctamente`, null, { duration: 3000 });
    });
    return obs;
  }

  public unJoinApplication(groupId: string) {
    const obs = this.http.delete<ProjectApplication>(`${environment.apiURL}/project_application/${groupId}`,
      { headers: this.headers }).pipe(share());
    obs.subscribe(() => {
      this._matSnackbar.open(`Se ha desanotado del grupo correctamente`, null, { duration: 3000 });
    });
    return obs;
  }

  public getStudents(): Observable<Student[]> {
    return this.http.get<any>(`${environment.apiURL}/students`, { headers: this.headers }).pipe(share());
  }

  public deleteMilestone(projectId, milestoneId: any) {
    const obs = this.http.delete<Project>(`${environment.apiURL}/project/${projectId}/milestone/${milestoneId}`,
      { headers: this.headers }).pipe(share());
    obs.subscribe(() => {
      this._matSnackbar.open(`Se ha eliminado la meta correctamente`, null, { duration: 3000 });
    });
    return obs;
  }

  public finishMilestone(projectId, milestoneId: any, comment: string) {
    const obs = this.http.post<Project>(`${environment.apiURL}/project/${projectId}/milestone/${milestoneId}/finish`, { comment }, { headers: this.headers }).pipe(share());
    obs.subscribe(() => {
      this._matSnackbar.open(`Se ha dado por finalizada la meta`, null, { duration: 3000 });
    });
    return obs;
  }

  public getCurrentSemester() {
    const obs = this.http.get<any>(`${environment.apiURL}/current_semester`, { headers: this.headers }).pipe(share());
    obs.subscribe(data => {
      this.currentSemester.next(data.currentSemester);
      this.saveCurrentSemesterToLS();
    });
    return obs;
  }

  saveCurrentSemesterToLS() {
    localStorage.setItem('currentSemesterITBAThesis', JSON.stringify(this.currentSemester.getValue()));
  }

  loadCurrentSemesterFromLS() {
    if (localStorage.getItem('currentSemesterITBAThesis')) {
      this.currentSemester.next(JSON.parse(localStorage.getItem('currentSemesterITBAThesis')));
    }
  }

  public getInscriptionInterval() {
    const obs = this.http.get<any>(`${environment.apiURL}/inscription_interval`, { headers: this.headers }).pipe(share());
    obs.subscribe(data => {
      this.inscriptionInterval.next({
        start: new Date(data.inscriptionInterval.start),
        end: new Date(data.inscriptionInterval.end)
      });
    });
    return obs;
  }

  public sendEmail(emailType: EmailType, emailData: any) {
    return this.http.post<ProjectApplication>(`${environment.apiURL}/email/${emailType}`, emailData, { headers: this.headers }).pipe(share());
  }

  public get inscriptionOpen() {
    const now = new Date();
    const start: Date = this.inscriptionInterval.value.start;
    const end: Date = this.inscriptionInterval.value.end;
    return (now > new Date(start) && now < new Date(end));
  }

  public gradeProject(projectId: string, gradeData: any, user: User): Observable<Project> {
    if (user.type !== USER_TYPE.admin && user.type !== USER_TYPE.teacher) {
      this._matSnackbar.open(`Sólo un administrador o tutor puede realizar esta acción`, null, { duration: 5000 });
      return;
    }
    const $gradeProject = this.http.put<Project>(`${environment.apiURL}/project/${projectId}/grade`, gradeData, { headers: this.headers }).pipe(share());
    $gradeProject.subscribe(
      () => {
        this._matSnackbar.open(`Proyecto calificado exitosamente`, null, { duration: 5000 });
      }, () => {
        this._matSnackbar.open(`Error del servidor, intentelo nuevamente`, null, { duration: 5000 });
      }
    );
    return $gradeProject;
  }

  public gradeStudent(userId: string, gradeData: any): Observable<Student> {
    const $gradeProject = this.http.put<Student>(`${environment.apiURL}/user/${userId}/grade`, gradeData, { headers: this.headers }).pipe(share());
    $gradeProject.subscribe(
      () => {
        this._matSnackbar.open(`Alumno calificado exitosamente`, null, { duration: 5000 });
      }, () => {
        this._matSnackbar.open(`Error del servidor, intentelo nuevamente`, null, { duration: 5000 });
      }
    );
    return $gradeProject;
  }

  public addPresentation(projectId: string, presentationData: any, user: User): Observable<Project> {
    if (user.type !== USER_TYPE.admin && user.type !== USER_TYPE.teacher) {
      this._matSnackbar.open(`Sólo un administrador o un profesor puede realizar esta acción`, null, { duration: 5000 });
      return;
    }
    const $addPresentation = this.http.put<Project>(`${environment.apiURL}/project/${projectId}/add-presentation`,
      presentationData, { headers: this.headers }).pipe(share());
    $addPresentation.subscribe(
      () => {
        if (this.auth.isAdmin) {
          this._matSnackbar.open(`Presentación cargada exitosamente`, null, { duration: 5000 });
        }
      }, () => {
        this._matSnackbar.open(`Error del servidor, intentelo nuevamente`, null, { duration: 5000 });
      }
    );
    return $addPresentation;
  }

  public editJury(projectId: string, jury: string[]): Observable<string[]> {
    const obs = this.http.put<string[]>(`${environment.apiURL}/project/${projectId}/edit_jury`, { jury }, { headers: this.headers }).pipe(share());
    obs.subscribe(() => {
      this._matSnackbar.open(`El jurado se ha editado correctamente`, null, { duration: 3000 });
    },
      () => {
        this._matSnackbar.open(`Error del servidor, intente nuevamente`, null, { duration: 3000 });
      });
    return obs;
  }

  public addJuryComment(projectId: string, juryComments: any[]): Observable<any[]> {
    const obs = this.http.put<string[]>(`${environment.apiURL}/project/${projectId}/jury_comment`, { juryComments }, { headers: this.headers }).pipe(share());
    obs.subscribe(() => {
      this._matSnackbar.open(`Dictamen cargado exitosamente`, null, { duration: 3000 });
    },
      () => {
        this._matSnackbar.open(`Error del servidor, intente nuevamente`, null, { duration: 3000 });
      });
    return obs;
  }



  public setDocumentationStatus(projectId: string, status: number, user: User): Observable<Project> {
    if (user.type !== USER_TYPE.admin) {
      this._matSnackbar.open(`Sólo un administrador puede realizar esta acción`, null, { duration: 5000 });
      return;
    }
    const obs = this.http.put<Project>(`${environment.apiURL}/project/${projectId}/set_documentation_status`, { status }, { headers: this.headers }).pipe(share());
    obs.subscribe(() => {
      this._matSnackbar.open(`El estado de la documentación fue actualizado`, null, { duration: 3000 });
    });
    return obs;
  }


  proposeFinishProject(project: Project): Observable<any> {
    const finishProject$ = this.http.put(`${environment.apiURL}/project/${project._id}/finish-propose`, {}, { headers: this.headers }).pipe(share());
    finishProject$.subscribe(
      () => {
        this._matSnackbar.open(`Petición enviada correctamente, se notificará a los administradores`, null, { duration: 5000 });
      }, () => {
        this._matSnackbar.open(`Error del servidor, intentelo nuevamente`, null, { duration: 5000 });
      }
    );
    return finishProject$;
  }

  /*    public finishProject(project: Project, data: any): Observable<any> {
    const finishProject$ = this.http.put(`${environment.apiURL}/project/${project._id}/finish`, data, {headers: this.headers}).pipe(share());
    finishProject$.subscribe(
      () => {
        this._matSnackbar.open(`El proyecto se ha dado por finalizado`, null, {duration: 5000});
      }, () => {
        this._matSnackbar.open(`Error del servidor, intentelo nuevamente`, null, {duration: 5000});
      }
      );
      return finishProject$;
    }*/

  rejectFinishProject(projectId: string, user: User): Observable<any> {
    if (user.type !== USER_TYPE.admin && user.type !== USER_TYPE.teacher) {
      this._matSnackbar.open(`Sólo un administrador o tutor puede realizar esta acción`, null, { duration: 5000 });
      return;
    }
    const rejectFinish$ = this.http.put(`${environment.apiURL}/project/${projectId}/reject-finish`, {}, { headers: this.headers }).pipe(share());
    rejectFinish$.subscribe(
      () => {
        this._matSnackbar.open(`Se ha cancelado la finalización del proyecto`, null, { duration: 5000 });
      }, () => {
        this._matSnackbar.open(`Error del servidor, intentelo nuevamente`, null, { duration: 5000 });
      }
    );
    return rejectFinish$;
  }

  confirmPresentation(projectId: string, presentationData: any, user: User): Observable<any> {
    if (presentationData.presentationDate == null || presentationData.presentationTime == null) {
      this._matSnackbar.open(`Es necesario definir una fecha y hora de presentación`, null, { duration: 5000 });
      return;
    }
    if (user.type !== USER_TYPE.admin) {
      this._matSnackbar.open(`Sólo un administrador puede confirmar la presentación`, null, { duration: 5000 });
      return;
    }
    const obs = this.http.put(`${environment.apiURL}/project/${projectId}/confirm-presentation`, { presentationData }, { headers: this.headers }).pipe(share());
    obs.subscribe(
      () => {
        this._matSnackbar.open(`Presentación confirmada exitosamente`, null, { duration: 5000 });
      }, () => {
        this._matSnackbar.open(`Error del servidor, intentelo nuevamente`, null, { duration: 5000 });
      }
    );
    return obs;
  }

  setPresentationVisibility(projectId: string, isPublic: boolean) {
    const obs = this.http.put<Project>(`${environment.apiURL}/project/${projectId}/visibility`, { isPublic }, { headers: this.headers }).pipe(share());
    obs.subscribe(() => {
      this._matSnackbar.open(`Visibilidad de la defensa editada existosamente`, null, { duration: 3000 });
    });
    return obs;
  }

  editProjectComment(project: Project, comment: string) {
    return this.http.put(`${environment.apiURL}/project/${project._id}/comment`, { comment }, { headers: this.headers });
  }

  getStudentLibraryAuthDoc(projectId: string): Observable<ArrayBuffer> {
    return this.http.get(`${environment.apiURL}/project/${projectId}/student-libauth`, {
      responseType: 'arraybuffer',  // Set the response type to arraybuffer
      observe: 'response',  // Include the full response to access headers
    }).pipe(map((response: HttpResponse<ArrayBuffer>) => response.body));
  }

  getRecordDoc(projectId: string): Observable<ArrayBuffer> {
    return this.http.get(`${environment.apiURL}/project/${projectId}/record`, {
      responseType: 'arraybuffer',  // Set the response type to arraybuffer
      observe: 'response',  // Include the full response to access headers
    }).pipe(map((response: HttpResponse<ArrayBuffer>) => response.body));
  }

  closeProject(projectId: string): Observable<Project> {
    const obs = this.http.put<Project>(`${environment.apiURL}/project/${projectId}/close`,{}, { headers: this.headers }).pipe(share());
    obs.subscribe(() => {
      this._matSnackbar.open(`Proyecto cerrado exitosamente`, null, { duration: 3000 });
    });
    return obs;
  }


}
