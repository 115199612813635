import {Component, Input, OnInit} from '@angular/core';
import {Project} from 'src/app/models/Project';
import {AuthService} from 'src/app/services/auth.service';
import {ApiService} from 'src/app/services/api.service';
import {FormGroup} from '@angular/forms';
import {Milestone} from 'src/app/models/Milestone';
import {MatDialog} from '@angular/material/dialog';
import {CreateMilestoneComponent} from 'src/app/shared/components/dialogs/create-milestone/create-milestone.component';
import {ConfirmDialogComponent} from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import {MilestoneAcceptComponent} from 'src/app/shared/components/dialogs/milestone-accept/milestone-accept.component';
import {EmailDialogService} from 'src/app/shared/services/email-dialog.service';

@Component({
  selector: 'app-milestones-viewer',
  templateUrl: './milestones-viewer.component.html',
  styleUrls: ['./milestones-viewer.component.scss']
})
export class MilestonesViewerComponent implements OnInit {

  @Input()
  public project: Project;
  public addEventFG: FormGroup;
  public showAllMilestones = false;

  constructor(
    public authService: AuthService,
    public apiService: ApiService,
    private dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  addEvent() {
    const d = this.dialog.open(CreateMilestoneComponent, {
      data: this.project
    });
  }

  deleteEvent(milestoneId) {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Borrar objetivo',
        body: '¿Confirmás borrar el objetivo?'
      }
    }).afterClosed().subscribe(data => {
      if (data) {
        this.apiService.deleteMilestone(this.project._id, milestoneId).subscribe(
          data => {
            this.project.milestones = data.milestones;
          }
        );
      }
    });
  }

  finishEvent(milestoneId) {
    this.dialog.open(MilestoneAcceptComponent, {
      data: {}
    }).afterClosed().subscribe(data => {
      if (data) {
        this.apiService.finishMilestone(this.project._id, milestoneId, data).subscribe(
          data => {
            this.project.milestones = data.milestones;
          }
        );
      }
    });
  }

  isDelayed(ev: Milestone) {
    return new Date(ev.dueDate).getTime() < new Date().getTime() && !ev.doneDate;
  }

}
