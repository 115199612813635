<div class="container">
  <img src="assets/icons/close.svg" alt="" (click)="this.dialogRef.close(false)" cdkFocusInitial>
    <div class="row">
        <h1 mat-dialog-title>Agregar jurado</h1>
    </div>
    <form [formGroup]="juryForm" (submit)="addJuror()">
        <div class="row">
            <div class="col-12 col-sm-5 col-md-5 col-lg-6 col-xl-4" style="align-self: center; text-align: center;">
                <mat-form-field style="margin-right: 10px;">
                    <mat-label>Email del jurado *</mat-label>
                    <input matInput type="text" formControlName="jury" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let teacher of filteredTeachers | async" [value]="teacher.email"
                            (onSelectionChange)="onTeacherSelected($event)">
                            {{teacher | usernameOrEmail}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
          <div class="col-12 col-sm-5 col-md-5 col-lg-6 col-xl-4" style="align-self: center; text-align: center;">
            <mat-form-field style="margin-right: 10px;margin-left: 20px;">
              <mat-label>Nombre</mat-label>
              <input matInput type="text" formControlName="name" autocomplete="off">
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-2 col-md-2 col-lg-12 col-xl-4" style="text-align: center; align-self: center; ">
            <button mat-stroked-button class="btn-blue" type="submit">Agregar</button>
          </div>
        </div>
      <div *ngIf="errorMessage!=null" style="padding-bottom: 0">
        <span style="color: #f44336;font-size: 12px">{{errorMessage}}</span>
      </div>
    </form>
    
</div>
