import {User} from './User';

export enum MILESTONE_TYPE {
  ADMINISTRATIVE = 0,
  MAJOR = 1,
  MINOR = 2,
  ENTREGA = 3
}

export enum MILESTONE_STATE {
  UNFINISHED = 0,
  FINISHED = 1,
  ACCEPTED = 2,
  REJECTED = 3
}

export class Milestone {
  _id: string;
  name: string;
  description: string;
  creator: User;
  type: MILESTONE_TYPE;
  dueDate: Date;
  doneDate: Date;
  state: MILESTONE_STATE;
  attachmentText: string;
  comment: string;
}
