<div class="container" style="padding-bottom: 10px">
  <img [src]="teacher?.picture || 'assets/icons/blank-profile-picture.png'" alt="" style="margin-top: 12px;">
  <h3 style="text-transform: capitalize; min-inline-size: max-content;">{{teacher?.title}} {{teacher?.firstName.toLowerCase()}} {{teacher?.lastName.toLowerCase()}}
    <span *ngIf="canEdit" class="edit-link">
          <mat-icon style="cursor: pointer;" (click)="openTeacherEditor()">edit</mat-icon>
    </span>
  </h3>
  <p>{{teacher?.email}}</p>
  <p style="margin-bottom: 0px;" *ngIf="teacher?.area">{{teacher?.area}}</p>
  <hr/>
  <p *ngIf="teacher?.bio" style="text-align: justify">{{teacher?.bio}}</p>
  <mat-chip-list #chipList>
    <mat-basic-chip *ngFor="let tag of sortedTags; let i = index">
      {{tag}}
    </mat-basic-chip>
  </mat-chip-list>
  <button mat-stroked-button class="btn-blue" style="margin-left: 12px; margin-right: 12px; align-self: center;padding-left: 27px;padding-right: 27px;" [routerLink]="['/proposal']">Proponer proyecto</button>
</div>
