import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../../../services/api.service";
import {Project} from "../../../../models/Project";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AuthService} from 'src/app/services/auth.service';

export interface projectGrade {
    grade: number;
    acta: number;
}

@Component({
    selector: 'app-grade-editor',
    templateUrl: './grade-editor.component.html',
    styleUrls: ['./grade-editor.component.scss']
})
export class GradeEditorComponent implements OnInit {

    gradeForm: FormGroup;
    public grade: number;
    public acta: number;
    public comment: string;

    constructor(public dialogRef: MatDialogRef<GradeEditorComponent>,
                private formBuilder: FormBuilder,
                private apiService: ApiService,
                private _matSnackbar: MatSnackBar,
                public auth: AuthService,
                @Inject(MAT_DIALOG_DATA) public data: {
                    project: Project
                }) {
    }

    ngOnInit(): void {
        this.gradeForm = this.formBuilder.group({
            student: [null, [Validators.required]],
            grade: [null, [Validators.required, Validators.min(0), Validators.max(10)]],
            acta: [null, [Validators.min(0)]],
            sendMail: [false, []]
        });
    }

    onStudentSelected(studentId: string) {
        const selectedStudent = this.data.project.students.find(s => s._id === studentId);
        const studentGrade: projectGrade = selectedStudent.projectGrade;
        if (studentGrade) {
            this.gradeForm.get('grade').setValue(studentGrade.grade);
            this.gradeForm.get('acta').setValue(studentGrade.acta);
        } else {
            this.gradeForm.get('grade').setValue(null);
            this.gradeForm.get('acta').setValue(null);
        }
    }

    gradeProject() {
        if (this.gradeForm.invalid) {
            return;
        }
        this.gradeForm.value.projectId = this.data.project._id;
        this.apiService.gradeStudent(this.gradeForm.value.student, this.gradeForm.value).subscribe(
            data => {
                const student = this.data.project.students.find(s => s._id === this.gradeForm.value.student);
                student.projectGrade = data.projectGrade;
                this.closeDialog();
            }
        );
    }

    closeDialog() {
        this.dialogRef.close('closed');
    }

}
