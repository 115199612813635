import {Pipe, PipeTransform} from '@angular/core';
import {Proposal} from 'src/app/models/Proposal';

@Pipe({
  name: 'proposalStatus'
})
export class ProposalStatusPipe implements PipeTransform {

  transform(proposal: Proposal, ...args: unknown[]): unknown {
    return !proposal.accepted ? 'Esperando confirmación' : `Aceptado, ${proposal.applications?.length || 0} aplicaciones`;
  }

}
