<div class="container" style="margin-right: 0px;padding-top: 0">
<div class="back" (click)="back()">
    <mat-icon>arrow_back</mat-icon>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
      <div class="profile">
        <app-teacher-info [teacher]="this.auth.user"></app-teacher-info>
      </div>
    </div>
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
      <div class="projects">
        <app-teacher-project-list [teacher]="this.auth.user"></app-teacher-project-list>
      </div>
    </div>
  </div>
</div>
