import {User} from './User';
import {Proposal} from './Proposal';
import {Milestone} from './Milestone';
import {Meeting} from './Meeting';

export enum EmailType {
  BASIC,
  PROJECT_LAUNCH,
  PROJECT_CHANGE,
  MEETING_CREATE,
  MEETING_DELETE,
  MILESTONE_CREATE,
  MILESTONE_ACCOMPLISH,
	FINISH_PROCESS_STARTED,
	FINISH_PROJECT_APPROVED,
  FINISH_PROJECT_REJECTED,
  APPROVED_PROJECT,
  CONFIRM_PRESENTATION
}

export class EmailData {
  recipients: User[];
  juryRecipients?: string[];
  emailType: EmailType;
  emailRecipients?: string[];
  message?: string;
  project?: Proposal;
  milestone?: Milestone;
  meeting?: Meeting;
}
