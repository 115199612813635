import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../../../services/api.service";
import {Project} from "../../../../models/Project";

@Component({
  selector: 'app-presentations',
  templateUrl: './presentations.component.html',
  styleUrls: ['./presentations.component.scss']
})
export class PresentationsComponent implements OnInit {

  today: string;

  projects: Project[];
  upcomingPresentations: Project[];
  pastPresentations: Project[];

  constructor(private api: ApiService) {
  }

  ngOnInit(): void {
    this.today = new Date().toISOString();
    this.api.getFinishedProjects().subscribe(
      data => {
        const yearBackFromNow = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
        const now = new Date();
        this.projects = data.filter(p => p.presentationDate && p.confirmedPresentation && new Date(p.presentationDate) >= yearBackFromNow);
        this.upcomingPresentations = data.filter(p => p.presentationDate && p.confirmedPresentation && new Date(p.presentationDate) >= now && p.publicPresentation);
        this.pastPresentations = data.filter(p => p.presentationDate && p.confirmedPresentation && new Date(p.presentationDate) >= yearBackFromNow && new Date(p.presentationDate) < now && p.publicPresentation);
        // @ts-ignore
        this.projects.sort((a, b) => new Date(b.presentationDate) - new Date(a.presentationDate));
        // @ts-ignore
        this.upcomingPresentations.sort((a, b) => new Date(a.presentationDate) - new Date(b.presentationDate));
        // @ts-ignore
        this.pastPresentations.sort((a, b) => new Date(b.presentationDate) - new Date(a.presentationDate));
      }
    );
  }

  capitalizeName(name: string): string {
    let capitalized = '';
    for (let i = 0; i < name.length; i++) {
      if (i === 0 || (name[i - 1] === ' ' && name[i] !== ' ')) {
        capitalized += name[i].toUpperCase();
      } else {
        capitalized += name[i].toLowerCase();
      }
    }
    return capitalized;
  }

}
