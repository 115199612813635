import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from 'src/app/services/api.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CreateMilestoneComponent} from '../create-milestone/create-milestone.component';
import {Project} from 'src/app/models/Project';
import {AuthService} from 'src/app/services/auth.service';
import {Meeting} from 'src/app/models/Meeting';

@Component({
  selector: 'app-create-meet',
  templateUrl: './create-meet.component.html',
  styleUrls: ['./create-meet.component.scss']
})
export class CreateMeetComponent implements OnInit {

  public addMeetFG: FormGroup;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    public auth: AuthService,
    public dialogRef: MatDialogRef<CreateMilestoneComponent>,
    @Inject(MAT_DIALOG_DATA) public project: Project) {
  }

  ngOnInit(): void {

    this.addMeetFG = this.fb.group({
      type: [1, [Validators.required]],
      // name: ['', [Validators.required]],
      location: ['', [Validators.required]],
      time: ['', [Validators.required]]
    });
  }

  addEvent() {
    const aux = new Meeting();
    aux.weekly = this.addMeetFG.value.type;
    // aux.weekday = this.addMeetFG.value.name;
    // aux.hour = this.addMeetFG.value.dueDate;
    aux.location = this.addMeetFG.value.location;
    this.apiService.addMeet(this.project._id, aux).subscribe(
      data => {
        this.project = data;
        this.dialogRef.close(true);
      }
    );
  }

}
