import {Pipe, PipeTransform} from '@angular/core';
import {Meeting} from 'src/app/models/Meeting';

@Pipe({
  name: 'meetingToString'
})
export class MeetingToStringPipe implements PipeTransform {

  private static WEEK = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
  private static OCURRENCE = ['', 'Semanal', 'Bisemanal', '', 'Mensual'];

  transform(value: Meeting, ...args: unknown[]): unknown {
    return `${
      MeetingToStringPipe.OCURRENCE[value.weekly]
    } - ${
      MeetingToStringPipe.WEEK[value.weekday]
    } ${
      ('0' + value.hour).slice(-2)}:${('0' + value.minute).slice(-2)
    } - ${
      value.location
    }`;
  }

}
