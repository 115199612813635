<div class="container">
  <h1 mat-dialog-title *ngIf="data.title" [innerHTML]="data.title"></h1>
  <p [innerHTML]="data.body"></p>
  <div mat-dialog-actions style="margin-top: 0">
    <div *ngIf="emailBody" class="col-12" [formGroup]="emailBody">
      <mat-form-field class="templateField">
        <textarea style="text-align: justify;" matInput matNativeControl cdkTextareaAutosize formControlName="email_default_body"></textarea>
      </mat-form-field>
      <mat-slide-toggle color="primary" matNativeControl formControlName="checkbox_control">Enviar email</mat-slide-toggle>
      <mat-slide-toggle color="primary" matNativeControl formControlName="update_template_control" style="margin-left: 10px;">Actualizar template</mat-slide-toggle>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button color="primary" [mat-dialog-close]="true">Confirmar</button>
  </div>
  <img src="assets/icons/close.svg" alt="" (click)="dialogRef.close(false)" cdkFocusInitial>
</div>
