<div>
  <mat-tab-group mat-align-tabs="center" animationDuration="1500ms" (selectedIndexChange)="changeTab($event)" [(selectedIndex)]="tabIndex">
    <mat-tab *ngIf="auth.isTeacher">
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">person</mat-icon>
        <span class="tab-title">Mi perfil</span>
      </ng-template>
      <div class="container-teacher">
        <app-teacher-info [teacher]="teacher"></app-teacher-info>
        <app-teacher-project-list [teacher]="teacher" style="margin-top: 20px;"></app-teacher-project-list>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">school</mat-icon>
        <span class="tab-title">Propuestas</span>
      </ng-template>
      <app-public-proposals></app-public-proposals>
    </mat-tab>
    <mat-tab *ngIf="auth.user" label="Tutores">
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">people</mat-icon>
        <span class="tab-title">Tutores</span>
      </ng-template>
      <app-tutors></app-tutors>
    </mat-tab>
    <mat-tab label="Defensas">
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">gavel</mat-icon>
        <span class="tab-title">Defensas</span>
      </ng-template>
      <app-presentations></app-presentations>
    </mat-tab>
    <mat-tab *ngIf="auth.user && auth.user.type == 0" label="Aplicaciones">
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">history_edu</mat-icon>
        <span class="tab-title">Mis propuestas</span>
      </ng-template>
      <app-applications></app-applications>
    </mat-tab>
    
  </mat-tab-group>
</div>


