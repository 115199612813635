import {Component, Input, OnInit} from '@angular/core';
import {Student} from "../../../models/Student";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../services/auth.service";
import {ApiService} from "../../../services/api.service";
import { Location } from '@angular/common';

@Component({
  selector: 'app-student-profile',
  templateUrl: './student-profile.component.html',
  styleUrls: ['./student-profile.component.scss']
})
export class StudentProfileComponent implements OnInit {

  student: Student;
  constructor(
    public router: Router,
    public auth: AuthService,
    public apiService: ApiService,
    private _location: Location,
    public route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      params => {
        this.apiService.getStudent(params.get('id')).subscribe(
          data => {
            this.student = data;
          }
        );
      }
    );
  }
  
  back() {
    this._location.back();
  }
}
