import {Component, OnInit} from '@angular/core';
import {AuthService} from 'src/app/services/auth.service';
import {DialogService} from 'src/app/services/dialog.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(
    public dialogService: DialogService,
    public auth: AuthService
  ) {
  }

  ngOnInit(): void {
  }

}
