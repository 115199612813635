import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {EmailSenderComponent} from 'src/app/shared/components/dialogs/email-sender/email-sender.component';
import {ConfirmationSenderComponent} from 'src/app/components/project/project-card/confirmation-sender/confirmation-sender.component';
import {Project} from 'src/app/models/Project';
import {EmailType} from 'src/app/models/EmailType';
import {Meeting} from 'src/app/models/Meeting';
import {Milestone} from 'src/app/models/Milestone';
import {SharedModule} from '../shared.module';
import {User, USER_TYPE} from 'src/app/models/User';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
    providedIn: SharedModule
})
export class EmailDialogService {

    constructor(private dialog: MatDialog,
                private _matSnackbar: MatSnackBar) {
    }

    openBasicEmail(recipients: string[]): MatDialogRef<EmailSenderComponent, any> {
        return this.dialog.open(EmailSenderComponent, {data: {recipients, emailType: EmailType.BASIC}});
    }

    openLaunchedProject(project: Project): MatDialogRef<EmailSenderComponent, any> {
        const recipients = [];
        project.students.forEach(s => recipients.push(s));
        project.tutors.forEach(t => recipients.push(t));
        return this.dialog.open(EmailSenderComponent, {
            data: {
                recipients,
                emailType: EmailType.PROJECT_LAUNCH,
                project
            }
        });
    }

    openMeetingCreate(project: Project, meeting: Meeting): MatDialogRef<EmailSenderComponent, any> {
        const recipients = [];
        project.students.forEach(s => recipients.push(s));
        project.tutors.forEach(t => recipients.push(t));
        return this.dialog.open(EmailSenderComponent, {
            data: {
                recipients,
                emailType: EmailType.MEETING_CREATE,
                project,
                meeting
            }
        });
    }

    openMeetingDelete(project: Project, meeting: Meeting): MatDialogRef<EmailSenderComponent, any> {
        const recipients = [];
        project.students.forEach(s => recipients.push(s));
        project.tutors.forEach(t => recipients.push(t));
        return this.dialog.open(EmailSenderComponent, {
            data: {
                recipients,
                emailType: EmailType.MEETING_DELETE,
                project,
                meeting
            }
        });
    }

    openMilestoneCreate(project: Project, milestone: Milestone): MatDialogRef<EmailSenderComponent, any> {
        const recipients = [];
        project.students.forEach(s => recipients.push(s));
        project.tutors.forEach(t => recipients.push(t));
        return this.dialog.open(EmailSenderComponent, {
            data: {
                recipients,
                emailType: EmailType.MILESTONE_CREATE,
                project,
                milestone
            }
        });
    }

    openMilestoneAccomplish(project: Project, milestone: Milestone): MatDialogRef<EmailSenderComponent, any> {
        const recipients = [];
        project.students.forEach(s => recipients.push(s));
        project.tutors.forEach(t => recipients.push(t));
        return this.dialog.open(EmailSenderComponent, {
            data: {
                recipients,
                emailType: EmailType.MILESTONE_ACCOMPLISH,
                project,
                milestone
            }
        });
    }

    openConfirmPresentationEmail(recipients: any[], juryRecipients: any[], project: Project, user: User): MatDialogRef<ConfirmationSenderComponent, any> {
        if (user.type != USER_TYPE.admin) {
            this._matSnackbar.open(`Sólo un administrador puede realizar esta acción`, null, {duration: 5000});
            return;
        }
        return this.dialog.open(ConfirmationSenderComponent, {
            data: {
                recipients,
                juryRecipients,
                emailType: EmailType.CONFIRM_PRESENTATION,
                project
            }
        });
    }

}
