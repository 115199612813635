import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Teacher } from 'src/app/models/Teacher';
import { MatDialog } from '@angular/material/dialog';
import { TeacherEditorComponent } from 'src/app/shared/components/dialogs/teacher-editor/teacher-editor.component';
import { Editable } from 'src/app/utils/editable';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-teacher-info',
    templateUrl: './teacher-info.component.html',
    styleUrls: ['./teacher-info.component.scss']
})
export class TeacherInfoComponent implements OnInit, Editable {

    @Input()
    teacher: Teacher;

    sortedTags: string[] = [];

    constructor(
        private dialog: MatDialog,
        private auth: AuthService
    ) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.teacher && !changes.teacher.firstChange) {
            this.updateSortedTags();
        }
    }


    private updateSortedTags(): void {
        if (this.teacher && this.teacher.tags && this.teacher.tags.length) {
            this.sortedTags = this.teacher.tags.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
        } else {
            this.sortedTags = [];
        }
  
    }

    openTeacherEditor() {
        this.dialog.open(TeacherEditorComponent, { data: this.teacher });
    }

    get canEdit(): boolean {
        return this.auth.isAdmin || this.auth.user?._id === this.teacher?._id;
    }


}
