<div class="container">
  <h1 mat-dialog-title *ngIf="data.title" [innerHtml]="data.title"></h1>

  <p>{{data.paso1}}<a href="{{data.link}}">link</a>.</p>
  <p>{{data.paso2}}</p>
  <p>{{data.paso3}}</p>
  <p>{{data.paso4}}</p>
  <mat-form-field>
    <mat-label>{{data.formControlName}}</mat-label>
    <input matInput [formControl]="resource">
    <button type="button" mat-icon-button matSuffix (click)="copyToClipboard()">
        <mat-icon>file_copy</mat-icon>
    </button>
  </mat-form-field>

  <div mat-dialog-actions>
    <button mat-stroked-button class="btn-blue" (click)="editResource()">Aceptar</button>
  </div>

  <img src="assets/icons/close.svg" alt="" (click)="this.dialogRef.close(false)" cdkFocusInitial>
</div>