import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {User, USER_TYPE} from 'src/app/models/User';
import {EmailData} from 'src/app/models/EmailType';
import {ApiService} from 'src/app/services/api.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-email-sender',
  templateUrl: './email-sender.component.html',
  styleUrls: ['./email-sender.component.scss']
})
export class EmailSenderComponent implements OnInit {

  teacherRecipients: User[] = [];
  studentRecipients: User[] = [];

  htmlText = '';
  hasFocus = false;

  quillConfig = {
    toolbar: {
      container: [
        [{'header': [1, 2, 3, 4, 5, 6, false]}],
        ['bold', 'italic', 'underline', 'strike'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
      ],
    },
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: (range, context) => {
            return true;
          }
        }
      }
    }
  };

  constructor(
    public api: ApiService,
    public snackbar: MatSnackBar,
    public dialogRef: MatDialogRef<EmailSenderComponent>,
    @Inject(MAT_DIALOG_DATA) public emailData: EmailData) {
    this.teacherRecipients = emailData.recipients.filter(u => u.type == USER_TYPE.teacher);
    this.studentRecipients = emailData.recipients.filter(u => u.type == USER_TYPE.student);
  }

  ngOnInit(): void {
  }

  sendMail() {
    this.emailData.message = this.htmlText;
    this.emailData.emailRecipients = this.studentRecipients.map(s => s.email).concat(this.teacherRecipients.map(t => t.email));
    this.api.sendEmail(this.emailData.emailType, this.emailData).subscribe(ret => {
      if (ret) {
        this.snackbar.open('Email enviado correctamente', null, {duration: 2000});
        this.dialogRef.close(true);
      }
    });

  }

  removeRecipient(index: number, type: USER_TYPE): void {
    if (type == USER_TYPE.student) {
      if (this.studentRecipients.length >= 0) {
        this.studentRecipients.splice(index, 1);
      }
    } else if (type == USER_TYPE.teacher) {
      if (this.teacherRecipients.length >= 0) {
        this.teacherRecipients.splice(index, 1);
      }
    }
  }
}
