import {Student} from './Student';
import {Project} from './Project';
import {Proposal} from './Proposal';

export class ProjectApplication {
  _id: string;
  project: Proposal;
  students: Student[];
  priority: number;
  isPublic: boolean;
}
