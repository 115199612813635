import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User, USER_TYPE } from 'src/app/models/User';
import { EmailData } from 'src/app/models/EmailType';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Presentation } from 'src/app/models/Presentation';
import { Project } from 'src/app/models/Project';
import { AuthService } from 'src/app/services/auth.service';
import { formatDate } from '@angular/common';



@Component({
    selector: 'app-confirmation-sender',
    templateUrl: './confirmation-sender.component.html',
    styleUrls: ['./confirmation-sender.component.scss']
})
export class ConfirmationSenderComponent implements OnInit {

    confirmationForm: FormGroup | null = null;
    teacherRecipients: User[] = [];
    studentRecipients: User[] = [];
    juryRecipients: any[] = [];
    checked: boolean;
    project: Project;
    staticMsg: string;

    constructor(public api: ApiService,
        private formBuilder: FormBuilder,
        public snackbar: MatSnackBar,
        public dialogRef: MatDialogRef<ConfirmationSenderComponent>,
        public auth: AuthService,
        @Inject(MAT_DIALOG_DATA) public emailData: EmailData) {

        this.teacherRecipients = emailData.recipients.filter(u => u.type === USER_TYPE.teacher);
        this.studentRecipients = emailData.recipients.filter(u => u.type === USER_TYPE.student);
        this.juryRecipients = emailData.juryRecipients;
        this.project = emailData.project;
    }

    ngOnInit(): void {
        this.checked = false;
        this.api.getCurrentConfirmPresEmailTemplate().subscribe(
            (data: string) => {
                this.getTemplate(data);
            },
            (error) => {
                console.error('Error fetching template:', error);
                // Handle error or set default template
            }
        );
        const date = new Date(this.project.presentationDate);

        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const dateText = "La fecha de la defensa fue confirmada para el dia " + day + "/" + month + "/" + year + " a las " + this.project.presentationTime + ".\n";
        const modeText = this.project.onlinePresentation ? "Se llevará a cabo en la sala virtual de Campus creada para tal fin y ya disponible. Accediendo a través de Campus tendrán privilegios de moderador.\n" : ("Se llevará a cabo en el aula " + this.project.classroom + " de la sede " + this.project.campus + ".\n");
        const linkText = this.project.meetingLink ? ("Pueden invitar a quienes gusten compartiendo el siguiente link: " + this.project.meetingLink + " Dicho link otorga permisos de invitado (no sirve para la presentación de los alumnos).\n") : "\n";
        this.staticMsg = dateText + modeText + linkText;
    }

    getTemplate(emailTemplate: string) {
        this.confirmationForm = this.formBuilder.group({
            emailBody: [emailTemplate, [Validators.nullValidator]],
            update_template_control: [false, [Validators.required]]
        });
    }

    confirmPresentation() {
        if (this.confirmationForm.invalid) {
            return;
        }

        const recipients = this.studentRecipients.map(s => s.email)
            .concat(this.teacherRecipients.map(t => t.email))
            .concat(this.juryRecipients.map(j => j.jury));

        const reminderRecipients = [];
        this.project.jury.forEach(juryMember => {
            reminderRecipients.push(juryMember.jury);
        });
        this.project.tutors.forEach(tutor => {
            reminderRecipients.push(tutor.email);
        });

        const presentationData: Presentation = {
            jury: this.project.jury,
            meetingLink: this.project.meetingLink,
            presentationDate: this.project.presentationDate,
            presentationTime: this.project.presentationTime,
            emailBody: this.staticMsg + "\n" + this.confirmationForm.get('emailBody').value,
            sendMail: this.checked,
            emailRecipients: recipients,
            reminderRecipients: reminderRecipients
        };
        this.api.confirmPresentation(this.project._id, presentationData, this.auth.user).subscribe(() => {
            this.project.confirmedPresentation = true;
            this.closeDialog();
        });
    }

    closeDialog() {
        this.dialogRef.close('closed');
    }

    removeRecipient(index: number, type: USER_TYPE): void {
        if (type === USER_TYPE.student) {
            if (this.studentRecipients.length >= 0) {
                this.studentRecipients.splice(index, 1);
            }
        } else if (type === USER_TYPE.teacher) {
            if (this.teacherRecipients.length >= 0) {
                this.teacherRecipients.splice(index, 1);
            }
        }
    }

    removeJuror(index: number) {
        if (index >= 0) {
            this.juryRecipients.splice(index, 1);
        }
    }

    getUpdateTemplateValue() {
        return this.confirmationForm.get('update_template_control').value;
    }

    getEmailBodyValue() {
        return this.confirmationForm.get('emailBody').value;
    }


}
