import {Student} from './Student';
import {Teacher} from './Teacher';
import {Milestone} from './Milestone';
import {Meeting} from './Meeting';
import {Time} from '@angular/common';

export enum STATE {
    UNSTARTED = 0, UNDERWAY = 1, FINISHED = 2, CANCELLED = 3, PENDING_FINISH_CONFIRMATION = 4
}

export class Project {
    _id: string;
    title: string;
    abstract: string;
    accepted: boolean;
    students?: Student[];
    tutors?: Teacher[];
    subjects?: string[];
    tags: string[];
    realClient?: string;
    extraComments?: string;
    deliverables?: string;
    objectives?: string;
    requisites?: string;
    background?: string;
    impact?: string;
    externalParticipants?: string;
    otherTypeProject?: string;
    investigationProject?: boolean;
    developmentProject?: boolean;
    fieldOfStudy?: string;
    documentationStatus: number;
    jury?: any[];
    juryComments?: any[];
    meetingLink?: string;
    presentationDate?: Date;
    presentationTime?: string;
    confirmedPresentation: boolean;
    publicPresentation: boolean;
    gradeDate?: Date;
    endDate?: Date;
    comment?: string;
    expectedDurationMonths?: number;
    startDate: number[]; // Año + Q

    state: STATE;
    draftPaperLink: string;
    repository: string;

    studentsAuth: string;
    tutorsJuryAuth: string;

    meetings: Meeting[];

    milestones: Milestone[];
    nextMilestones: Milestone[];
    onlinePresentation?: boolean;
    campus?:string;
    classroom?:string;
    
    juryToken?:string;
    closed?:boolean;
    
    constructor(title: string) {
        this.title = title;
        this.students = [];
        this.startDate = [2020, 1];
        this.state = STATE.UNSTARTED;
        this.nextMilestones = [];
        this.documentationStatus = 0;
    }

}
