import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../../../services/api.service";
import {Project} from "../../../../models/Project";
import {AuthService} from "../../../../services/auth.service";
import {AdminService} from "../../../../administrator/services/admin.service";

@Component({
    selector: 'app-finish-project-dialog',
    templateUrl: './finish-project-dialog.component.html',
    styleUrls: ['./finish-project-dialog.component.scss']
})
export class FinishProjectDialogComponent implements OnInit {

    public endDate: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<FinishProjectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { title: string, body: string, project: Project },
        private fb: FormBuilder,
        private apiService: ApiService,
        private adminService: AdminService,
        public auth: AuthService) {
    }

    ngOnInit(): void {
        const today = new Date();
        this.endDate = this.fb.group({
            date: [today, [Validators.required]],
        });
    }

    confirmClose() {
        if (this.endDate.invalid) {
            return;
        }
        this.adminService.finishProject(this.data.project, {endDate: this.endDate.value.date}).subscribe(
            data => {
                this.data.project.state = data.state;
                this.dialogRef.close(true);
            },
            () => {
                this.dialogRef.close(false);
            }
        );
    }

}
