import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiService} from 'src/app/services/api.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AuthService} from 'src/app/services/auth.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Teacher} from 'src/app/models/Teacher';
import {MatChipInputEvent} from '@angular/material/chips';
import {AdminService} from 'src/app/administrator/services/admin.service';


@Component({
  selector: 'app-teacher-editor',
  templateUrl: './teacher-editor.component.html',
  styleUrls: ['./teacher-editor.component.scss']
})
export class TeacherEditorComponent implements OnInit {
  public separatorKeysCodes = [ENTER, COMMA];
  public tags: string[] = [];
  public tagInput: FormControl;
  public teacherEditFG: FormGroup;

  constructor(
    private fb: FormBuilder,
    public auth: AuthService,
    public adminServ: AdminService,
    public dialogRef: MatDialogRef<TeacherEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public origTeacher: Teacher
  ) {
  }

  ngOnInit(): void {
    this.teacherEditFG = this.fb.group({
      title: [this.origTeacher.title, [Validators.required]],
      firstName: [this.origTeacher.firstName, []],
      lastName: [this.origTeacher.lastName, []],
      bio: [this.origTeacher.bio, []],
      area: [this.origTeacher.area, []],
      // tags: this.fb.array([this.origTeacher.tags, [Validators.required]]),
    });
    this.tags = this.origTeacher.tags?.map(t => t) || [];
    this.tagInput = this.fb.control('', [this.AlreadyUsedTagValidator.bind(this)]);
  }

  // Art categories09
  addTag(event: MatChipInputEvent): void {
    if (this.tagInput.valid) {
      this.tags.push(this.tagInput.value);
      this.tagInput.reset();
    }
  }

  removeTag(tag: any, index: number): void {

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  save() {
    let aux: Teacher = this.teacherEditFG.value;
    aux.tags = this.tags;
    if (this.auth.isAdmin) {
      this.adminServ.editUser(this.origTeacher._id, aux).subscribe(
        teacher => {
          for (const key in aux)
            this.origTeacher[key] = teacher[key];
          this.dialogRef.close();
        }
      );
    } else if (this.auth.isTeacher && this.auth.user._id === this.origTeacher._id) {
      this.auth.editMe(aux).subscribe(
        teacher => {
          this.dialogRef.close();
        }
      );
    }
  }

  AlreadyUsedTagValidator(control: FormControl) {
    if (this.tags.map(t => t.toLowerCase()).includes(control.value?.toLowerCase())) {
      return {alreadyUsed: true};
    }
    return null;
  }

}
