import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-project-dialog',
  templateUrl: './launch-project-dialog.component.html',
  styleUrls: ['./launch-project-dialog.component.scss']
})
export class LaunchProjectDialogComponent implements OnInit {
  public emailBody: FormGroup;
  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<LaunchProjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, body: string }
  ) { }

  ngOnInit(): void {
    this.api.getCurrentLaunchEmailTemplate().subscribe(
      (data: string) => {
        this.getTemplate(data);
      },
      (error) => {
        console.error('Error fetching template:', error);
        // Handle error or set default template
      }
    );
  }

  getTemplate(emailTemplate: string) {
    this.emailBody = this.fb.group({
      email_default_body: [emailTemplate, [Validators.required]],
      checkbox_control: [true, [Validators.required]],
      update_template_control: [false, [Validators.required]]
    });

  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }

  getEmailBodyValue() {
    return this.emailBody.get('email_default_body').value;
  }

  getCheckboxValue() {
    return this.emailBody.get('checkbox_control').value;
  }

  getUpdateTemplateValue() {
    return this.emailBody.get('update_template_control').value;
  }

  closeDialog(data: any): void {
    this.dialogRef.close(data);
  }

}
