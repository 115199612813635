<div class="form">
  <div class="header">
    <div class="back" (click)="back()">
      <mat-icon>arrow_back</mat-icon>
    </div>
    <div class="inner" style="margin-top: 15px">
      <h2>{{project ? 'Edición' : 'Propuesta'}} de proyecto -</h2>
      <h2 *ngIf="auth.isStudent">{{(api.currentSemester | async)[1]}}C {{(api.currentSemester | async)[0]}}</h2>
      <div *ngIf="!auth.isStudent">
        <mat-form-field>
          <!-- <mat-label>Tipo de meta</mat-label> -->
          <mat-select [formControl]="startDateControl.at(1)" style="text-align: center;">
            <mat-option [value]="1" style="text-align: center;">1C</mat-option>
            <mat-option [value]="2" style="text-align: center;">2C</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-select [formControl]="startDateControl.at(0)">
            <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-slide-toggle #tooltip="matTooltip" (click)="showTooltipOnClick(tooltip)"
                          [matTooltip]="isPublic ? 'Es visible por todos.' : 'Es visible solo por los integrantes del proyecto.'"
                          *ngIf="!stateControl.value" [(ngModel)]="isPublic" color="primary" #toggle>
          <p style="margin-bottom: 0" [ngClass]="{color : toggle.checked}">{{isPublic?'Pública' : 'Privada'}}</p>
        </mat-slide-toggle>
      </div>
      <div class="admin-tools">
        <mat-slide-toggle *ngIf="auth.isAdmin && !stateControl.value" #tooltip="matTooltip"
                          (click)="showTooltipOnClick(tooltip)"
                          [matTooltip]="accepted ? 'Es aceptada como propuesta.' : 'Esta en revisión.'"
                          [(ngModel)]="accepted" color="primary" #toggle2>
          <p style="margin-bottom: 0" [ngClass]="{color : toggle2.checked}">{{accepted?'Aceptada' : 'No aceptada'}}</p>
        </mat-slide-toggle>
        <mat-form-field *ngIf="auth.isAdmin">
          <mat-label>Estado</mat-label>
          <mat-select [formControl]="stateControl" style="font-size: medium;">
            <mat-option [value]="0"><p>Sin empezar</p></mat-option>
            <mat-option [value]="1">En proceso</mat-option>
            <mat-option [value]="2">Terminado</mat-option>
            <mat-option [value]="3">Cancelado</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <mat-card class="mat-elevation-z3" style="margin-top: 0px;">
    <div class="title">
      <h3>Información general</h3>
      <mat-label style="color: red">* Indica campo obligatorio</mat-label>
    </div>

    <!-- TITULO -->
    <div class="hor">
      <mat-form-field>
        <input matInput [formControl]="titleControl" placeholder="Título" required>
        <mat-error for="title" class="form-error">
          {{getErrorMessage('title')}}</mat-error>
      </mat-form-field>
    </div>
    <!-- ABSTRACT -->
    <div class="hor">
      <mat-form-field>
        <mat-label>Abstract</mat-label>
        <textarea matInput [formControl]="abstractControl" required>
              </textarea>
        <mat-error for="abstract" class="form-error">
          {{getErrorMessage('abstract')}}</mat-error>
      </mat-form-field>
    </div>
    <!-- OBJETIVO -->
    <div class="hor">
      <mat-form-field>
        <mat-label>Objetivo</mat-label>
        <input matInput [formControl]="objectivesControl">
        <mat-hint>Indicar aquí qué se quiere lograr con este trabajo.</mat-hint>
        <mat-error for="objective" class="form-error">{{getErrorMessage('objective')}}</mat-error>
      </mat-form-field>
    </div>
    <!-- ANTECEDENTES -->
    <div class="hor">
      <mat-form-field>
        <mat-label>Antecedentes</mat-label>
        <textarea matInput [formControl]="backgroundControl"></textarea>
        <mat-hint>Indicar aquí trabajos anteriores, bibliografía, etc.</mat-hint>
        <mat-error for="background" class="form-error">{{getErrorMessage('background')}}</mat-error>
      </mat-form-field>
    </div>
    <!-- TIPO DE PROYECTO -->
    <div class="ver">
      <div class="hor checkboxes">
        <mat-label class="asterix-after">Tipo de proyecto</mat-label>
        <mat-checkbox [(ngModel)]="investigationProject" color="primary">
          Proyecto de investigación
        </mat-checkbox>
        <mat-checkbox [(ngModel)]="developmentProject" color="primary">
          Proyecto de desarrollo
        </mat-checkbox>
      </div>
      <p class="error-form-text" *ngIf="projectTypeError">Es necesario seleccionar algun tipo de proyecto</p>
    </div>
    <!-- TAGS -->
    <div class="ver">
      <mat-form-field>
        <mat-label for="tag">Tags</mat-label>
        <div class="cat-input">
          <input id="categories" matInput name="tag" placeholder="Ej: Machine Learning, Criptografía"
                 [matChipInputFor]="chipListTags"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 (matChipInputTokenEnd)="addTag($event)"
                 [matAutocomplete]="tagAuto"
                 [formControl]="tagInput">
          <button class="cat-input-btn" (click)="addTag($event)">
            <img src="assets/icons/forward.svg">
          </button>
        </div>
        <mat-autocomplete #tagAuto="matAutocomplete" (optionSelected)="selectedAutocompleteTag($event)">
          <mat-option *ngFor="let tag of autocompleteTags | async" [value]="tag">
            {{tag}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-chip-list #chipListTags>
        <mat-basic-chip *ngFor="let tag of tags; let i = index" [selectable]="true" [removable]="true"
                        (remove)="removeTag(tag, i)">
          {{tag}}
          <mat-icon matChipRemove (click)="removeTag(tag, i)">cancel</mat-icon>
        </mat-basic-chip>
      </mat-chip-list>
    </div>
  </mat-card>

  <mat-card *ngIf="!auth.isStudent" class="mat-elevation-z3">
    <h3>Involucrados</h3>
    <mat-label class="marbot">Indique la dirección de email de otros profesores del ITBA que participen como
      co-tutores
      del proyecto.
    </mat-label>
    <br/>
    <br/>
    <div class="ver">
      <mat-form-field>
        <mat-label for="tutor">Tutores</mat-label>
        <div class="cat-input">
          <input id="tutors" matInput name="tutor" placeholder="Nombre o email del tutor"
                 [matAutocomplete]="tutorAuto"
                 [formControl]="tutorInput">
        </div>

        <mat-autocomplete #tutorAuto="matAutocomplete" [class]="'custom-autocomplete'"
                          (optionSelected)="selectedAutocompleteTutor($event)">
          <mat-option *ngFor="let tutor of autocompleteTutors | async" [value]="tutor">

            <div class="autocomplete-option">
              <p *ngIf="tutor?.firstName">{{capitalizeName(tutor.firstName + " " + tutor.lastName)}}</p>
              <p *ngIf="!tutor?.firstName">{{tutor.email}}</p>
              <div class="hor">
                <p *ngIf="tutor.tags !== undefined && tutor.tags.length">Intereses:</p>
                <mat-chip-list>
                  <mat-basic-chip *ngFor="let tag of tutor.tags?.slice(0,6); let i=index">{{tag}}</mat-basic-chip>
                  <mat-basic-chip *ngIf="tutor.tags?.length >=6">...</mat-basic-chip>
                </mat-chip-list>
              </div>
            </div>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-chip-list #chipListTutors>
        <mat-basic-chip *ngFor="let tutor of tutors; let i = index">
          {{tutor?.firstName ? capitalizeName(tutor.firstName + " " + tutor.lastName) : tutor.email}}
          <mat-icon matChipRemove (click)="removeTutor(tutor, i)">cancel</mat-icon>
        </mat-basic-chip>
      </mat-chip-list>
    </div>

    <div class="hor">

    </div>
    <!-- MAX ALUMNOS -->
    <div *ngIf="!stateControl.value">
      <mat-form-field>
        <input matInput [formControl]="maxStudentsControl" type="number" min="1" placeholder="Máx. alumnos">
        <mat-error for="maxStudents" class="form-error">
          {{getErrorMessage('maxStudents')}}</mat-error>
      </mat-form-field>
    </div>
    <!-- ALUMNOS -->
    <div class="hor" *ngIf="auth.isAdmin">
      <mat-form-field *ngFor="let studentControl of studentControls.controls; let i=index">
        <input matInput placeholder="Email alumno" [formControl]="studentControl">
        <mat-error for="tutor" class="form-error">
          {{getErrorMessage('tutor')}}</mat-error>
      </mat-form-field>
    </div>
    <!-- REQUISITOS -->
    <div class="hor" *ngIf="!stateControl.value">
      <mat-form-field>
        <mat-label>Requisitos de los alumnos para poder aplicar</mat-label>
        <textarea matInput [formControl]="requisitesControl">
                </textarea>
        <mat-error for="requisites" class="form-error">
          {{getErrorMessage('requisites')}}</mat-error>
      </mat-form-field>
    </div>
    <div class="ver" *ngIf="!auth.isTeacher">
      <mat-form-field>
        <mat-label for="tutor">Otros posibles tutores</mat-label>
        <div class="cat-input">
          <input id="posible-tutors" matInput name="tutor"
                 [matAutocomplete]="posibleTutorAuto"
                 [formControl]="posibleTutorInput">
        </div>
        <mat-autocomplete #posibleTutorAuto="matAutocomplete" [class]="'custom-autocomplete'"
                          (optionSelected)="selectedAutocompletePosibleTutor($event)">
          <mat-option *ngFor="let tutor of autocompletePosibleTutors | async" [value]="tutor">
            <div class="autocomplete-option">
              <p>{{tutor | usernameOrEmail}}</p>
              <div class="hor">
                <p *ngIf="tutor.tags !== undefined && tutor.tags.length">Intereses:</p>
                <mat-chip-list>
                  <mat-basic-chip *ngFor="let tag of tutor.tags?.slice(0,6); let i=index">{{tag}}</mat-basic-chip>
                  <mat-basic-chip *ngIf="tutor.tags?.length >=6">...</mat-basic-chip>                
                </mat-chip-list>
              </div>
            </div>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-chip-list #chipListTutors>
        <mat-basic-chip *ngFor="let tutor of posibleTutors; let i = index">
          {{tutor}}
          <mat-icon matChipRemove (click)="removePosibleTutor(tutor, i)">cancel</mat-icon>
        </mat-basic-chip>
      </mat-chip-list>
    </div>
  </mat-card>
  <mat-card *ngIf="auth.isStudent && !project" class="mat-elevation-z3">
    <h3>Sumá a tus compañeros</h3>
    <div class="hor">
      <p>
        Si tenés pensado este proyecto para hacerlo con un grupo, agregalos. Si te falta gente o querés buscar
        interesados
        lanzalo como propuesta pública.
      </p>
      <mat-slide-toggle [(ngModel)]="isPublic" #toggle color="primary">
      <p style="margin-bottom: 0" [ngClass]="{color : toggle.checked}">{{isPublic?'Propuesta pública' : 'Propuesta privada'}}</p></mat-slide-toggle>
    </div>
    <!-- ALUMNOS -->
    <div class="hor">
      <mat-form-field *ngFor="let studentControl of studentControls.controls; let i=index">
        <input matInput placeholder="Email alumno" [formControl]="studentControl"
               [required]="project?.state && i === 0">
        <mat-error for="tutor" class="form-error">
          {{getErrorMessage('tutor')}}</mat-error>
      </mat-form-field>
    </div>
  </mat-card>

  <mat-card class="mat-elevation-z3">
    <h3>Datos extra</h3>
        <div class="ver" *ngIf="auth.isStudent">
      <mat-form-field>
        <mat-label for="tutor">Posibles tutores</mat-label>
        <div class="cat-input">
          <input id="posible-tutors" matInput name="tutor"
                 [matAutocomplete]="posibleTutorAuto"
                 [formControl]="posibleTutorInput">
        </div>
        <mat-autocomplete #posibleTutorAuto="matAutocomplete" [class]="'custom-autocomplete'"
                          (optionSelected)="selectedAutocompletePosibleTutor($event)">
          <mat-option *ngFor="let tutor of autocompletePosibleTutors | async" [value]="tutor">
            <div class="autocomplete-option">
              <p>{{tutor | usernameOrEmail}}</p>
              <div class="hor">
                <p *ngIf="tutor.tags !== undefined && tutor.tags.length">Intereses:</p>
                <mat-chip-list>
                  <mat-basic-chip *ngFor="let tag of tutor.tags?.slice(0,6); let i=index">{{tag}}</mat-basic-chip>
                  <mat-basic-chip *ngIf="tutor.tags?.length >=6">...</mat-basic-chip>
                </mat-chip-list>
              </div>
            </div>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-chip-list #chipListTutors>
        <mat-basic-chip *ngFor="let tutor of posibleTutors; let i = index">
          {{tutor}}
          <mat-icon matChipRemove (click)="removePosibleTutor(tutor, i)">cancel</mat-icon>
        </mat-basic-chip>
      </mat-chip-list>
    </div>
    <!-- MATERIALES ENTREGABLES -->
    <div class="hor">
      <mat-form-field>
        <mat-label>Materiales entregables</mat-label>
        <textarea matInput [formControl]="deliverablesControl"></textarea>
        <mat-error for="deliverables" class="form-error">
          {{getErrorMessage('deliverables')}}</mat-error>
      </mat-form-field>
    </div>
    <!-- IMPACTO SOCIAL -->
    <div class="hor">
      <mat-form-field>
        <mat-label>Impacto social o ambiental</mat-label>
        <input matInput [formControl]="impactControl">
        <mat-hint>Indicar el tipo de impacto, innovación y en caso de aplicar, la ONG que ha solicitado el
          proyecto.
        </mat-hint>
        <mat-error for="impact" class="form-error">
          {{getErrorMessage('impact')}}</mat-error>
      </mat-form-field>
    </div>
    <!-- PARTICIPANTES EXTERNOS -->
    <div class="hor">
      <mat-form-field>
        <mat-label>Participantes externos</mat-label>
        <textarea matInput [formControl]="externalParticipantsControl"></textarea>
        <mat-hint>Indique los participantes externos al departamento de informática. Se debe indicar el nombre,
          una
          descripción o mini bio
          del participante, la calidad de su participación (co-tutor, stakeholder, soporte externo, etc) y una
          dirección
          de email.
        </mat-hint>
        <mat-error for="externalParticipants" class="form-error">
          {{getErrorMessage('externalParticipants')}}</mat-error>
      </mat-form-field>
    </div>

    <!-- COMENTARIOS -->
    <div class="hor">
      <mat-form-field>
        <mat-label>Comentarios</mat-label>
        <textarea matInput [formControl]="extraCommentsControl"></textarea>
        <mat-hint>Por favor, incluya cualquier comentario que considere pertinente.</mat-hint>
        <mat-error for="extraComments" class="form-error">
          {{getErrorMessage('extraComments')}}</mat-error>
      </mat-form-field>
    </div>
  </mat-card>

  <div class="hor buttons">
    <button mat-stroked-button class="btn-blue" style="width: 150px" (click)="uploadProject()">
      {{project ? 'Guardar cambios' : 'Proponer'}}
    </button>
  </div>

</div>
