import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../../../services/api.service";
import {Teacher} from "../../../../models/Teacher";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'app-tutors',
  templateUrl: './tutors.component.html',
  styleUrls: ['./tutors.component.scss']
})
export class TutorsComponent implements OnInit {

  tutors: Teacher[] = [];
  filteredTutors: Teacher[];
  tutorDS = new MatTableDataSource<Teacher>();

  constructor(private api: ApiService) {
  }

  ngOnInit(): void {
    this.api.getTeachers().subscribe(
      teachers => {
        this.tutors = teachers.filter(t => t.firstName);
        this.filteredTutors = this.tutors;
      }
    );
  }

  onSearchInputChanged(input: string) {
    const loweredInput = input.toLowerCase();
    this.filteredTutors = this.tutors.filter(t =>
      t.firstName?.toLowerCase().includes(loweredInput) ||
      t.lastName?.toLowerCase().includes(loweredInput) ||
      t.bio?.toLowerCase().includes(loweredInput) ||
      t.email?.toLowerCase().includes(loweredInput) ||
      (t.tags?.some(tag => tag.toLowerCase().includes(loweredInput)) ?? false) ||
      t.area?.toLowerCase().includes(loweredInput)
    );
  }
}
