<div class="col-12 project-info">
  <mat-card class="card" [ngClass]="{graded: graded, finishedNotGraded: !graded}">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-6 col-xs-6">
        <h2>{{project?.title | uppercase}}</h2>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6">
        <div class="action-btn-group" *ngIf="auth.isAdmin" style="margin-top: 0px;justify-content: right;">
          <button mat-stroked-button class="btn-blue" (click)="openEmailSender()">Enviar mail al equipo</button>
        </div>
      </div>
    </div>
    <p *ngIf="!graded">* Necesita calificación</p>
    <div class="extras">
      <p class="abstract">{{ project.abstract }}</p>
      <div class="col-12" style="display: flex;">
        <div class="col-9">
          <div *ngIf="project?.students?.length">
            <b>{{project?.students.length > 1 ? 'Alumnos:' : 'Alumno:'}} </b>
            <div *ngFor="let student of project?.students">
              <p *ngIf="student.firstName; else noName" class="capitalize">
                {{student?.firstName + ' ' | lowercase }}{{student?.lastName | lowercase}}
              </p>
              <ng-template #noName>
                <p>
                  {{student.email}}
                </p>
              </ng-template>
            </div>
          </div>
          <div *ngIf="project?.tutors?.length">
            <b>{{project?.tutors.length > 0 ? 'Tutor:' : 'Tutores'}} </b>
            <div *ngFor="let tutor of project?.tutors; index as i" [routerLink]="['/teachers', tutor.email]"
                 class="click">
              <p *ngIf="tutor.firstName; else noName" class="capitalize">
                {{capitalizeName(tutor.firstName) + ' ' + capitalizeName(tutor.lastName)}}
              </p>
              <ng-template #noName>
                <p>
                  {{tutor.email}}
                </p>
              </ng-template>
            </div>
          </div>

          <div>
            <p *ngIf="project?.draftPaperLink" class="text-label">
              <b>Informe: </b>
              <a *ngIf="project?.draftPaperLink"
                 [href]="project.draftPaperLink.includes('http')? project.draftPaperLink: 'https://' + project.draftPaperLink">{{project.draftPaperLink.includes('http')
                ?
                project.draftPaperLink : 'https://' + project.draftPaperLink}}</a>
            </p>
          </div>
          <div>
            <p *ngIf="project?.repository" class="text-label">
              <b>Bitbucket: </b>
              <a *ngIf="project?.repository"
                 [href]="project.repository.includes('http')? project.repository: 'https://' + project.repository">{{project.repository.includes('http')
                ?
                project.repository : 'https://' + project.repository}}</a>
            </p>
          </div>
          <!-- Fecha de finalizacion -->
          <p *ngIf="project?.endDate" class="text-label">
            <b>Fecha de finalización: </b> {{ project?.endDate | date:'dd/MM/yyyy' }}
          </p>
        </div>
      </div>
      <h2 style="margin-bottom: 5px;font-size: 1.5rem">Presentación</h2>
      <p *ngIf="project?.classroom" class="text-label">
        <b>Aula: </b>{{ project?.classroom }}{{ project?.campus }}
      </p>
      <mat-slide-toggle *ngIf="project?.confirmedPresentation && auth.isAdmin && !this.closed" [color]="'primary'"
                        [checked]="project?.publicPresentation" (change)="onPresentationVisibilityChange($event)"
                        #toggle>
        <p style="margin-bottom: 0" [ngClass]="{color : toggle.checked}"><b>{{tooltip()}}</b></p>
      </mat-slide-toggle>
      <!-- Fecha de presentación -->
      <p *ngIf="project?.presentationDate" class="text-label" style="padding-top: 5px;">
        <b>Fecha de presentación: </b>{{ project?.presentationDate | date:'dd/MM/yyyy' }} {{ project?.presentationTime}}
      </p>
      <!-- Link de la reunión -->
      <p *ngIf="project?.meetingLink" class="text-label">
        <b>Link de la reunión: </b><a href="{{project?.meetingLink}}">{{ project?.meetingLink }}</a>
      </p>
      <!-- Jurado -->
      <p *ngIf="project?.jury?.length" class="text-label">
        <b class="jury-title">{{project?.jury.length > 1 ? 'Jurados:' : 'Jurado:'}} </b>
        <mat-chip-list #chipListJury>
          <mat-chip *ngFor="let juror of project?.jury; let i = index" [selectable]="true"
                    [removable]="!this.closed">{{juror.name ? juror.name + ' (' + juror.jury + ')' : juror.jury}}
            <mat-icon matChipRemove *ngIf="auth.isAdmin && !this.closed" (click)="removeJuror(juror, i)">cancel
            </mat-icon>
          </mat-chip>
        </mat-chip-list>
      </p>
      <h3 style="margin-bottom: 5px;font-size: 1.2rem" *ngIf="auth.isStudent || auth.isAdmin"><b>Descarga de
        documentos</b></h3>
      <div class="col-12">
        <button mat-stroked-button class="btn-blue" (click)="downloadLibAuthDoc()"
                *ngIf="auth.isStudent || auth.isAdmin" style="margin: 5px 0">
          Autorización para alumnos
          <mat-icon style="padding-left: 5px;">file_download</mat-icon>
        </button>
        <button mat-stroked-button class="btn-blue" [disabled]="!stepFiveCompleted" (click)="downloadRecordDoc()"
                *ngIf="auth.isTeacher || auth.isAdmin" style="margin-left: 12px;margin-top: 5px; margin-bottom: 5px">
          Acta
          Final
          <mat-icon style="padding-left: 5px;">file_download</mat-icon>
        </button>
      </div>
      <h3 style="margin-bottom: 5px;font-size: 1.2rem" *ngIf="(auth.isAdmin || auth.isTeacher) && !auth.accessByToken ">
        <div class="custom-class">
          <span><b>Administración de accesos </b></span>
          <mat-icon color="primary"
                    matTooltip="Presione en Recordatorio dictamen para recordar a jurado y tutores que luego de la defensa deben cargar el dictamen. Se enviará el link que les permitirá hacerlo.">
            info
          </mat-icon>
        </div>
      </h3>
      <div class="row" *ngIf="(auth.isAdmin || auth.isTeacher) && !auth.accessByToken ">
        <div class="col-12" *ngIf="auth.isTeacher || auth.isAdmin" style="margin: 5px 0">
          <button mat-stroked-button class="btn-blue" [disabled]="!stepThreeCompleted"
                  (click)="openJuryAccessDialog()">Recordatorio dictamen
          </button>
        </div>
      </div>

      <div class="text-label" *ngIf="userType == 2" style="display: flex; align-items: center;">
        <b style="margin-right: 8px;">Estado biblioteca: </b>
        <mat-form-field style="width: 15%; text-align: center;">
          <mat-select color="primary" (selectionChange)="changeDocumentationStatus($event.value)"
                      [value]="project?.documentationStatus" name="auth" [disabled]="this.closed">
            <mat-option [value]="0">NO ENVIADO</mat-option>
            <mat-option [value]="1">ENVIADO</mat-option>
            <mat-option [value]="2">RECIBIDO</mat-option>
            <mat-option [value]="3">COMPLETO</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- Fecha de Calificacion -->
      <p *ngIf="project?.gradeDate" class="text-label">
        <b>Fecha de calificación: </b>{{ project?.gradeDate | date }}
      </p>
      <mat-chip-list #chipList class="chips">
        <mat-basic-chip *ngFor="let tag of project?.tags; let i = index">
          {{tag}}
        </mat-basic-chip>
      </mat-chip-list>
      <div *ngIf="auth.isAdmin && !this.closed" style="margin: 20px 0">
        <mat-form-field style="width: 100%">
          <mat-label>Comentario</mat-label>
          <textarea matInput placeholder="Nota para el administrador..." (change)="editComment($event)"
                    [(ngModel)]="adminComment"></textarea>
        </mat-form-field>
      </div>
      <div class="col-12" *ngIf="!this.closed && (this.auth.isAdmin || isTutor && !this.auth.isStudent)">
        <mat-horizontal-stepper [linear]="isLinear" #stepper *ngIf="!this.auth.accessByToken; else externalJuryForm">
          <mat-step [stepControl]="firstFormGroup" [completed]="stepOneCompleted">
            <form [formGroup]="firstFormGroup">
              <ng-template matStepLabel>
                <div (click)="openJuryDialog()" style="padding: 20px">Agregar jurado</div>
              </ng-template>
              <div>
              </div>
            </form>
          </mat-step>
          <div (click)="openPresentationDialog()">
            <mat-step [stepControl]="secondFormGroup" [editable]="stepOneCompleted" [completed]="stepTwoCompleted">
              <form [formGroup]="secondFormGroup">
                <ng-template matStepLabel>
                  <div (click)="openPresentationDialog()" style="padding: 20px">
                    {{project?.presentationDate ? 'Editar presentación' : 'Agregar presentación'}}
                  </div>
                </ng-template>
              </form>
            </mat-step>
          </div>
          <mat-step [stepControl]="firstFormGroup" [completed]="stepThreeCompleted" [editable]="stepTwoCompleted"
                    *ngIf="this.auth.isAdmin">
            <form [formGroup]="firstFormGroup">
              <ng-template matStepLabel>
                <div (click)="openConfirmPresentationDialog()" style="padding: 20px">Confirmar</div>
              </ng-template>
            </form>
          </mat-step>
          <mat-step [stepControl]="firstFormGroup" [completed]="stepFourCompleted" [editable]="stepThreeCompleted">
            <form [formGroup]="firstFormGroup">
              <ng-template matStepLabel>
                <div (click)="openGradeDialog(); !stepThreeCompleted && openSnackBar()" style="padding: 20px">Cargar
                  Nota
                </div>
              </ng-template>
            </form>
          </mat-step>
          <mat-step [stepControl]="firstFormGroup" [completed]="stepFiveCompleted" [editable]="stepFourCompleted">
            <form [formGroup]="firstFormGroup">
              <ng-template matStepLabel>
                <div (click)="openJuryCommentDialog()" style="padding: 20px">Cargar Dictamen</div>
              </ng-template>
            </form>
          </mat-step>
        </mat-horizontal-stepper>

        <div style="text-align: center;">
          <button mat-stroked-button class="btn-blue" (click)="closeProject()" *ngIf="auth.isAdmin"
                  [disabled]="!(stepOneCompleted && stepTwoCompleted && stepThreeCompleted && stepFourCompleted && stepFiveCompleted)">
            Cerrar proyecto
          </button>
        </div>

        <ng-template #externalJuryForm>
          <hr>
          <h4 style="text-align-last: center;padding-bottom: 20px;padding-top: 20px">Si sos jurado externo, hacer click
            acá:
            <button mat-stroked-button class="btn-blue" (click)="openJuryCommentDialog()">
              Cargar Dictamen
            </button>
          </h4>
          <hr>
        </ng-template>
      </div>
      <!-- TABLA DE CALIFICACIONES ALUMNOS-->
      <div *ngIf="project?.students.length > 0" style="margin-top: 40px">
        <h2 style="font-size: 1.5rem">Calificaciones</h2>
        <table mat-table [dataSource]="project.students" style="width: 100%">
          <ng-container matColumnDef="alumno">
            <th mat-header-cell *matHeaderCellDef> Alumno</th>
            <td mat-cell
                *matCellDef="let element">{{element.firstName ? capitalizeName(element.firstName) + ' ' + capitalizeName(element.lastName) :
              element.email}}</td>
          </ng-container>
          <ng-container matColumnDef="nota">
            <th mat-header-cell *matHeaderCellDef> Nota</th>
            <td mat-cell
                *matCellDef="let element"> {{element.projectGrade?.grade ? element.projectGrade?.grade : ' En espera '}} </td>
          </ng-container>
          <ng-container matColumnDef="acta">
            <th mat-header-cell *matHeaderCellDef>Acta</th>
            <td mat-cell *matCellDef="let element"> {{element.projectGrade?.acta ? element.projectGrade?.acta : ' -'}}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="studentGradeColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: studentGradeColumns;"></tr>
        </table>
      </div>
      <!-- TABLA DE DICTAMENES TUTORES Y JURADOS-->
      <div *ngIf="project?.juryComments?.length > 0" style="margin-top: 40px;">
        <h2 style="font-size: 1.5rem">Dictámenes</h2>
        <table mat-table [dataSource]="project.juryComments" style="width: 100%;">
          <ng-container matColumnDef="jurado">
            <th mat-header-cell *matHeaderCellDef> Tutor/Jurado</th>
            <td mat-cell *matCellDef="let element">{{element.tutor}}</td>
          </ng-container>
          <ng-container matColumnDef="dictamen">
            <th mat-header-cell *matHeaderCellDef style="padding-left: 40px;">Dictamen</th>
            <td mat-cell *matCellDef="let element" style="padding: 40px;text-align: justify"> {{element.comment}}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="juryCommentColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: juryCommentColumns;"></tr>
        </table>
      </div>
    </div>
  </mat-card>
</div>
