<div class="container">
    <h1 mat-dialog-title *ngIf="data.title" [innerHtml]="data.title"></h1>
    <p [innerHTML]="data.body"></p>
    <div mat-dialog-actions>
        <button mat-raised-button color="primary" (click)="confirmClose()">Aceptar</button>
        <mat-form-field [formGroup]="endDate">
            <mat-label>Fecha de finalización</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="date">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>
    <img src="assets/icons/close.svg" alt="" (click)="this.dialogRef.close(false)">
</div>
