import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TeacherComponent} from './components/teacher/teacher.component';
import {StudentComponent} from './components/student/student.component';
import {LoginComponent} from './components/login/login.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxApiUtilsModule} from 'ngx-api-utils';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatChipsModule} from '@angular/material/chips';
import {AuthServiceConfig, GoogleLoginProvider, SocialLoginModule} from 'angularx-social-login';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {IntroComponent} from './components/student/intro/intro.component';
import {ChoiceComponent} from './components/student/choice/choice.component';
import {ProjectDetailComponent} from './components/project/project-detail/project-detail.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatRadioModule} from '@angular/material/radio';
import {ProjectEditorComponent} from './components/project/project-editor/project-editor.component';
import {ProjectFeedComponent} from './components/project/project-feed/project-feed.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MilestonesViewerComponent} from './components/project/milestones-viewer/milestones-viewer.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {TeacherDashboardComponent} from './components/teacher/teacher-dashboard/teacher-dashboard.component';
import {TeacherInfoComponent} from './components/teacher/teacher-info/teacher-info.component';
import {NavbarComponent} from './components/navbar/navbar.component';
import {TeacherProfileComponent} from './components/teacher/teacher-profile/teacher-profile.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {TeacherProjectListComponent} from './components/teacher/teacher-project-list/teacher-project-list.component';
import {QuillModule} from 'ngx-quill';
import {SharedModule} from './shared/shared.module';
import {ProjectModule} from './components/project/project.module';
import {ProjectCardComponent} from './components/project/project-card/project-card.component';
import {GradeEditorComponent} from './components/project/project-card/grade-editor/grade-editor.component';
import {PresentationEditorComponent} from './components/project/project-card/presentation-editor/presentation-editor.component';
import {ChartsModule} from 'ng2-charts';
import {PublicProposalsComponent} from './components/student/choice/public-proposals/public-proposals.component';
import {TutorsComponent} from './components/student/choice/tutors/tutors.component';
import {PresentationsComponent} from './components/student/choice/presentations/presentations.component';
import { ApplicationsComponent } from './components/student/choice/applications/applications.component';
import {MatDividerModule} from "@angular/material/divider";
import {JuryEditorComponent} from './components/project/project-card/jury-editor/jury-editor.component';
import {ConfirmationSenderComponent} from './components/project/project-card/confirmation-sender/confirmation-sender.component';
import {UsernameOrEmailPipe} from './components/project/pipes/username-or-email.pipe';
import {MatStepperModule} from "@angular/material/stepper";
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { StudentProfileComponent } from './components/student/student-profile/student-profile.component';

registerLocaleData(localeEs);

const authConfig = new AuthServiceConfig([
    {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider('999053421866-kjmnfd5t7udr5lqdbl79stal1g3q9svf.apps.googleusercontent.com', {
            scope: 'profile email',
            prompt: 'select_account'
        })
    }
]);

export function provideAuthConfig() {
    return authConfig;
}


@NgModule({
    declarations: [
        AppComponent,
        TeacherComponent,
        StudentComponent,
        LoginComponent,
        IntroComponent,
        ChoiceComponent,
        ProjectDetailComponent,
        ProjectEditorComponent,
        ProjectFeedComponent,
        MilestonesViewerComponent,
        TeacherDashboardComponent,
        TeacherInfoComponent,
        NavbarComponent,
        TeacherProfileComponent,
        TeacherProjectListComponent,
        ProjectCardComponent,
        GradeEditorComponent,
        PresentationEditorComponent,
        PublicProposalsComponent,
        TutorsComponent,
        PresentationsComponent,
        JuryEditorComponent,
        ConfirmationSenderComponent,
        UsernameOrEmailPipe,
        StudentProfileComponent,
        ApplicationsComponent,
      
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgxApiUtilsModule,
    SocialLoginModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatTableModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatChipsModule,
    MatTooltipModule,
    MatNativeDateModule,
    NgxMaterialTimepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSortModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    QuillModule.forRoot(),
    SharedModule,
    ChartsModule,
    ProjectModule,
    MatDividerModule,
    MatStepperModule
  ],
    providers: [
        {provide: AuthServiceConfig, useFactory: provideAuthConfig},
        {provide: LOCALE_ID, useValue: 'es'}
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
