<div class="container">
  <img src="assets/icons/close.svg" alt="" (click)="this.dialogRef.close(false)" cdkFocusInitial>
  <h1 mat-dialog-title *ngIf="emailData.emailType === 0">¿Desea enviar un mail?</h1>
  <h1 mat-dialog-title *ngIf="emailData.emailType !== 0">¿Desea notificarlo con un mail?</h1>
  <div mat-dialog-content>
    <div class="recipients" *ngIf="teacherRecipients.length">
      <b>{{teacherRecipients?.length > 1? 'Tutores:' : 'Tutor:'}}</b>
      <mat-chip-list #chipList>
        <mat-basic-chip *ngFor="let user of teacherRecipients; let i = index"
                        (remove)="removeRecipient(i, user.type)">
          {{user | usernameOrEmail}}
          <mat-icon matChipRemove (click)="removeRecipient(i, user.type)">cancel</mat-icon>
        </mat-basic-chip>
      </mat-chip-list>
    </div>
    <div class="recipients" *ngIf="studentRecipients.length">
      <b>{{studentRecipients?.length > 1? 'Alumnos:' : 'Alumno:'}}</b>
      <mat-chip-list #chipList>
        <mat-basic-chip *ngFor="let user of studentRecipients; let i = index"
                        (remove)="removeRecipient(i, user.type)">
          {{user | usernameOrEmail}}
          <mat-icon matChipRemove (click)="removeRecipient(i, user.type)">cancel</mat-icon>
        </mat-basic-chip>
      </mat-chip-list>
    </div>
    <quill-editor
      [(ngModel)]="htmlText" placeholder="Agrega un texto personalizado"
      [modules]="quillConfig">
    </quill-editor>
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>No enviar</button>
    <button mat-stroked-button class="btn-blue" (click)="sendMail()">Enviar</button>
  </div>
</div>
