<div class="container" *ngIf="project?.milestones?.length" >
  <div class="col-12" style="text-align: center;">
    <button mat-stroked-button class="btn-blue" (click)="addEvent()"><mat-icon style="margin-bottom: 2px;">add</mat-icon>Agregar meta</button>
  </div>
  <ul class="timeline">
    <div class="title">
      <h2 style="margin: 0;">
        {{showAllMilestones ? 'Todas las metas' : 'Próximas metas'}}
      </h2>
      <a color="#3C48F6" (click)="showAllMilestones = !showAllMilestones"
         style="align-self: center;">{{showAllMilestones ? 'Ver solo próximas' : 'Ver todas'}}</a>
    </div>
    <li class="event"
        [attr.data-date]="event.dueDate | date: 'longDate'"
        [ngClass]="{'behind':isDelayed(event)}"
        *ngFor="let event of project?.milestones | fromDate:'dueDate':showAllMilestones">
      <div class="col-12" style="display: flex; align-items: flex-start;">
        <div class="col-11">
          <h3>{{event.name}}</h3>
          <p *ngIf="!event.comment">{{event.description}}</p>
          <p *ngIf="event.comment">{{event.comment}}</p>
        </div>
        <div class="col-1" style="display: flex; flex-direction: row; align-items: end; justify-content: space-around;">
          <mat-icon style="color:black;" *ngIf="!event.doneDate" class="icon-hover" (click)="finishEvent(event._id)">
            check
          </mat-icon>
          <mat-icon style="color:black;" *ngIf="!authService.isStudent" class="icon-hover"
                    (click)="deleteEvent(event._id)">delete
          </mat-icon>
        </div>
      </div>
    </li>
  </ul>
</div>
<div class="no-milestones" *ngIf="!project?.milestones?.length">
  <p>No tienes ninguna meta a alcanzar al momento.<br> Agrega una!</p>
  <div class="col-12" style="text-align: center;">
    <button mat-stroked-button class="btn-blue" (click)="addEvent()"><mat-icon style="margin-bottom: 2px;">add</mat-icon>Agregar meta</button>
  </div>
</div>

