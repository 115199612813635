const errors = {
  'required': 'Este campo es obligatorio',
  'badCharacters': 'Este campo contiene carácteres inválidos',
  'minlength': 'Este campo debe contener al menos X caracteres',
  'invalidCredentials': 'Las credenciales son incorrectas',
  'emailDoesntExist': 'No existe una cuenta con ese email asociado.',
  'email': 'Ingrese un email válido',
  'maxlength': 'El campo es muy largo',
  'min': 'Debe elegir una prioridad',
  'requiredshort': 'Requerido',
  'minlengthsix': 'Este campo debe contener al menos 6 caracteres',
};

export default errors;
