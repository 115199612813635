import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {USER_TYPE} from 'src/app/models/User';
import {DialogService} from '../services/dialog.service';

@Injectable({
  providedIn: 'root'
})
export class NotStudentGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private dialogService: DialogService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.auth.user) {
      this.dialogService.openLoginDialog();
      return false;
    }
    if (this.auth.user.type == USER_TYPE.student)
      return false;
    return true;
  }

}
