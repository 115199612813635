import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-milestone-accept',
  templateUrl: './milestone-accept.component.html',
  styleUrls: ['./milestone-accept.component.scss']
})
export class MilestoneAcceptComponent implements OnInit {
  acceptEventFG: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<MilestoneAcceptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, body: string },
    private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.acceptEventFG = this.fb.group({
      comment: ["", [Validators.required]]
    });
  }

  close() {
    if (this.acceptEventFG.valid) {
      this.dialogRef.close(this.acceptEventFG.value.comment);
    }
    return;
  }
}
