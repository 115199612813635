import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "../../../../services/api.service";
import { Project } from "../../../../models/Project";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from 'src/app/services/auth.service';


export interface PresentationData {
    meetingLink: string;
    presentationDate: Date;
    presentationTime: string;
    onlinePresentation: boolean;
    campus: string;
    classroom: string;
}

@Component({
    selector: 'app-presentation-editor',
    templateUrl: './presentation-editor.component.html',
    styleUrls: ['./presentation-editor.component.scss']
})
export class PresentationEditorComponent implements OnInit {

    presentationForm: FormGroup;
    public meetingLink: string;
    public presentationDate: Date;
    public presentationTime: string;
    public onlinePresentation: boolean;
    public classroom: string;
    public campus: string;

    campusOptions: string[] = ['F', 'R', 'T']; // Replace this with your actual list of options


    constructor(public dialogRef: MatDialogRef<PresentationEditorComponent>,
        private formBuilder: FormBuilder,
        private apiService: ApiService,
        private _matSnackbar: MatSnackBar,
        public auth: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: {
            project: Project
        }) {

    }

    ngOnInit(): void {
        const date = this.data.project.presentationDate || null;
        const time = this.data.project.presentationTime || null;
        const link = this.data.project.meetingLink || null;
        const campusData = this.data.project.campus || null;
        const classroomData = this.data.project.classroom || null;
        if (this.data.project.onlinePresentation === null) {
            this.data.project.onlinePresentation = false;
        }
        this.presentationForm = this.formBuilder.group({
            meetingLink: [link, [Validators.nullValidator]],
            presentationDate: [date, [Validators.required]],
            presentationTime: [time, [Validators.nullValidator]],
            campus: [campusData, [Validators.nullValidator]],
            classroom: [classroomData, [Validators.nullValidator]]
        });
    }

    addPresentation() {
        if (this.presentationForm.invalid) {
            return;
        }
        const presentation: PresentationData = {
            meetingLink: this.presentationForm.value.meetingLink,
            presentationDate: this.presentationForm.value.presentationDate,
            presentationTime: this.presentationForm.value.presentationTime,
            onlinePresentation: this.data.project.onlinePresentation,
            campus: this.presentationForm.value.campus,
            classroom: this.presentationForm.value.classroom
        };
        this.apiService.addPresentation(this.data.project._id, presentation, this.auth.user).subscribe(
            data => {
                this.data.project.meetingLink = data.meetingLink;
                this.data.project.presentationDate = data.presentationDate;
                this.data.project.presentationTime = data.presentationTime;
                this.data.project.campus = data.campus;
                this.data.project.classroom = data.classroom;
                this.closeDialog();
            }
        );
    }

    parseDateTimeToString(date: Date) {
        return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    }

    closeDialog() {
        this.dialogRef.close('closed');
    }

    onPresentationModeChange(event: any) {
        this.data.project.onlinePresentation = event.checked;
    }
    tooltip(): string {
        return this.data.project.onlinePresentation ? 'Virtual' : 'Presencial';
    }

}
