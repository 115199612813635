import {Project} from './Project';
import {Teacher} from './Teacher';
import {Student} from './Student';
import {ProjectApplication} from './ProjectApplication';
import {User} from './User';

export class Proposal extends Project {

    maxStudents?: number;
    posibleTutors: string[];
    creator: User | Teacher | Student;
    isPublic: boolean;
    accepted: boolean;
    applications: ProjectApplication[];
    type: PROPOSAL_TYPE;

    public static getStatus(prop: Proposal): string {
        // No puedo hacer que esto no sea static, si viene de un get el Proposal lo pega
        // sin los métodos, hay que lograr una manera que el get le pegue los métodos
        // de clase

        if (!prop.accepted) {
            return 'Siendo procesado...';
        }
        if (!prop.tutors.length) {
            return 'Sin tutores';
        }
        if (!prop.applications?.length) {
            return 'Libre';
        } else {
            const groupsWithOnePriority = prop.applications.some(a => a.priority === 1);
            if (groupsWithOnePriority) {
                return 'Hay interés con prioridad alta';
            } else {
                return 'Hay interés';
            }
        }
    }

    constructor() {
        super(name);
        this.applications = [];
    }

    public getReadableType(): string {
        let ret = '';
        switch (this.type) {
            case (PROPOSAL_TYPE.STUDENT_PROPOSAL):
                ret += 'Alumno';
            case PROPOSAL_TYPE.TEACHER_PROPOSAL:
                ret += 'Profesor';
        }
        if (this.accepted) {
            ret += ' aceptado';
        }
        return ret;
    }

}

export enum PROPOSAL_TYPE {
    STUDENT_PROPOSAL,
    TEACHER_PROPOSAL
}
