import {Component, Input, OnInit} from '@angular/core';
import {Teacher} from 'src/app/models/Teacher';
import {AuthService} from 'src/app/services/auth.service';
import {Milestone} from 'src/app/models/Milestone';
import {Router} from "@angular/router";
@Component({
    selector: 'app-teacher-project-list',
    templateUrl: './teacher-project-list.component.html',
    styleUrls: ['./teacher-project-list.component.scss']
})
export class TeacherProjectListComponent implements OnInit {

    @Input()
    teacher: Teacher;

    constructor(
      public auth: AuthService,
      public router: Router) {
    }

    ngOnInit(): void {
    }

    getPstatus(milestones: Milestone[]) {
        return milestones.reduce(
            (prev, curr) =>
                prev || (curr.doneDate === undefined && new Date(curr.dueDate).getTime() > new Date().getTime()), false) ? 'Atrasado' : 'En tiempo';
    }

    getLastUpdate(milestones: Milestone[]): string {
        return milestones.reduce(
            (prev, curr) =>
                (curr.doneDate !== undefined) ? (curr.doneDate as any) : prev, '');
    }

    get isMeOrAdmin() {
        return this.auth.isAdmin || this.auth.user?._id === this.teacher?._id;
    }

}
