<div class="container">
  <h1 mat-dialog-title *ngIf="data.title" [innerHtml]="data.title"></h1>

  <p>{{data.body}}</p>
  <mat-form-field class="">
    <mat-label>{{data.formControlName}}</mat-label>
    <input matInput [formControl]="resource">
  </mat-form-field>

  <div mat-dialog-actions>
    <button mat-stroked-button class="btn-blue" (click)="editResource()">Aceptar</button>
  </div>

  <img src="assets/icons/close.svg" alt="" (click)="this.dialogRef.close(false)" cdkFocusInitial>
</div>
