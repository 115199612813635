<div class="container" style="padding-bottom: 0px;">
  <img src="assets/icons/close.svg" alt="" (click)="this.dialogRef.close(false)" cdkFocusInitial>
  <h1 mat-dialog-title>Editar presentación</h1>
  <form [formGroup]="presentationForm" (submit)="addPresentation()">
    <p>
      <mat-form-field class="string-input">
        <mat-label>Link a la reunión</mat-label>
        <input matInput type="url" formControlName="meetingLink" autocomplete="false" />
      </mat-form-field>
    </p>
    <div class="options">
      <mat-form-field>
        <mat-label>Fecha *</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="presentationDate" autocomplete="false"
               readonly />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Hora *</mat-label>
        <input matInput formControlName="presentationTime" [ngxTimepicker]="endTimePicker" autocomplete="false"
               readonly />
        <ngx-material-timepicker #endTimePicker>
        </ngx-material-timepicker>
      </mat-form-field>
    </div>
    <p>
      <mat-slide-toggle [color]="'primary'" [checked]="data.project.onlinePresentation"
                        (change)="onPresentationModeChange($event)" #toggle><p style="margin-bottom: 0" [ngClass]="{color : toggle.checked}">{{data.project.onlinePresentation?'Virtual' : 'Presencial'}}</p></mat-slide-toggle>
    </p>
    <div *ngIf="!data.project.onlinePresentation" class="options">
      <mat-form-field>
        <mat-label>Aula</mat-label>
        <input matInput formControlName="classroom" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Sede</mat-label>
        <mat-select formControlName="campus">
          <mat-option *ngFor="let campus of campusOptions" [value]="campus">{{ campus }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="btn-wrapper">
      <button mat-button color="primary" type="submit">Confirmar</button>
    </div>
  </form>
</div>
