<div class="addEvent" [formGroup]="addMeetFG">
  <h2>Agregar reunión periódica</h2>
  <mat-form-field>
    <mat-label>Tipo de reunión</mat-label>
    <mat-select formControlName="type" required>
      <mat-option [value]="1">Semanal</mat-option>
      <mat-option [value]="2">Bi semanal</mat-option>
      <mat-option [value]="4">Mensual</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="">
    <mat-label>Location</mat-label>
    <input matInput formControlName="location" required>
  </mat-form-field>
  <mat-form-field class="minutes-gap-example">
    <input matInput placeholder="Gap with 5" formControlName="time" [ngxTimepicker]="picker" readonly>
    <ngx-material-timepicker #picker [minutesGap]="15"></ngx-material-timepicker>
  </mat-form-field>
  <button mat-raised-button color="primary" (click)="addEvent()">Agregar meta</button>
</div>
