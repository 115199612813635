<div class="container">
  <img src="assets/icons/close.svg" alt="" (click)="this.dialogRef.close(false)" cdkFocusInitial>
  <h1 mat-dialog-title>Cargar Dictamen</h1>
    <form [formGroup]="commentForm" (submit)="submitComment()">
        <p>
            <mat-form-field class="area-input">
                <mat-label>Jurado o Tutor</mat-label>
                <mat-select formControlName="tutor" (selectionChange)="onTutorSelected($event.value)">
                    <mat-option *ngFor="let tutor of data.members" [value]="tutor.jury">
                        <span *ngIf="tutor.name">{{tutor.name}} ({{tutor.jury}})</span>
                        <span *ngIf="!tutor.name">{{tutor.jury}}</span>
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="commentForm.controls.tutor.touched && commentForm.controls.tutor.invalid">
                    <span *ngIf="commentForm.controls.tutor.errors.required">Campo obligatorio</span>
                </mat-error>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field class="text-input">
                <mat-label>Dictamen</mat-label>
                <textarea style="resize: none;"
                        matInput
                        type="text"
                        formControlName="comment"
                        autocomplete="off"
                        rows="5"
                    >
                </textarea>
                <mat-error *ngIf="commentForm.controls.comment.touched && commentForm.controls.comment.invalid">
                    <span *ngIf="commentForm.controls.comment.errors.required">Campo obligatorio</span>
                    <span *ngIf="commentForm.controls.comment.errors.maxlength">Max. 250 caracteres</span>
                </mat-error>
            </mat-form-field>
        </p>
        <p class="btn-wrapper">
            <button mat-stroked-button class="btn-blue" type="submit">Calificar</button>
        </p>
    </form>
</div>
