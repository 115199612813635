import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EmailSenderComponent} from './components/dialogs/email-sender/email-sender.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxApiUtilsModule} from 'ngx-api-utils';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatChipsModule} from '@angular/material/chips';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatNativeDateModule} from '@angular/material/core';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {QuillModule} from 'ngx-quill';
import {ProjectStatusFilterPipe} from './pipes/project-status-filter.pipe';
import {ProposalImportanceSorterPipe} from './pipes/proposal-importance-sorter.pipe';
import {ProposalStatusPipe} from './pipes/proposal-status.pipe';
import {MeetingToStringPipe} from './pipes/meeting-to-string.pipe';
import {FromDatePipe} from './pipes/from-date.pipe';
import {UsernameOrEmailPipe} from './pipes/username-or-email.pipe';
import {ShowOrNAPipe} from './pipes/show-or-na.pipe';
import {ProjectApplicationEditorComponent} from './components/dialogs/project-application-editor/project-application-editor.component';
import {MilestoneAcceptComponent} from './components/dialogs/milestone-accept/milestone-accept.component';
import {CreateMeetComponent} from './components/dialogs/create-meet/create-meet.component';
import {CreateMilestoneComponent} from './components/dialogs/create-milestone/create-milestone.component';
import {ConfirmDialogComponent} from './components/dialogs/confirm-dialog/confirm-dialog.component';
import {TeacherEditorComponent} from './components/dialogs/teacher-editor/teacher-editor.component';
import {ProjectResourceEditorComponent} from './components/dialogs/project-resource-editor/project-resource-editor.component';
import {FinishedProjectsPipe} from './pipes/finished-projects.pipe';
import {OngoingProjectsPipe} from './pipes/ongoing-projects.pipe';
import {FinishProjectDialogComponent} from './components/dialogs/finish-project-dialog/finish-project-dialog.component';
import {ProjectAcceptedFilterPipe} from "./pipes/project-accepted-filter.pipe";
import { LaunchProjectDialogComponent } from './components/dialogs/launch-project-dialog/launch-project-dialog.component';
import { AuthorizationDialogComponent } from './components/dialogs/authorization-dialog/authorization-dialog.component';
import { JuryAccessDialogComponent } from './components/dialogs/jury-access-dialog/jury-access-dialog.component';


@NgModule({
    declarations: [
        EmailSenderComponent,
        ShowOrNAPipe,
        UsernameOrEmailPipe,
        FromDatePipe,
        MeetingToStringPipe,
        ProposalStatusPipe,
        ProposalImportanceSorterPipe,
        ProjectStatusFilterPipe,
        ProjectAcceptedFilterPipe,
        ConfirmDialogComponent,
        CreateMilestoneComponent,
        CreateMeetComponent,
        MilestoneAcceptComponent,
        ProjectApplicationEditorComponent,
        TeacherEditorComponent,
        ProjectResourceEditorComponent,
        FinishedProjectsPipe,
        OngoingProjectsPipe,
        FinishProjectDialogComponent,
        LaunchProjectDialogComponent,
        AuthorizationDialogComponent,
        JuryAccessDialogComponent,
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        NgxApiUtilsModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatButtonToggleModule,
        MatTabsModule,
        MatTableModule,
        MatCheckboxModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatChipsModule,
        MatTooltipModule,
        MatNativeDateModule,
        NgxMaterialTimepickerModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatSortModule,
        MatSnackBarModule,
        MatAutocompleteModule,
        QuillModule.forRoot()
    ],
    exports: [
        EmailSenderComponent,
        ShowOrNAPipe,
        UsernameOrEmailPipe,
        FromDatePipe,
        MeetingToStringPipe,
        ProposalStatusPipe,
        ProposalImportanceSorterPipe,
        ProjectStatusFilterPipe,
        ProjectAcceptedFilterPipe,
        ConfirmDialogComponent,
        CreateMilestoneComponent,
        CreateMeetComponent,
        MilestoneAcceptComponent,
        ProjectApplicationEditorComponent,
        TeacherEditorComponent,
        ProjectResourceEditorComponent,
        FinishedProjectsPipe,
    ]
})
export class SharedModule {
}
