import {Project} from './Project';
import {User} from './User';

export class Teacher extends User {

    title: string;
    bio: string;
    area: string;
    tags: string[];
    project: Project;
    projects: Project[];
    juryProjects: Project[];
    jury?: boolean;


    constructor(name: string, area: string, bio: string, jury: boolean) {
        super();
        this.name = name;
        this.area = area;
        this.bio = bio;
        this.projects = [];
        this.juryProjects = [];
        this.jury = jury;
    }

}
