<div class="header">
  <!-- Left side (User name) -->
  <div class="left" *ngIf="auth.user">
    <h6 *ngIf="!auth.accessByToken && !auth.isTeacher"
        style="margin-bottom: 0px; text-transform: capitalize;">
      {{ auth.user?.firstName.toLowerCase() }} {{ auth.user?.lastName.toLowerCase() }}
    </h6>
    <h6 *ngIf="auth.isTeacher && !auth.accessByToken" style="margin-bottom: 0px; text-transform: capitalize;">
      {{ auth.user?.firstName.toLowerCase() }} {{ auth.user?.lastName.toLowerCase() }}
    </h6>
    <div *ngIf="auth.accessByToken && !auth.isAdmin">
      <p class="role">Jurado</p>
    </div>
    <div *ngIf="auth.type==0">
      <p class="role">Alumno</p>
    </div>
    <div *ngIf="auth.type==1 && !auth.accessByToken">
      <p class="role">Profesor</p>
    </div>
    <div *ngIf="auth.type==2">
      <p class="role">Administrador</p>
    </div>
  </div>

  <!-- empty div to keep logo centered -->
  <div class="left" *ngIf="!auth.user"></div>

  <!-- Center (Logo) -->
  <div class="center">
    <a class="navbar-logo" href="#">
      <img id="logo" src="assets/icons/thesis-logo.png" style="width: 100px;">
    </a>
  </div>

  <!-- Right side (Login/Logout button) -->
  <div class="right">

    <ng-container *ngIf="auth.user">
      <button mat-stroked-button class="btn-red" *ngIf="!auth.accessByToken" (click)="auth.signOut()">Cerrar sesión</button>
    </ng-container>
    <ng-container *ngIf="!auth.user">
      <button mat-stroked-button class="btn-blue" (click)="dialogService.openLoginDialog()">Iniciar sesión</button>
    </ng-container>
  </div>
</div>
