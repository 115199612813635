<div class="container">
    <mat-card class="card mat-elevation-z8">
        <img src="assets/icons/itba_white.png" alt="" style="margin: 20px 0">
        <mat-card-content class="card-content" style="margin-top: 10px;">
            <button class="disappear-mobile" (click)="signIn()" mat-button style="padding: 1rem;">
                <mat-icon style="width: 50px; height:30px" svgIcon="logo"></mat-icon>
                Iniciar Sesión con cuenta Google del ITBA
            </button>
            <button class="disappear-medium-desktop disappear-desktop" (click)="auth.signInWithGoogle()" mat-button
                    style="padding: 1rem;">
                <mat-icon style="width: 50px; height:30px" svgIcon="logo"></mat-icon>
                Iniciar Sesión
            </button>
            <mat-spinner [style.display]="loading ? 'block' : 'none'"></mat-spinner>
        </mat-card-content>
    </mat-card>
</div>
