import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TeacherComponent} from './components/teacher/teacher.component';
import {AdminGuard} from './guards/admin.guard';
import {StudentComponent} from './components/student/student.component';
import {StudentGuard} from './guards/student.guard';
import {IntroComponent} from './components/student/intro/intro.component';
import {ChoiceComponent} from './components/student/choice/choice.component';
import {ProjectDetailComponent} from './components/project/project-detail/project-detail.component';
import {ProjectEditorComponent} from './components/project/project-editor/project-editor.component';
import {TeacherGuard} from './guards/teacher.guard';
import {ProjectFeedComponent} from './components/project/project-feed/project-feed.component';
import {TeacherDashboardComponent} from './components/teacher/teacher-dashboard/teacher-dashboard.component';
import {NotStudentGuard} from './guards/not-student.guard';
import {TeacherProfileComponent} from './components/teacher/teacher-profile/teacher-profile.component';
import {RedirectorGuard} from './guards/redirector.guard';
import {StudentProfileComponent} from "./components/student/student-profile/student-profile.component";


const routes: Routes = [
  {
    path: '',
    component: ChoiceComponent,
    pathMatch: 'full',
    canActivate: [RedirectorGuard]
  },
  {path: 'project/:id', component: ProjectFeedComponent},
  {path: 'proposal/:id', component: ProjectDetailComponent},
  {path: 'proposal/:id/edit', component: ProjectEditorComponent, canActivate: [NotStudentGuard]},
  {path: 'project/:id/edit', component: ProjectEditorComponent, canActivate: [NotStudentGuard]},
  {path: 'proposal', component: ProjectEditorComponent, canActivate: [NotStudentGuard]}, // ?
  {path: 'teachers/:id', component: TeacherProfileComponent},
  {
    path: 'alu', component: StudentComponent,
    children: [
      {path: '', component: ChoiceComponent, pathMatch: 'full'},
      {path: 'proposal', component: ProjectEditorComponent},
      {path: 'proposal/:id', component: ProjectDetailComponent},
      {path: 'proposal/:id/edit', component: ProjectEditorComponent},
      {path: 'buenas', component: IntroComponent},
    ],
    canActivate: [StudentGuard]
  },
  {
    path: 'teacher', component: TeacherComponent, children: [
      {path: '', component: ChoiceComponent, pathMatch: 'full'},
      {path: 'home', component: TeacherDashboardComponent, pathMatch: 'full'},
    ], canActivate: [TeacherGuard]
  },
  {
    path: 'admin',
    canLoad: [AdminGuard],
    canActivate: [AdminGuard],
    loadChildren: () => import('./administrator/administrator.module').then(m => m.AdministratorModule)
  },
  {
    path: 'users/:id',
    component: StudentProfileComponent,
    canLoad: [AdminGuard],
    canActivate: [AdminGuard],
  },
  {path: '**', redirectTo: ''},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
