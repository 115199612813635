import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ApiService} from "../../../../services/api.service";
import {AuthService} from "../../../../services/auth.service";
import {Proposal} from "../../../../models/Proposal";

@Component({
    selector: 'app-public-proposals',
    templateUrl: './public-proposals.component.html',
    styleUrls: ['./public-proposals.component.scss']
})
export class PublicProposalsComponent implements OnInit {
    public proposals: Array<Proposal>;
    public currentSemester: number[];
    loaded: boolean;

    constructor(public dialog: MatDialog,
                private api: ApiService,
                public auth: AuthService) {
    }

    ngOnInit(): void {
        this.loaded = false;
        this.api.getUnstartedProjects().subscribe((p => {
            this.proposals = p;
            this.api.currentSemester.subscribe((data) => {
                this.currentSemester = data;
            });
        }));
        this.loaded = true;
    }
    
    getCurrentSemester(){
      return this.currentSemester.slice(1,3) + "C " + this.currentSemester.slice(0,1)
    }

}
