import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'fromDate'
})
export class FromDatePipe implements PipeTransform {

  transform(value: unknown[], ...args: unknown[]): unknown {
    if (value) {
      if (args[1]) {
        return value;
      } else {
        return value.filter(a => {
            return new Date(a[args[0] as string]).getTime() > new Date().getTime() || !a['doneDate'];
          }
        );
      }
    } else
      return null;
  }

}
