import { Pipe, PipeTransform } from '@angular/core';
import { User } from 'src/app/models/User';

@Pipe({
  name: 'usernameOrEmail'
})

export class UsernameOrEmailPipe implements PipeTransform {
  
  transform(user: User, ...args: unknown[]): unknown {
    if(user?.firstName){
      const firstName = capitalizeFirstLetter(user.firstName);
      const lastName = capitalizeFirstLetter(user.lastName);      
      return `${firstName} ${lastName}`;
    }else{
      return user?.email.toLowerCase();
    }
    // return user?.firstName ? "${user.firstName} ${user.lastName}".toLowerCase()  : user?.email.toLowerCase();
  }
}

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
