import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ApiService} from 'src/app/services/api.service';
import {ProjectApplication} from 'src/app/models/ProjectApplication';
import {FormControl, Validators} from '@angular/forms';
import {Proposal} from 'src/app/models/Proposal';
import {Student} from 'src/app/models/Student';
import {ConfirmDialogComponent} from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import {AuthService} from 'src/app/services/auth.service';
import errors from 'src/app/utils/errorCodes';

@Component({
    selector: 'app-project-application-editor',
    templateUrl: './project-application-editor.component.html',
    styleUrls: ['./project-application-editor.component.scss']
})
export class ProjectApplicationEditorComponent implements OnInit {

    public groupPartners: FormControl[] = [];
    public priorityControl = new FormControl(-1, [Validators.min(1)]);
    public canEdit = false;

    private projectApplication: ProjectApplication;
    private proposal: Proposal;

    allStudents: Student[];

    public isPublicApplication = false;

    controls = [this.priorityControl];
    controlNames = {
        'priority': this.priorityControl,
    };

    constructor(
        public dialogRef: MatDialogRef<ProjectApplicationEditorComponent>,
        public api: ApiService,
        public auth: AuthService,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: { proposal: Proposal, pa: ProjectApplication }
    ) {
        this.proposal = data.proposal;
        this.projectApplication = data.pa;
    }

    ngOnInit(): void {
        for (let i = 0; i < this.proposal.maxStudents; i++) {
            if (i === 0)
                this.groupPartners.push(new FormControl(this.projectApplication.students[i]?.email || null, [Validators.email, Validators.required]));
            else
                this.groupPartners.push(new FormControl(this.projectApplication.students[i]?.email || null, [Validators.email]));
            this.controls.push(this.groupPartners[i]);
            this.controlNames['partner' + i] = this.groupPartners[i];
        }
        this.priorityControl.setValue(this.projectApplication.priority);
        this.isPublicApplication = this.projectApplication.isPublic;
        this.api.getStudents().subscribe(
            data => this.allStudents = data.filter(s => s._id !== this.auth.user._id && !s.project)
        );
    }

    public editGroup() {
        if (this.groupPartners.every((a) => !a.invalid) && !this.priorityControl.invalid) {
            let partnerEmails = [];
            this.groupPartners.forEach(gp => {
                if (gp.value) {
                    partnerEmails.push(gp.value);
                }
            });
            let title: string;
            let body: string;
            title = 'Editar applicación';
            body = 'Estás editando el grupo con los siguientes integrantes:';
            this.groupPartners.forEach(a => {
               if (a !== null && a.value!=null ) {
                  body += '\n ' + a.value
                }
            }
            );
            let ref = this.dialog.open(ConfirmDialogComponent, {
                data: {
                    title,
                    body
                }
            });

            ref.afterClosed().subscribe(data => {
                if (data) {
                    this.api.editProjectApplication(this.projectApplication._id, partnerEmails, this.isPublicApplication, this.priorityControl.value)
                        .subscribe((data: ProjectApplication) => {
                            const app = new ProjectApplication();
                            this.projectApplication.isPublic = data.isPublic;
                            this.projectApplication.priority = data.priority;
                            this.projectApplication.students = partnerEmails.map(email => ({email} as Student));
                            this.dialogRef.close(true);
                        });
                }
            });
        } else {
            this.priorityControl.markAsDirty();
            this.priorityControl.markAsTouched();
            this.groupPartners.forEach(a => {
                a.markAsDirty();
                a.markAsTouched();
            });
        }
    }

    getErrorMessage(input: string) {
        const errorsAux = this.controlNames[input].errors;
        if (errorsAux) {
            const keys = Object.keys(this.controlNames[input].errors);
            if (!keys || keys.length === 0) return '';
            return errors[Object.keys(this.controlNames[input].errors)[0]];
        } else {
            return '';
        }
    }

}
