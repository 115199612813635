<div class="actions">
  <div style="display: flex; justify-content: right; padding-right: 12px;">
    <mat-icon (click)="this.dialogRef.close(false)" class="cursor">close</mat-icon>
  </div>
  <h1 mat-dialog-title>Editar grupo</h1>
  <div class="options appear">
    <mat-form-field>
      <mat-label>Prioridad</mat-label>
      <mat-select [formControl]="priorityControl" required>
        <mat-option [value]="-1">--</mat-option>
        <mat-option [value]="1">1 - Ideal</mat-option>
        <mat-option [value]="2">2 - Alta</mat-option>
        <mat-option [value]="3">3 - Media</mat-option>
      </mat-select>
      <mat-error *ngIf="priorityControl.invalid">{{getErrorMessage('priority')}}</mat-error>
    </mat-form-field>
    <mat-form-field *ngFor="let partner of groupPartners; let i = index">
      <input
        matInput
        placeholder="Email del compañero de grupo"
        [formControl]="partner"
        type="text"
        aria-label="Number"
        [matAutocomplete]="auto">
      <mat-error for="title" class="form-error">
        {{getErrorMessage('partner' + i)}}</mat-error>
    </mat-form-field>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let student of allStudents" [value]="student.email">
        {{student.email}}
      </mat-option>
    </mat-autocomplete>
    <mat-slide-toggle [(ngModel)]="isPublicApplication">Es abierta</mat-slide-toggle>
  </div>
  <div style="text-align: center;">
    <button mat-stroked-button class="btn-blue" (click)="editGroup()">Editar grupo</button>
  </div>
</div>
