<div class="addEvent" [formGroup]="addEventFG">
  <img src="assets/icons/close.svg" alt="" (click)="this.dialogRef.close(false)" cdkFocusInitial>
  <h1 mat-dialog-title>Agregar meta</h1>
  <mat-form-field>
    <mat-label>Tipo de meta</mat-label>
    <mat-select formControlName="type" required>
      <mat-option [value]="0" *ngIf="auth.isAdmin">Administrativa</mat-option>
      <mat-option [value]="1">Objetivo importante</mat-option>
      <mat-option [value]="2">Checkpoint</mat-option>
      <mat-option [value]="3">Entrega de Informe</mat-option>
      <mat-option [value]="4">Defensa</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="">
    <mat-label>Nombre de la meta</mat-label>
    <input matInput formControlName="name" required>
    <mat-error for="name" class="form-error">
      {{getErrorMessage('name')}}</mat-error>
  </mat-form-field>
  <mat-form-field class="">
    <mat-label>Descripción</mat-label>
    <textarea matInput formControlName="description"></textarea>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Fecha de entrega de la meta</mat-label>
    <input matInput [matDatepicker]="picker" formControlName="dueDate" required>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error for="dueDate" class="form-error">
      {{getErrorMessage('dueDate')}}</mat-error>
  </mat-form-field>
  <button mat-stroked-button class="btn-blue" (click)="addEvent()">Confirmar</button>
</div>
