<div class="main-container">
    <div class="filter-container" style="padding-left: 50px">
        <mat-form-field>
          <input matInput placeholder="Buscar tutor" (input)="onSearchInputChanged($event.target.value)" style="padding-bottom: 5px">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
    </div>
    <div class="cards-container">
        <mat-card *ngFor="let teacher of filteredTutors" class="card" [routerLink]="['/teachers', teacher.email]" style="cursor: pointer; width: 100px; height: 400px;">
            <div class="img-container">
                <img [src]="teacher?.picture || 'assets/icons/blank-profile-picture.png'" alt="">
            </div>
            <mat-card-title style="margin-bottom: 0; padding-bottom: 0">
                <h4 style="text-align: center; margin: 30px 0 0 0;padding-bottom: 10px;">{{teacher?.title}} {{teacher?.firstName}} {{teacher?.lastName}}</h4>
            </mat-card-title>
            <div>
              <b>Email: </b>
                <span class="text" *ngIf="teacher?.email">{{teacher?.email}}</span>
            </div>
            <div style="margin-top: 10px;">
                <p class="text" style="overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;" *ngIf="teacher?.area"><b>Área:</b> {{teacher?.area}}</p>
            </div>
            <mat-card-actions class="chip-list">
                <mat-chip-list #chipList class="chips">
                    <mat-basic-chip *ngFor="let tag of teacher?.tags; let i = index">
                        {{tag}}
                    </mat-basic-chip>
                </mat-chip-list>
            </mat-card-actions>
        </mat-card>
    </div>
</div>


