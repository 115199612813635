import { Component, Input, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Project, STATE } from "../../../models/Project";
import { EmailDialogService } from "../../../shared/services/email-dialog.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { GradeEditorComponent } from "./grade-editor/grade-editor.component";
import { PresentationEditorComponent } from "./presentation-editor/presentation-editor.component";
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { USER_TYPE } from 'src/app/models/User';
import { JuryEditorComponent } from "./jury-editor/jury-editor.component";
import { Teacher } from "../../../models/Teacher";
import { JuryCommentComponent } from "./jury-comment/jury-comment.component";
import { Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import {
  AuthorizationDialogComponent
} from "../../../shared/components/dialogs/authorization-dialog/authorization-dialog.component";
import { ConfirmationSenderComponent } from 'src/app/components/project/project-card/confirmation-sender/confirmation-sender.component';
import { AdminService } from 'src/app/administrator/services/admin.service';
import { JuryAccessDialogComponent } from 'src/app/shared/components/dialogs/jury-access-dialog/jury-access-dialog.component';
import { EmailData, EmailType } from 'src/app/models/EmailType';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from "moment";

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss']
})

export class ProjectCardComponent implements OnInit {

  studentGradeColumns: string[] = ['alumno', 'nota', 'acta'];
  juryCommentColumns: string[] = ['jurado', 'dictamen'];

  @Input() project: Project;
  public state: string;
  public graded: boolean;
  public grade = 0;
  public documentationStatus: number;
  public userType: USER_TYPE;
  public closed: boolean;
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  stepOneCompleted: boolean = false;
  stepTwoCompleted: boolean = false;
  stepThreeCompleted: boolean = false;
  stepFourCompleted: boolean = false;
  stepFiveCompleted: boolean = false;
  currentStepIndex: number = 0

  adminComment: string;
  teachers: Teacher[];
  localJuryAccessToken: string;

  @ViewChild('stepper') stepper: MatStepper;

  displayAdd = false;

  generateRandomToken(length: number): string {
    // Use Web Crypto API for generating secure random numbers
    const randomValues = new Uint8Array(length);
    window.crypto.getRandomValues(randomValues);

    // Convert the array to a hexadecimal string
    return Array.from(randomValues)
      .map(byte => byte.toString(16).padStart(2, '0'))
      .join('');
  }

  constructor(private emailDialog: EmailDialogService,
    private dialog: MatDialog,
    private apiService: ApiService,
    public auth: AuthService,
    private _formBuilder: FormBuilder,
    private _location: Location,
    private cdr: ChangeDetectorRef,
    private adminService: AdminService,
    private _snackBar: MatSnackBar,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  getBaseUrl(): string {
    return this.document.location.origin;
  }

  ngOnInit(): void {
    this.state = STATE[this.project.state];
    //this.graded = this.project.students.some(s => s.projectGrade && s.projectGrade.grade);
    this.graded = this.project.state === STATE.FINISHED && this.project.closed;
    
this.project.students?.forEach(s => {
      const grade = s.projectGrade?.grade || 0;
      this.grade += grade;
    });
    this.grade /= this.project.students?.length;
    this.documentationStatus = this.project.documentationStatus;
    this.userType = this.auth.user.type;
    this.apiService.getTeachers().subscribe(
      data => this.teachers = data
    );
    this.closed = this.project.closed;
    this.adminComment = this.project.comment || '';

    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });

    this.juryAccess();
    this.localJuryAccessToken = this.project.juryToken ? this.project.juryToken : "";


    if (this.project.jury?.length > 0) {
      this.stepOneCompleted = true;
    }
    if (this.project.presentationDate) {
      this.stepTwoCompleted = true;
      this.currentStepIndex = 1;
    }
    if (this.project.confirmedPresentation) {
      this.stepThreeCompleted = true;
      this.currentStepIndex = 2;
    }
    this.stepFourCompleted = true;
    for (let i = 0; i < this.project.students.length; i++) {
      if (!this.project.students[i].projectGrade) {
        this.stepFourCompleted = false;
        break;
      }
    }
    if (this.stepFourCompleted) {
      this.currentStepIndex = 3;
    }
    if (this.project.jury?.length > 0 && (new Set(this.project.juryComments).size) == new Set([
      ...this.project.jury.map(member => member.jury),
      ...this.project.tutors.map(member => member.email)
    ]).size) {
      this.stepFiveCompleted = true;
      this.currentStepIndex = 4;
    }

  }


  juryAccess() {
    if (!this.project.juryToken) {
      const token = this.generateRandomToken(32);
      this.localJuryAccessToken = token;
      this.adminService.saveJuryToken(this.project._id, token).subscribe(
        response => {
          console.log('POST request successful:', response);
        },
        error => {
          console.error('POST request failed:', error);
        }
      );
    }

  }

  isTutor() {
    return this.project.tutors.some(t => t.email === this.auth.user.email);
  }

  openEmailSender() {
    const recipients = {};
    this.project.students.forEach(s => {
      recipients[s.email] = s;
    });
    this.project.tutors.forEach(s => {
      recipients[s.email] = s;
    });
    this.emailDialog.openBasicEmail(Object.values(recipients));
  }

  openStudentsAuth() {
    this.dialog.open(AuthorizationDialogComponent, {
      data: {
        title: 'Pasos a seguir:',
        paso1: '1. Ingresá a este ',
        link: 'https://docs.google.com/document/d/1dgtRkJSiWV7G1LQj08eAZlNmn8xi5Zs7qm89C2mkpD4/edit',
        paso2: '2. Realizá una copia del documento y completalo con la información del grupo.',
        paso3: '3. Subí el link del documento una vez completado:',
        project: this.project,
        resourceName: 'studentsAuth',
        formControl: new FormControl(this.project.studentsAuth, [this.InvalidGDOCValidator]),
        formControlName: 'Link al Google Doc'
      }
    })
  }

  InvalidGDOCValidator(control: FormControl) {
    if (!/^(http:\/\/|https:\/\/)?docs\.google\.com\/document\/d\//i.test(control.value)) {
      return { invalidURL: true };
    }
    return null;
  }
  openTeachersAuth() {
    this.dialog.open(AuthorizationDialogComponent, {
      data: {
        title: 'Pasos a seguir:',
        paso1: '1. Ingresá a este ',
        link: 'https://docs.google.com/document/d/1KCuhxRacKBDM3fLJbMqcDC3T31B4MlvHauSPkDos58M/edit',
        paso2: '2. Realizá una copia del documento y completá los campos.',
        paso3: '3. En caso de la partición de jurados externos a la institución compartirles mediante drive dicha copia.',
        paso4: '4. Subí el link del documento una vez completado:',
        project: this.project,
        resourceName: 'tutorsJuryAuth',
        formControl: new FormControl(this.project.tutorsJuryAuth, [this.InvalidGDOCValidator]),
        formControlName: 'Link al Google Doc'
      }
    })
  }

  openGradeDialog() {
    if (this.stepThreeCompleted) {
      const dialogRef: MatDialogRef<GradeEditorComponent> = this.dialog.open(GradeEditorComponent, {
        data: {
          project: this.project
        },

      });
      dialogRef.afterClosed().subscribe(() => {
        this.stepFourCompleted = true;
        for (let i = 0; i < this.project.students.length; i++) {
          if (!this.project.students[i].projectGrade) {
            this.stepFourCompleted = false;
            break;
          }
        }
      });


    }
  }

  openSnackBar() {
    this._snackBar.open('Debe esperar a que el administrador confirme la presentación para cargar la nota', 'Close', {
      duration: 3000, // Duration in milliseconds
      horizontalPosition: 'center',
      verticalPosition: 'bottom'
    });
  }

  openPresentationDialog() {
    if (this.stepOneCompleted) {
      const dialogRef: MatDialogRef<PresentationEditorComponent> = this.dialog.open(PresentationEditorComponent, {
        data: {
          project: this.project
        },
        // height: '320px',
        width: '500px',
      });
      dialogRef.afterClosed().subscribe(() => {
        if (this.project.presentationDate) {
          this.stepTwoCompleted = true;
        }
      });
    }
  }

  isExternJury() {
    return this.project.jury.filter(jury => jury.jury && !jury.jury.endsWith('@itba.edu.ar')).map(jury => jury.jury).length != 0;
  }

  openJuryCommentDialog() {
    if (this.stepFourCompleted) {

      const members: any[] = [];

      if (this.auth.accessByToken) {
        this.project.tutors.forEach(tutor => {
          if (!tutor.email.endsWith('@itba.edu.ar')) { // Check if the email ends with '@aaa.bb'
            members.push({
              jury: tutor.email,
              name: tutor.firstName ? tutor.firstName + ' ' + tutor.lastName : null
            });
          }
        });
        this.project.jury.forEach(jury => {
          if (!jury.jury.endsWith('@itba.edu.ar')) {
            members.push({
              jury: jury.jury,
              name: jury.name
            });
          }
        })
      } else if (this.auth.isTeacher) {
        this.project.tutors.forEach(tutor => {
          if (tutor.email === this.auth.user.email) {
            members.push({
              jury: tutor.email,
              name: tutor.firstName ? tutor.firstName + ' ' + tutor.lastName : null,
            });
          }
        });
        this.project.jury.forEach(jury => {
          if (jury.jury === this.auth.user.email) {
            members.push({
              jury: jury.jury,
              name: jury.name
            });
          }
        });
      } else if (this.auth.isAdmin) {
        this.project.tutors.forEach(tutor => {
          members.push({
            jury: tutor.email,
            name: tutor.firstName ? tutor.firstName + ' ' + tutor.lastName : null,
          });
        });
        members.push(...this.project.jury);

      }


      const dialogRef: MatDialogRef<JuryCommentComponent> = this.dialog.open(JuryCommentComponent, {
        data: {
          members,
          project: this.project
        },
        // height: '400px',
        width: '500px',
      });
      dialogRef.afterClosed().subscribe(() => {

        if (this.project.jury.length > 0 && (new Set(this.project.juryComments).size) == (new Set([
          ...this.project.jury.map(member => member.jury),
          ...this.project.tutors.map(member => member.email)
        ]).size)) {
          this.stepFiveCompleted = true;
        }
      });
    }
  }


  openConfirmPresentationDialog() {
    if (this.stepTwoCompleted) {
      const recipients = {};
      const juryRecipients = [];
      this.project.students?.forEach(s => {
        recipients[s.email] = s;
      });
      this.project.tutors?.forEach(s => {
        recipients[s.email] = s;
      });
      this.project.jury?.forEach(jury => {
        juryRecipients.push(jury);
      });
      const dialogRef: MatDialogRef<ConfirmationSenderComponent> = this.emailDialog.openConfirmPresentationEmail(Object.values(recipients), juryRecipients, this.project, this.auth.user);

      dialogRef.afterClosed().subscribe(() => {
        if (dialogRef.componentInstance.getUpdateTemplateValue()) {
          this.adminService.updateConfirmPresEmailTemplate(dialogRef.componentInstance.getEmailBodyValue());
        }
        if (this.project.confirmedPresentation) {
          this.stepThreeCompleted = true;
        }
      });
    }
  }

  removeJuror(juror: string, index: number): void {
    const copiedJury = [...this.project.jury];
    copiedJury.splice(index, 1);
    this.apiService.editJury(this.project._id, copiedJury).subscribe(
      () => {
        this.project.jury.splice(index, 1);
      }
    );
  }

  changeDocumentationStatus(selectedValue: number) {
    this.apiService.setDocumentationStatus(this.project._id, selectedValue, this.auth.user);
  }

  showAddJury() {
    this.displayAdd = true;
  }

  openJuryDialog() {
    const dialogRef: MatDialogRef<JuryEditorComponent> = this.dialog.open(JuryEditorComponent, {
      data: {
        project: this.project,
        teachers: this.teachers
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      if (this.project.jury.length > 0) {
        this.stepOneCompleted = true;
      }
    });

  }

  openJuryAccessDialog() {
    if (this.project.juryToken === undefined) {
      this.juryAccess();
    }
    const baseUrl = this.getBaseUrl();
    const externalLink = baseUrl + '/project/' + this.project._id + '?token=' + this.localJuryAccessToken;
    const internalLink = baseUrl + '/project/' + this.project._id;
    
    moment.locale('es');
    const date = moment(this.project.presentationDate).format('dddd LL');
    var pre = "Recuerde que la presentación del proyecto se llevará a cabo el día " + date + " a las " + this.project.presentationTime + ".\n\n";
    var info;
    if (this.project.onlinePresentation) {
      info = pre + 'La misma se hará de manera virtual a través del siguiente link: ' + this.project.meetingLink + "\n\n";
    } else {
      var aula = undefined;
      if (this.project.classroom !== null && this.project.campus !== null){
        aula = this.project.classroom + this.project.campus;
      }
      if (this.project.meetingLink) {
        if(aula === undefined){
          info = pre + "La misma se hará de manera presencial y estará disponible mediante el siguiente link: " + this.project.meetingLink + "\n\n";
        }else{
          info = pre + "La misma se hará de manera presencial en el aula " + aula +  " y estará disponible mediante el siguiente link: " + this.project.meetingLink + "\n\n";

        }
      } else {
        info = pre + 'La misma se hará de manera presencial en el aula ' + aula + "\n\n";
      }
    }
    info = info + "Recuerde que luego de la presentación debe cargar su dictamen en la plataforma thesis.it.itba.edu.ar .";
    const info_plus_links = info + "\n\nSi es parte de la institución ITBA ingrese con su cuenta al link: " + internalLink + "\n\nSi no es parte de la institución ingrese mediante el link: " + externalLink;

    const ref = this.dialog.open(JuryAccessDialogComponent, {
      data: {
        title: 'Acceso a tutores y jurados',
        body: 'Se enviará el siguiente email a los tutores y jurados del proyecto:',
        info: info,
        externalLink: externalLink,
        internalLink: internalLink
      }
    });
    ref.afterClosed().subscribe(data => {
      if (data) {

        const emailComment = ref.componentInstance?.getEmailBodyValue();
        const checkboxValue = ref.componentInstance?.getCheckboxValue();
        const updated_template = ref.componentInstance?.getUpdateTemplateValue();
        if (updated_template) {
          this.adminService.updateJuryAccessEmailTemplate(emailComment);
        }
        if (checkboxValue) {

          if (this.project.jury.length !== 0) {
            const emails = this.project.jury.map(jury => jury.jury).concat(this.project.tutors.map(tutor => tutor.email));
            this.adminService.sendJuryEmail(emails, info_plus_links,emailComment, externalLink, internalLink, this.project);
          }
        }

      }
    });
  }



  onPresentationVisibilityChange(event: any) {
    this.project.publicPresentation = event.checked;
    this.apiService.setPresentationVisibility(this.project._id, event.checked);
  }

  tooltip(): string {
    return this.project.publicPresentation ? 'Pública' : 'Privada';
  }

  editComment($event: Event) {
    // @ts-ignore
    const comment = $event.target.value;
    this.apiService.editProjectComment(this.project, comment).subscribe(() => {
    });
  }

  back() {
    this._location.back();
  }

  private libAuthDoc(response: ArrayBuffer): void {
    const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'Autorizacion_Biblioteca.docx';
    link.click();
  }

  downloadLibAuthDoc(): void {
    // Call the API service function to get the document
    this.apiService.getStudentLibraryAuthDoc(this.project._id).subscribe(
      (response: ArrayBuffer) => {
        this.libAuthDoc(response); // Assuming the response contains the base64-encoded document
      },
      (error) => {
        console.error(error);
      }
    );
  }

  private recordDoc(response: ArrayBuffer): void {
    const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'Acta_Final.docx';
    link.click();
  }

  downloadRecordDoc(): void {
    // Call the API service function to get the document
    this.apiService.getRecordDoc(this.project._id).subscribe(
      (response: ArrayBuffer) => {
        this.recordDoc(response); // Assuming the response contains the base64-encoded document
      },
      (error) => {
        console.error(error);
      }
    );
  }

  capitalizeName(name: string) {
    if(name!=null){
      let capitalized = '';
      for (let i = 0; i < name.length; i++) {
        if (i === 0 || (name[i - 1] === ' ' && name[i] !== ' ')) {
          capitalized += name[i].toUpperCase();
        } else {
          capitalized += name[i].toLowerCase();
        }
      }
      return capitalized;
    }
  }

  closeProject(): void {
    const d = this.dialog.open(ConfirmDialogComponent,
      {
        data: {
          title: "Cerrar Proyecto",
          body: `¿Está seguro de que quiere cerrar el proyecto ${this.project.title}?\nUna vez cerrado no se podrán realizar cambios.`
        }
      });


    d.afterClosed().subscribe(r => {
      if (r) {
        this.apiService.closeProject(this.project._id).subscribe((data) => {
          this.project.closed = true;
          this.closed = true;
        });
      }
    });

  }



}
