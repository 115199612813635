import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {LoginComponent} from '../components/login/login.component';

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    constructor(private dialog: MatDialog) {
    }

    openLoginDialog() {
        return this.dialog.open(LoginComponent, {data: {}});
    }
}
