import { Component, Inject, OnInit } from '@angular/core';
import { ApiService } from "../../../../services/api.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Project } from "../../../../models/Project";
import { FormControl } from "@angular/forms";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";

@Component({
  selector: 'app-authorization-dialog',
  templateUrl: './authorization-dialog.component.html',
  styleUrls: ['./authorization-dialog.component.scss']
})
export class AuthorizationDialogComponent implements OnInit {

  resource: FormControl;
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    public api: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string, paso1: string, project: Project,
      link: string, paso2: string, paso3: string,
      paso4: string,
      resourceName: string, formControl: FormControl, formControlName: string
    }
  ) {
    this.resource = data.formControl;
  }

  ngOnInit(): void {
  }

  editResource(): void {
    this.resource.markAsTouched();
    if (this.resource.invalid) {
      return;
    }
    let p = {};
    p[this.data.resourceName] = this.resource.value;
    this.api.editProject(this.data.project._id, p).subscribe((p) => {
      this.data.project[this.data.resourceName] = this.resource.value;
      this.dialogRef.close(true);
    });
  }

  copyToClipboard(): void {
    navigator['clipboard'].writeText(this.resource.value).then().catch(err => {
      console.log('Something went wrong', err);
    }
    );
  }


}
