import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  googleLogoURL = 'https://raw.githubusercontent.com/fireflysemantics/logo/master/Google.svg';
  loading = false;

  constructor(
    public auth: AuthService,
    public matIconRegistry: MatIconRegistry,
    public domSanitizer: DomSanitizer,
    public router: Router,
    public dialog: MatDialogRef<LoginComponent>) {
    this.matIconRegistry.addSvgIcon('logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl(this.googleLogoURL));
  }

  ngOnInit() {
    this.auth.redirectLoggedUser();
  }

  signIn() {
    this.auth.signInWithGoogle().then(() => {
      this.dialog.close();
    },
      err => {
        console.log(err);
      }
    );
  }

}
