import { Component, Input, OnInit } from '@angular/core';
import { Proposal, PROPOSAL_TYPE } from 'src/app/models/Proposal';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { Student } from 'src/app/models/Student';
import { ActivatedRoute, Router } from '@angular/router';
import { Teacher } from 'src/app/models/Teacher';
import { USER_TYPE } from 'src/app/models/User';

let routes = ['profile', 'proposals', 'tutors', 'presentations',];

@Component({
  selector: 'app-choice',
  templateUrl: './choice.component.html',
  styleUrls: ['./choice.component.scss']
})
export class ChoiceComponent implements OnInit {

  public unstartedProjects: Array<Proposal>;
  public currentSemester: number[];
  tabIndex = 0;
  teacher: Teacher;
  constructor(
    public dialog: MatDialog,
    private api: ApiService,
    public auth: AuthService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    if (this.auth.user) {
      this.api.getTeacher(this.auth.user.email).subscribe(
        data => {
          this.teacher = data;
        }
      );
    }
    if (this.auth.user && this.auth.user.type === USER_TYPE.student){
      routes = ['proposals', 'tutors', 'presentations', 'applications'];
    }

    this.api.getUnstartedProjects().subscribe((p => {
      this.unstartedProjects = p.filter(p => p.type === PROPOSAL_TYPE.TEACHER_PROPOSAL);
      if (this.auth.user) {
        this.auth.getMe().subscribe(u => {
          (this.auth.user as Student).proposals?.forEach((p) => {
            if (!this.unstartedProjects.map(u => u._id).includes(p._id)) {
              // p.creator = this.auth.user;
              this.unstartedProjects.unshift(p);
            }
          });
        });
      }
    }));
    this.api.currentSemester.subscribe((data) => {
      this.currentSemester = data;
    });
    if (this.auth.user && (this.auth.user as Student).project && this.auth.user.type != USER_TYPE.teacher && this.auth.user.type != USER_TYPE.admin) {
      this.router.navigate(['project', (this.auth.user as Student).project]);
    }

  }

  getStatusWrapper(prop) {
    return Proposal.getStatus(prop);
  }

  changeTab(locIndex: number) {
    this.tabIndex = locIndex;
    window.history.replaceState({}, '', `/${routes[locIndex]}`);
  }

}
