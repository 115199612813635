<div class="container">

  <div class="projects" *ngIf="(teacher?.projects | projectStatusFilter:1).length" style="margin-top: 12px; ">
    <h3>Proyectos en curso</h3>
    <!--    <div class="project underway" [routerLink]="['/project', proj._id]" *ngFor="let proj of teacher.projects | projectStatusFilter:1">-->
    <mat-table [dataSource]="teacher?.projects | projectStatusFilter:1">
      <ng-container matColumnDef="project">
        <mat-header-cell *matHeaderCellDef style="min-width: 50%">Proyecto</mat-header-cell>
        <mat-cell *matCellDef="let row" style="min-width: 50%">{{row?.startDate[0]}}, C{{row?.startDate[1]}} -
          {{row?.title}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef style="justify-content: center;min-width: 30%">Estado</mat-header-cell>
        <mat-cell *matCellDef="let row"
          style="justify-content: center;min-width: 30%">{{getPstatus(row.milestones)}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="updates">
        <mat-header-cell *matHeaderCellDef style="justify-content: center">Último Update</mat-header-cell>
        <mat-cell *matCellDef="let row" style="justify-content: center">{{getLastUpdate(row.milestones) ?
          (getLastUpdate(row.milestones) | date : 'longDate') : '-'}}</mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="['project','status','updates']"></mat-header-row>
      <mat-row class="project underway" *matRowDef="let row; columns: ['project','status','updates']"
        (click)="router.navigate(['/project', row._id])"></mat-row>
    </mat-table>
  </div>

  <div class="projects" *ngIf="(teacher?.jury && teacher?.juryProjects.length)" style="padding-top: 10px;">
    <h3>Proyectos en los que fue jurado</h3>
    <mat-table [dataSource]="teacher?.juryProjects">
      <ng-container matColumnDef="project">
        <mat-header-cell *matHeaderCellDef style="min-width: 80%">Proyecto</mat-header-cell>
        <mat-cell *matCellDef="let row" style="min-width: 80%">{{row?.startDate[0]}}, C{{row?.startDate[1]}} -
          {{row?.title}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="['project']"></mat-header-row>
      <mat-row class="project underway" *matRowDef="let row; columns: ['project']"
        (click)="router.navigate(['/project', row._id])"></mat-row>

    </mat-table>


  </div>

  <div class="projects" *ngIf="isMeOrAdmin && (teacher?.projects | projectAcceptedFilter:1).length" style="padding-top: 10px;">
    <h3>Propuestas pendientes</h3>
    <!--    <div class="project underway" [routerLink]="['/project', proj._id]" *ngFor="let proj of teacher.projects | projectStatusFilter:1">-->
    <mat-table [dataSource]="teacher?.projects | projectAcceptedFilter:1">
      <ng-container matColumnDef="project">
        <mat-header-cell *matHeaderCellDef style="min-width: 80%">Proyecto</mat-header-cell>
        <mat-cell *matCellDef="let row" style="min-width: 80%">{{row?.startDate[0]}}, C{{row?.startDate[1]}} -
          {{row?.title}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="applications">
        <mat-header-cell *matHeaderCellDef style="justify-content: center">Aplicaciones</mat-header-cell>
        <mat-cell *matCellDef="let row" style="justify-content: center"> {{row.applications?.length || 0}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="['project','applications']"></mat-header-row>
      <mat-row class="project underway" *matRowDef="let row; columns: ['project','applications']"
        (click)="router.navigate(['/proposal', row._id])"></mat-row>

    </mat-table>
  </div>

  <div class="projects" *ngIf="isMeOrAdmin && (teacher?.projects | projectAcceptedFilter: 0).length" style="padding-top: 10px;">
    <h3>Propuestas sin confirmar</h3>
    <ng-container *ngIf="teacher?.projects">
      <!--    <div class="project underway" [routerLink]="['/project', proj._id]" *ngFor="let proj of teacher.projects | projectStatusFilter:1">-->
      <mat-table [dataSource]="teacher?.projects | projectAcceptedFilter: 0">
        <ng-container matColumnDef="project">
          <mat-header-cell *matHeaderCellDef style="min-width: 80%">Proyecto</mat-header-cell>
          <mat-cell *matCellDef="let row" style="min-width: 80%">{{row?.startDate[0]}}, C{{row?.startDate[1]}} -
            {{row?.title}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef style="justify-content: center">Editar</mat-header-cell>
          <mat-cell *matCellDef="let row" style="justify-content: center"><mat-icon>edit</mat-icon></mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="['project','actions']"></mat-header-row>
        <mat-row class="project underway" *matRowDef="let row; columns: ['project','actions']"
          (click)="router.navigate(['/project', row._id,'edit'])"></mat-row>

      </mat-table>
    </ng-container>
  </div>

  <div class="projects" *ngIf="isMeOrAdmin && (teacher?.projects | projectStatusFilter:3).length" style="padding-top: 10px;">
    <h3>Propuestas anteriores</h3>
    <mat-table [dataSource]="teacher?.projects | projectStatusFilter:3">
      <ng-container matColumnDef="project">
        <mat-header-cell *matHeaderCellDef style="min-width: 80%">Proyecto</mat-header-cell>
        <mat-cell *matCellDef="let row" style="min-width: 80%">{{row?.startDate[0]}}, C{{row?.startDate[1]}} -
          {{row?.title}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef style="justify-content: center">Editar</mat-header-cell>
        <mat-cell *matCellDef="let row" style="justify-content: center"><mat-icon>edit</mat-icon></mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="['project','actions']"></mat-header-row>
      <mat-row class="project underway" *matRowDef="let row; columns: ['project','actions']"
        (click)="router.navigate(['/proposal', row._id,'edit'])"></mat-row>

    </mat-table>
  </div>

  <div class="projects" *ngIf="(teacher?.projects | projectStatusFilter:2).length" style="padding-top: 10px;">
    <h3>Proyectos finalizados</h3>
    <mat-table [dataSource]="teacher?.projects | projectStatusFilter:2">
      <ng-container matColumnDef="project">
        <mat-header-cell *matHeaderCellDef style="min-width: 80%">Proyecto</mat-header-cell>
        <mat-cell *matCellDef="let row" style="min-width: 80%">{{row?.startDate[0]}}, C{{row?.startDate[1]}} -
          {{row?.title}}</mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="['project']"></mat-header-row>
      <mat-row class="project underway" *matRowDef="let row; columns: ['project']"
        (click)="router.navigate(['/project', row._id])"></mat-row>
    </mat-table>
  </div>

  <div style="align-self: center; padding-top: 20px;"
    *ngIf="!((teacher?.projects | projectStatusFilter:1).length) && !(isMeOrAdmin && (teacher?.projects | projectAcceptedFilter:1).length) && !(isMeOrAdmin && (teacher?.projects | projectAcceptedFilter: 0).length) && !(isMeOrAdmin && (teacher?.projects | projectStatusFilter:3).length) && !((teacher?.projects | projectStatusFilter:2).length)">
    <h3>Aún no hay proyectos propios para mostrar.</h3>
  </div>

  <div class="projects" *ngIf="teacher?.project" style="padding-top: 10px;">
    <h3>Proyecto de tesis</h3>
    <div class="project underway" (click)="router.navigate(['/project', teacher?.project._id])">
      <p>{{ teacher?.project.startDate[0] }}, C{{ teacher?.project.startDate[1] }} - {{ teacher?.project.title }}</p>
    </div>
  </div>


</div>
