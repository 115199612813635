import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Project } from "../../../../models/Project";
import { ApiService } from "../../../../services/api.service";

@Component({
    selector: 'app-jury-comment',
    templateUrl: './jury-comment.component.html',
    styleUrls: ['./jury-comment.component.scss']
})
export class JuryCommentComponent implements OnInit {

    commentForm: FormGroup;

    constructor(public dialogRef: MatDialogRef<JuryCommentComponent>,
        private formBuilder: FormBuilder,
        private api: ApiService,
        @Inject(MAT_DIALOG_DATA) public data: {
            members: any[],
            project: Project
        }) {
    }

    ngOnInit(): void {
        const firstTutorEmail = this.data.members.length > 0 ? this.data.members[0].jury : '';

        this.commentForm = this.formBuilder.group({
            tutor: [firstTutorEmail, [Validators.required]],
            comment: ['', [Validators.required]]
        });

        const previousJuryComment: any = this.data.project.juryComments.find(jc => jc.tutor === firstTutorEmail);
        if (previousJuryComment) {
            this.commentForm.get('comment').setValue(previousJuryComment.comment);
        }

    }


    onTutorSelected(tutor: string) {
        const previousJuryComment: any = this.data.project.juryComments.find(jc => jc.tutor === tutor);
        if (previousJuryComment) {
            this.commentForm.get('comment').setValue(previousJuryComment.comment);
        }else{
            this.commentForm.get('comment').setValue('');
        }
    }

    submitComment() {
        if (this.commentForm.invalid) {
            return;
        }
        const juryComments: any[] = this.data.project.juryComments || [];
        let i = juryComments.length;
        while (i--) {
            if (juryComments[i].tutor === this.commentForm.value.tutor) {
                juryComments.splice(i, 1);
            }
        }
        juryComments.push(this.commentForm.value);
        this.api.addJuryComment(this.data.project._id, juryComments).subscribe(
            data => {
                this.data.project.juryComments = data;
                this.closeDialog();
            }
        );
    }

    closeDialog() {
        this.dialogRef.close('closed');
    }

}
