import {Pipe, PipeTransform} from '@angular/core';
import {Project} from 'src/app/models/Project';

@Pipe({
  name: 'projectStatusFilter'
})
export class ProjectStatusFilterPipe implements PipeTransform {

  transform(projects: Project[], ...args: any[]): Project[] {
    if (args) {
      return projects?.filter(p => p.state === args[0]) || [];
    }
    return projects || [];
  }

}
