<div class="container">
  <div class="back" (click)="back()">
    <mat-icon>arrow_back</mat-icon>
  </div>
  <h3 style="text-transform: capitalize; min-inline-size: max-content;align-self: center;">{{student?.firstName.toLowerCase()}} {{student?.lastName.toLowerCase()}}</h3>
  <p style="align-self: center">{{student?.email}}</p>
  <div class="projects" *ngIf="student?.proposals?.length">
    <h3>Propuestas del alumno</h3>
    <mat-table [dataSource]="student?.proposals">
      <ng-container matColumnDef="project">
        <mat-header-cell *matHeaderCellDef style="min-width: 80%">Proyecto</mat-header-cell>
        <mat-cell *matCellDef="let row" style="min-width: 80%">{{row?.startDate[0]}}, C{{row?.startDate[1]}} - {{row?.title}}</mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="['project']"></mat-header-row>
      <mat-row class="project underway" *matRowDef="let row; columns: ['project']" (click)="router.navigate(['/proposal', row._id])" ></mat-row>
    </mat-table>
  </div>
  <div class="projects" *ngIf="student?.applications?.length">
    <h3 style="margin-top: 20px;">Proyectos aplicados</h3>
    <mat-table [dataSource]="student?.applications">
      <ng-container matColumnDef="project">
        <mat-header-cell *matHeaderCellDef style="min-width: 80%">Proyecto</mat-header-cell>
        <mat-cell *matCellDef="let row" style="min-width: 80%">{{row?.project.startDate[0]}}, C{{row?.project.startDate[1]}} - {{row.project.title}}</mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="['project']"></mat-header-row>
      <mat-row class="project underway" *matRowDef="let row; columns: ['project']" (click)="router.navigate(['/proposal', row.project._id])" ></mat-row>
    </mat-table>
  </div>
  <div style="align-self: center; padding-top: 20px;" *ngIf="(!(student?.proposals?.length) && !(student?.applications?.length))">
    <h3>Aún no hay propuestas para mostrar.</h3>
  </div>
</div>

