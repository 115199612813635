import { Injectable } from '@angular/core';
import { Project } from 'src/app/models/Project';
import { Student } from 'src/app/models/Student';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { User, USER_TYPE } from 'src/app/models/User';
import { Teacher } from 'src/app/models/Teacher';
import { ProjectApplication } from 'src/app/models/ProjectApplication';
import { Proposal } from 'src/app/models/Proposal';
import { share } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AdminService {

    public projects: Project[];
    public proposals: Proposal[];
    // year + 1/2
    private lastSelectedSemester$ = new BehaviorSubject<number[]>(null);

    public ongoingProjects: BehaviorSubject<Project[]> = new BehaviorSubject([]);

    private _headers = new HttpHeaders()
        .append('Content-Type', 'application/json');

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private api: ApiService,
        private _matSnackbar: MatSnackBar) {
        this.init();
    }

    private get headers() {
        return this._headers.append('x-access-token', this.auth.user.accessToken);
    }

    private init() {
        this.projects = [];
        if (this.auth.user?.type == USER_TYPE.admin) {
            this.getOngoingProjects();
        }
    }

    public createUser(userData: { email: string, type: number, jury: boolean }): Observable<User> {
        const obs = this.http.post<any>(`${environment.apiURL}/user`,
            userData, { headers: this.headers }).pipe(share());
        obs.subscribe((p) => {
            this._matSnackbar.open(`Se ha creado el usuario correctamente`, null, { duration: 3000 });
        });
        return obs;
    }

    public upsertMultipleUsers(userData: { email: string, type: number, semesterJoined?: number[], semester?: number, year?: number }[]): Observable<User> {
        const obs = this.http.post<any>(`${environment.apiURL}/user/multiple`,
            userData, { headers: this.headers }).pipe(share());
        obs.subscribe((p) => {
            this._matSnackbar.open(`Se han creado los usuarios correctamente`, null, { duration: 3000 });
        });
        return obs;
    }

    public getStudents(): Observable<Student[]> {
        return this.http.get<any>(`${environment.apiURL}/students`, { headers: this.headers }).pipe(share());
    }

    public getStudentsBySemester(year: number, semester: number): Observable<Student[]> {
        const params = new HttpParams().append('year', year.toString()).append('semester', semester.toString());
        return this.http.get<Student[]>(`${environment.apiURL}/student/query`, { headers: this.headers, params });
    }

    public getUsers(): Observable<User[]> {
        return this.http.get<any>(`${environment.apiURL}/user`, { headers: this.headers }).pipe(share());
    }

    public getTeachers(): Observable<Teacher[]> {
        return this.http.get<any>(`${environment.apiURL}/teachers`, { headers: this.headers }).pipe(share());
    }

    public getJurys(): Observable<Teacher[]> {
        return this.http.get<any>(`${environment.apiURL}/jurys`, { headers: this.headers }).pipe(share());
    }

    public getProjects(): Observable<Project[]> {
        return this.http.get<Project[]>(`${environment.apiURL}/project`, { headers: this.headers });
    }

    public createProject(project: Project): Observable<Project> {
        const obs = this.http.post<any>(`${environment.apiURL}/project`,
            project, { headers: this.headers }).pipe(share());
        obs.subscribe((p) => {
            this._matSnackbar.open(`Se ha creado el proyecto "${project.title}"`, null, { duration: 3000 });
        });
        return obs;
    }

    public deleteProject(id: string) {
        const obs = this.http.delete<Proposal>(`${environment.apiURL}/project/${id}`,
            { headers: this.headers }).pipe(share());
        obs.subscribe((p) => {
            this._matSnackbar.open(`Se ha eliminado el proyecto correctamente`, null, { duration: 3000 });
        });
        return obs;
    }

    public launchProject(id: string, emailBody: string, checkboxValue: boolean) {
        const finalEmailBody = { emailBody, checkboxValue }
        const obs = this.http.post<Proposal>(`${environment.apiURL}/project/${id}/launch`, JSON.stringify(finalEmailBody),
            { headers: this.headers }).pipe(share());
        obs.subscribe((p) => {
            this._matSnackbar.open(`Se ha lanzado el proyecto correctamente`, null, { duration: 3000 });
        });
        return obs;
    }

    public getProjectApplications(): Observable<ProjectApplication[]> {
        const obs = this.http.get<ProjectApplication[]>(`${environment.apiURL}/project_application`,
            { headers: this.headers }).pipe(share());
        return obs;
    }

    public acceptProjectApplication(id: string) {
        const obs = this.http.post<ProjectApplication>(`${environment.apiURL}/project_application/${id}/accept`, undefined,
            { headers: this.headers }).pipe(share());
        obs.subscribe((p) => {
            this._matSnackbar.open(`Se ha aceptado la aplicación al proyecto`, null, { duration: 3000 });
        });
        return obs;
    }

    public getOngoingProjects() {
        const obs = this.http.get<Project[]>(`${environment.apiURL}/project/ongoing`, { headers: this.headers }).pipe(share());
        obs.subscribe(data => {
            data.forEach(p => {
                const nextMilestoneIndex = p.milestones.findIndex(m => !m.doneDate);
                if (nextMilestoneIndex === -1) {
                    p.nextMilestones = [];
                } else {
                    p.nextMilestones = p.milestones.slice(nextMilestoneIndex);
                }
            });
            this.ongoingProjects.next(data);
        });
        return obs;
    }

    public editUser(_id: string, user: User) {
        const obs = this.http.post<User>(`${environment.apiURL}/user/${_id}`, user, { headers: this.headers }).pipe(share());
        obs.subscribe((p) => {
            this._matSnackbar.open(`Se ha editado el usuario correctamente.`, null, { duration: 3000 });
        });
        return obs;
    }

    public deleteUser(_id: string) {
        const obs = this.http.delete<any>(`${environment.apiURL}/user/${_id}`, { headers: this.headers }).pipe(share());
        obs.subscribe((p) => {
            this._matSnackbar.open(`Se ha eliminado el usuario correctamente`, null, { duration: 3000 });
        });
        return obs;
    }

    public changeSemester(semesterToChange: { year: number, semester: number }): Observable<User> {
        const obs = this.http.post<any>(`${environment.apiURL}/current_semester`,
            semesterToChange, { headers: this.headers }).pipe(share());
        obs.subscribe(data => {
            this.api.currentSemester.next([semesterToChange.year, semesterToChange.semester]);
            this._matSnackbar.open(`Cuatrimestre cambiado a ${semesterToChange.semester}C ${semesterToChange.year}`, 'OK', {
                duration: 2000,
            });
        });
        return obs;
    }

    public changeInscriptionInterval(newInscriptionInterval: { start: Date, end: Date }): Observable<User> {
        const obs = this.http.post<any>(`${environment.apiURL}/inscription_interval`,
            newInscriptionInterval, { headers: this.headers }).pipe(share());
        obs.subscribe(data => {
            this.api.inscriptionInterval.next(newInscriptionInterval);
            this._matSnackbar.open(`Intervalo de inscripcion cambiado a ${newInscriptionInterval.start.toLocaleDateString()} - ${newInscriptionInterval.end.toLocaleDateString()}`, 'OK', {
                duration: 2000,
            });
        });
        return obs;
    }

    public finishProject(project: Project, data: any): Observable<any> {
        const finishProject$ = this.http.put(`${environment.apiURL}/project/${project._id}/finish-admin`, data, { headers: this.headers }).pipe(share());
        finishProject$.subscribe(
            () => {
                this._matSnackbar.open(`El proyecto se ha dado por finalizado`, null, { duration: 5000 });
            }, () => {
                this._matSnackbar.open(`Error del servidor, intentelo nuevamente`, null, { duration: 5000 });
            }
        );
        return finishProject$;
    }

    public sendJuryEmail(juries: string[], emailBody: string,extraComment: string, externalLink: string, internalLink: string, project: Project) {
        const obs = this.http.post<any>(`${environment.apiURL}/email/12`,
            { juries, emailBody,extraComment, externalLink, internalLink, project }, { headers: this.headers }).pipe(share());
        obs.subscribe(data => {
            this._matSnackbar.open(`Se ha enviado el email correctamente`, null, { duration: 3000 });
        });
        return obs;
    }

    public getCurrentSemester(): Observable<any> {
        return this.http.get(`${environment.apiURL}/current_semester`);
    }

    public setLastSelectedSemester(year: number, semester: number): void {
        this.lastSelectedSemester$.next([year, semester]);
    }

    public getLastSelectedSemester(): number[] {
        return this.lastSelectedSemester$.getValue();
    }

    public updateLaunchProjectEmailTemplate(updated_template: string): Observable<any> {
        const obs = this.http.post<any>(`${environment.apiURL}/launch_project_email_template`,
            { updated_template }, { headers: this.headers }).pipe(share());
        obs.subscribe(data => {
            this._matSnackbar.open(`Se ha actualizado la plantilla de email`, null, { duration: 3000 });
        });
        return obs;
    }

    public updateFinishProjectEmailTemplate(updated_template: string): Observable<any> {
        const obs = this.http.post<any>(`${environment.apiURL}/finish_project_email_template`,
            { updated_template }, { headers: this.headers }).pipe(share());
        obs.subscribe(data => {
            this._matSnackbar.open(`Se ha actualizado la plantilla de email`, null, { duration: 3000 });
        });
        return obs;
    }

    public updateConfirmPresEmailTemplate(updated_template: string): Observable<any> {
        const obs = this.http.post<any>(`${environment.apiURL}/confirm_pres_email_template`,
            { updated_template }, { headers: this.headers }).pipe(share());
        obs.subscribe(data => {
            this._matSnackbar.open(`Se ha actualizado la plantilla de email`, null, { duration: 3000 });
        });
        return obs;
    }

    public updateJuryAccessEmailTemplate(updated_template: string): Observable<any> {
        const obs = this.http.post<any>(`${environment.apiURL}/jury_access_email_template`,
            { updated_template }, { headers: this.headers }).pipe(share());
        obs.subscribe(data => {
            this._matSnackbar.open(`Se ha actualizado la plantilla de email`, null, { duration: 3000 });
        });
        return obs;
    }

    public saveJuryToken(projectId: string, juryToken: string): Observable<any> {
        const url = `${environment.apiURL}/project/${projectId}/jury-token`;
        return this.http.post<any>(`${environment.apiURL}/project/${projectId}/jury-token`, { juryToken }, { headers: this.headers }).pipe(share(),
            catchError((error) => {
                console.error('Error in POST request:', error);
                throw error; // Rethrow the error to propagate it to the subscriber
            }));

    }

}


