import {SocialUser} from 'angularx-social-login';
import { Project } from './Project';

export class User extends SocialUser {
  fullName?: string;
  accessToken?: string;
  picture?: string;
  type: USER_TYPE;
  _id: string;
}

export enum USER_TYPE {
  student, teacher, admin
}
