import {Pipe, PipeTransform} from '@angular/core';
import {STATE} from 'src/app/models/Project';

@Pipe({
  name: 'proposalImportanceSorter'
})
export class ProposalImportanceSorterPipe implements PipeTransform {

  transform(proposals: any[], ...args: unknown[]): any[] {
    return proposals.filter(p => p.state === STATE.UNSTARTED).sort((a, b) => {
      if (a.accepted !== b.accepted) {
        return (b.accepted ? 1 : 0) - (a.accepted ? 1 : 0);
      } else {
        if (a.accepted) {
          return b.applications?.length - a.applications?.length;
        } else {
          return 0;
        }
      }
    });
  }

}
