<div class="container">
  <h1 mat-dialog-title>Finalización de proyecto</h1>
  <div mat-dialog-content>
    <form *ngIf="finishForm" [formGroup]="finishForm"  (ngSubmit)="finishProject()">
      <div>
        <mat-form-field class="area-input">
          <mat-label>Comentario</mat-label>
          <mat-hint>Opcional</mat-hint>
          <textarea matInput type="text" rows="10" formControlName="emailBody" style="text-align: justify;"></textarea>
        </mat-form-field>
        <div class="bottom-controls" style="justify-content: space-between;">
          <mat-form-field class="end-date">
            <mat-label>Fecha de finalización</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="date">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <mat-slide-toggle [color]="'primary'" formControlName="sendMail">Enviar mail</mat-slide-toggle>
      <mat-slide-toggle [color]="'primary'" formControlName="updateTemplate" style="margin-left: 10px;">Actualizar template</mat-slide-toggle>
    </form>
  </div>
  <img src="assets/icons/close.svg" alt="" (click)="dialogRef.close(false)" cdkFocusInitial class="icon-x">
  <div mat-dialog-actions>
    <button mat-button color="primary" (click)="finishProject()">Confirmar</button>
  </div>
</div>
