const errorMessages = {
  'required': (error) => 'Este campo es obligatorio',
  'badCharacters': (error) => 'Este campo contiene carácteres inválidos',
  'minlength': ({requiredLength, actualLength}) => `Este campo debe contener al menos ${requiredLength} caracteres`,
  'invalidCredentials': (error) => 'Las credenciales son incorrectas',
  'emailDoesntExist': (error) => 'No existe una cuenta con ese email asociado',
  'email': (error) => 'Ingrese un email válido',
  'maxlength': ({requiredLength, actualLength}) => `Este campo debe tener como máximo ${requiredLength} caracteres`,
  'min': (error) => 'Debe elegir una prioridad',
  'requiredshort': (error) => 'Requerido',
};

export default errorMessages;
