import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Milestone} from 'src/app/models/Milestone';
import {ApiService} from 'src/app/services/api.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AuthService} from 'src/app/services/auth.service';
import {Project} from 'src/app/models/Project';
import errorMessages from 'src/app/utils/errorMessages';

@Component({
  selector: 'app-create-milestone',
  templateUrl: './create-milestone.component.html',
  styleUrls: ['./create-milestone.component.scss']
})
export class CreateMilestoneComponent implements OnInit {
  public addEventFG: FormGroup;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    public auth: AuthService,
    public dialogRef: MatDialogRef<CreateMilestoneComponent>,
    @Inject(MAT_DIALOG_DATA) public project: Project
  ) {
  }

  ngOnInit(): void {

    this.addEventFG = this.fb.group({
      type: [1, [Validators.required]],
      name: ['', [Validators.required]],
      description: [''],
      dueDate: ['', [Validators.required]]
    });
  }

  addEvent() {
    this.addEventFG.markAllAsTouched();
    if (this.addEventFG.invalid) {
      return;
    }
    let aux = new Milestone();
    aux.type = this.addEventFG.value.type;
    aux.name = this.addEventFG.value.name;
    aux.dueDate = this.addEventFG.value.dueDate;
    aux.description = this.addEventFG.value.description;
    this.apiService.addMilestone(this.project._id, aux).subscribe(
      data => {
        this.project.milestones = data.milestones;
        this.dialogRef.close(true);
      }
    );
  }

  getErrorMessage(input: string) {
    const errorsAux = this.addEventFG.controls[input]?.errors;
    if (errorsAux) {
      const keys = Object.keys(this.addEventFG.controls[input].errors);
      if (!keys || keys.length === 0) return '';
      return errorMessages[keys[0]](errorsAux[keys[0]]);
    } else {
      return '';
    }
  }

}
