<div class="container">
  <img src="assets/icons/close.svg" alt="" (click)="this.dialogRef.close(false)" cdkFocusInitial>
  <h1 mat-dialog-title>Confirmación de defensa</h1>
  <div mat-dialog-content>
    <div class="recipients" *ngIf="teacherRecipients.length">
      <b>{{project?.tutors.length > 1? 'Tutores:' : 'Tutor:'}}</b>
      <mat-chip-list #chipList>
        <mat-basic-chip *ngFor="let user of teacherRecipients; let i = index" (remove)="removeRecipient(i, user.type)">
          {{ user | usernameOrEmail }}
          <mat-icon matChipRemove (click)="removeRecipient(i, user.type)">cancel</mat-icon>
        </mat-basic-chip>
      </mat-chip-list>
    </div>
    <div class="recipients" *ngIf="juryRecipients.length">
      <b>{{project?.jury.length > 1? 'Jurados:' : 'Jurado:'}}</b>
      <mat-chip-list #chipList>
        <mat-basic-chip *ngFor="let juror of juryRecipients; let i = index">
          <span *ngIf="juror.name">{{juror.name}}</span>
          <span *ngIf="!juror.name">{{juror.jury}}</span>
          <mat-icon matChipRemove (click)="removeJuror(i)">cancel</mat-icon>
        </mat-basic-chip>
      </mat-chip-list>
    </div>
    <div class="recipients" *ngIf="studentRecipients.length">
      <b>{{project?.students.length > 1? 'Alumnos:' : 'Alumno:'}}</b>
      <mat-chip-list #chipList>
        <mat-basic-chip *ngFor="let user of studentRecipients; let i = index" (remove)="removeRecipient(i, user.type)">
          {{ user | usernameOrEmail }}
          <mat-icon matChipRemove (click)="removeRecipient(i, user.type)">cancel</mat-icon>
        </mat-basic-chip>
      </mat-chip-list>
    </div>
    <p style="color: darkgray; white-space: pre-line; text-align: justify;">{{staticMsg}}</p>
    <p></p>
    <form *ngIf="confirmationForm" [formGroup]="confirmationForm">
      <div>
        <mat-form-field class="area-input">
          <mat-label>Comentario</mat-label>
          <mat-hint>Opcional</mat-hint>
          <textarea
          matInput
          type="text"
          rows="7"
          formControlName="emailBody" style="text-align: justify;"
          ></textarea>
        </mat-form-field>
      </div>
      <mat-slide-toggle [color]="'primary'" [checked]="checked" (change)="checked = !checked">Enviar mail</mat-slide-toggle>
      <mat-slide-toggle color="primary" matNativeControl formControlName="update_template_control" style="margin-left: 10px;">Actualizar template</mat-slide-toggle>
    </form>
  </div>
  <div mat-dialog-actions>
    <button mat-button color="primary" (click)="confirmPresentation()">Confirmar</button>
  </div>
</div>
