<div class="container-custom">
  <div class="instructions" *ngIf="!auth.isTeacher">
    <h2>Información para alumnos</h2>
    <p style="text-align: justify;">En esta materia los alumnos deben llevar adelante un proyecto de Ing. Informática en el
      transcurso de dos cuatrimestres. El primer paso es elegir un proyecto y para eso tienen dos
      opciones: elegir una propuesta existente, creada por algún tutor, o subir una propuesta propia
      para que sea evaluada por la cátedra.
      Para poder comenzar, deben estar matriculados en la materia y loguearse con su cuenta del ITBA.
    </p>

  </div>
  <div class="instructions" *ngIf="!auth.isStudent && !auth.isTeacher">

    <h2>Información para profesores</h2>
    <p style="text-align: justify;">Para incluir una propuesta para el próximo cuatrimestre, deben loguearse con su cuenta del ITBA y seleccionar <b style="color: #3C48F6">Proponer proyecto</b>.
      La misma será revisada por la cátedra e incluida entre las opciones disponibles para los alumnos.
    </p>

  </div>
  <div *ngIf="!auth.isTeacher" style="text-align: center; padding: 0px 20px;">
    <button mat-stroked-button class="btn-blue" [routerLink]="['proposal']">Proponer proyecto</button>
  </div>
  <div *ngIf="auth.isTeacher" style="text-align: center; padding: 0px 20px;">
    <button mat-stroked-button class="btn-blue" [routerLink]="['/proposal']">Proponer proyecto</button>
  </div>
<!--  <h3><b>Propuestas activas para {{getCurrentSemester()}}</b></h3>-->
  <div class="proposals" *ngIf="!proposals || proposals.length>0; else noProposal">
    <mat-card *ngFor="let proposal of proposals; index as i" class="card proposal" [routerLink]="['proposal', proposal._id]">
      <div class="row">
        <h3 style="cursor: pointer; overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 1;display: -webkit-box;-webkit-box-orient: vertical;font-weight: 400;">{{proposal.title | uppercase}}</h3>
      </div>
      <div class="module line-clamp">
        <p style="text-align: justify; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical;
      margin: 0; line-height: 1.2em; text-overflow: ellipsis;">{{proposal.abstract}}</p>
      </div>
      <div>
        <p *ngIf="proposal?.tutors?.length" style="margin: 5px 0">
          <b>{{proposal?.tutors.length > 1? 'Tutores:' : 'Tutor:'}} </b>
          <span *ngFor="let tutor of proposal?.tutors; index as i" [routerLink]="['/teachers', tutor.email]" class="click" style="cursor: pointer;text-align: justify; overflow: hidden; -webkit-line-clamp: 1; -webkit-box-orient: vertical;
      margin: 0; line-height: 1.2em; text-overflow: ellipsis;">
            <span *ngIf="tutor.firstName; else noName">
            {{tutor.firstName}} {{tutor.lastName}} <span *ngIf="tutor.email">- {{tutor.email}}</span><span
              *ngIf="(i + 1) < proposal?.tutors?.length">,</span>
            </span>
            <ng-template #noName>
              <span>
                {{tutor.email}}
              </span>
            </ng-template>
          </span>
        </p>
        <p *ngIf="!proposal?.tutors?.length && proposal?.posibleTutors?.length" style="margin: 5px 0">
          <b>Tutores tentativos: </b>
          <span *ngFor="let tutor of proposal?.posibleTutors; index as i">
                        {{tutor}}<span *ngIf="i + 1 < proposal?.posibleTutors.length"> - </span>
                    </span>
      </div>
      <div>
        <p style="margin: 5px 0"><b>Tipo de proyecto:</b> {{proposal?.investigationProject ? 'Investigación' :
          ''}}{{proposal?.investigationProject && proposal?.developmentProject ? ',' : ''}}
          {{proposal?.developmentProject ? 'Desarrollo' : ''}}</p>
        <p style="margin: 5px 0" *ngIf="auth.isAdmin"><b>Objetivos:</b> {{proposal?.objectives | showOrNA}}</p>
        <p style="margin: 5px 0" *ngIf="proposal?.type && proposal?.maxStudents"><b>Cantidad máxima de alumnos:</b> {{proposal?.maxStudents | showOrNA}}</p>
      </div>
    </mat-card>
  </div>
  <ng-template #noProposal>
    <p>
      No hay propuestas de profesores para mostrar.
    </p>
  </ng-template>
</div>
