import {Component, OnInit} from '@angular/core';
import {AuthService} from 'src/app/services/auth.service';
import {ApiService} from 'src/app/services/api.service';
import {ActivatedRoute} from '@angular/router';
import {Teacher} from 'src/app/models/Teacher';
import {Location} from '@angular/common';


@Component({
  selector: 'app-teacher-profile',
  templateUrl: './teacher-profile.component.html',
  styleUrls: ['./teacher-profile.component.scss']
})
export class TeacherProfileComponent implements OnInit {
  teacher: Teacher;

  constructor(public auth: AuthService,
              public apiService: ApiService,
              private _location: Location,
              public route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      params => {
        this.apiService.getTeacher(params.get('id')).subscribe(
          data => {
            this.teacher = data;
          }
        );
      }
    );
  }
   back() {
        this._location.back();
    }

}
