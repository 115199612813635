<div class="body">
  <p>
    Thesis es un proyecto en desarollo como va a ser el tuyo en poco tiempo!
  </p>
  <p>
    Si encontras algún bug o se te ocurre alguna funcionalidad interesante no dudes
    en avisarnos a nuestros emails: ndonofri@itba.edu.ar y tduffau@itba.edu.ar
  </p>
  <p>
    Como primer paso tenés que elegir o proponer un proyecto. Vas a
    poder ver todos los proyectos disponibles actualmente.
  </p>
  <p>
    Si ninguno te convence proponé uno!
  </p>
  <button (click)="start()">EMPEZAR</button>
</div>
